import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogContent,
  InputBase,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
  Alert,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import { AccountsTable } from "./Accounts/AccountsTable"
import { FormattedUserResponse } from "../utils/flatteningFunctions/flattenSearchUser"
import { useDebounce } from "../hooks/useDebounce"

type SearchDialogProps = {
  totalUsers: number
  onPageChange: (newPage: number) => void
  rowsPerPage: number
  page: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  open: boolean
  onClose: () => void
  onSearch: (value: string) => void
  searchResults: FormattedUserResponse[] // Use the correct type
  handleUserSelected: (user: FormattedUserResponse) => void // Use the correct type
  isLoading: boolean
  MIN_SEARCH_THRESHOLD: number
  data: any
}

export const SearchDialog: React.FC<SearchDialogProps> = ({
  totalUsers,
  onPageChange,
  rowsPerPage,
  MIN_SEARCH_THRESHOLD,
  page,
  onRowsPerPageChange,
  open,
  onClose,
  onSearch,
  searchResults,
  handleUserSelected,
  isLoading,
  data,
}) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState("")
  const [hasSearched, setHasSearched] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const debouncedSearchValue = useDebounce(searchValue, 300)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value

    // Allow special characters as per RFC 5322 and RFC 5321
    const specialChars = "!#$%&'*+-/=?^_`{|}~@."
    const regex = new RegExp(`[^a-zA-Z0-9 ${specialChars}]`, "g")
    value = value.replace(regex, "")

    setSearchValue(value)

    if (value.length >= 2) {
      setHasSearched(true)
    } else if (value === "") {
      setHasSearched(false)
    }
  }

  useEffect(() => {
    onSearch(debouncedSearchValue)
  }, [debouncedSearchValue, onSearch])

  const handleUserClick = (user: any) => {
    handleUserSelected(user)
    setSearchValue("")
    setHasSearched(false)
  }

  const handleClose = () => {
    onClose()
    setSearchValue("")
    onSearch("")
    setHasSearched(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth="md"
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <DialogContent sx={{ height: "600px" }}>
        <InputBase
          autoFocus
          fullWidth
          placeholder="Search accounts..."
          value={searchValue}
          onChange={handleSearchChange}
          startAdornment={<SearchIcon />}
          endAdornment={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />

        <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
            Search using a first name, last name, email, phone number, or account number. Please
            enter one search parameter at a time only.
          </Typography>
        </Alert>

        {isLoading ? (
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading...
          </Typography>
        ) : debouncedSearchValue.length >= MIN_SEARCH_THRESHOLD ? (
          searchResults.length > 0 || (data !== undefined && data.response.length > 0) ? (
            <AccountsTable
              totalUsers={totalUsers}
              users={searchResults}
              handleUserClick={handleUserClick}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          ) : (
            <Typography variant="body1" sx={{ mt: 2 }}>
              No results found
            </Typography>
          )
        ) : null}
      </DialogContent>
    </Dialog>
  )
}
