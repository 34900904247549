import React from "react"
import { Alert, Stack, TableRow, useMediaQuery, useTheme } from "@mui/material"
import { TableLayout } from "../../layouts/TableLayout"
import customStyles from "../../styles/tokens.json"
import { getColorFromTitle, TitleColor } from "../../constants/getColorFromTitle"
import { getIconFromTitle } from "../../constants/getIconFromTitle"
import { DrawerTableTypography } from "../../styles/styledComponents/DrawerTableTypography"
import { DrawerTableCell } from "../../styles/styledComponents/DrawerTableCell"
import TableSkeleton, { AlignmentConfig, SizeConfig } from "../../layouts/Skeletons/TableSkeleton"

export const TransactionsReportTable: React.FC<{
  users: unknown[]
  totalNumberOfRows: number
  onPageChange: (newPage: number) => void
  rowsPerPage: number
  page: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
}> = ({
  users,
  onPageChange,
  totalNumberOfRows,
  rowsPerPage,
  setRowsPerPage,
  page,
  onRowsPerPageChange,
  loading,
}) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const headCells = isSmallScreen
    ? ["Account", "Date", "Tx.", "Amount", "Cashier"]
    : isMediumScreen
    ? ["Account", "Name", "Date", "Transaction", "Amount", "Cashier"]
    : ["Account", "Date", "Transaction", "Amount", "Cashier"]

  const headCellsAlign: ("center" | "inherit" | "left" | "right" | "justify")[] = isMediumScreen
    ? ["left", "left", "left", "left", "right", "left"]
    : ["left", "left", "left", "right", "left"]

  const sizeToWidthMap = {
    auto: "auto",
    xXSmall: customStyles.components.tables.size.xXSmall,
    xSmall: customStyles.components.tables.size.xSmall,
    small: customStyles.components.tables.size.small,
    big: customStyles.components.tables.size.big,
    xBig: customStyles.components.tables.size.xBig,
  }

  const widths = isMediumScreen
    ? (["small", "big", "small", "big", "big", "small"] as const).map(
        (size) => sizeToWidthMap[size]
      )
    : (["small", "xSmall", "auto", "small", "small"] as const).map((size) => sizeToWidthMap[size])
  const headCellsConfig = {
    small: ["Account", "Date", "Tx.", "Amount", "Cashier"],
    medium: ["Account", "Name", "Date", "Transaction", "Amount", "Cashier"],
    large: ["Account", "Date", "Transaction", "Amount", "Cashier"],
  }
  const sizeStylesConfig: SizeConfig = {
    small: ["small", "xSmall", "auto", "small", "small"],
    medium: ["small", "big", "big", "small", "big", "small", "small"],
    large: ["small", "small", "auto", "small", "small"],
  }

  const alignmentsConfig: AlignmentConfig = {
    small: ["left", "left", "left", "right", "left"],
    medium: ["left", "left", "left", "left", "right", "left"],
    large: ["left", "left", "left", "right", "left"],
  }
  if (loading) {
    return (
      <TableSkeleton
        rows={rowsPerPage}
        headCellsConfig={headCellsConfig}
        alignmentsConfig={alignmentsConfig}
        sizeStylesConfig={sizeStylesConfig}
      />
    )
  }

  if (users.length === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%", fontSize: "min(20px, calc(10px + 0.6vmin))" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <TableLayout
      onRowsPerPageChange={onRowsPerPageChange}
      headCells={headCells}
      headCellsAlign={headCellsAlign}
      totalNumberOfRows={totalNumberOfRows}
      rows={users}
      rowsPerPage={rowsPerPage}
      page={page}
      width={widths}
      onPageChange={onPageChange}
      renderRow={(transaction) => {
        const themeColor: TitleColor = getColorFromTitle(transaction.transactionType) as TitleColor
        const theme = customStyles.themePalette[themeColor]

        return (
          <TableRow key={transaction.id}>
            <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
              <DrawerTableTypography size="small" variant="primary">
                {transaction.playerNumber}
              </DrawerTableTypography>
              {!isMediumScreen && !isSmallScreen && (
                <DrawerTableTypography size="small" variant="secondary">
                  {transaction.name}
                </DrawerTableTypography>
              )}
              {isSmallScreen && (
                <DrawerTableTypography size="small" variant="secondary">
                  {transaction.name}
                </DrawerTableTypography>
              )}
            </DrawerTableCell>
            {isMediumScreen && (
              <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
                <DrawerTableTypography
                  size="big"
                  variant="primary"
                >{`${transaction.name}`}</DrawerTableTypography>
              </DrawerTableCell>
            )}
            <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
              <div>
                <DrawerTableTypography size={isMediumScreen ? "big" : "xSmall"} variant="primary">
                  {`${transaction.date}`}
                  {isMediumScreen && ` ${transaction.time}`}
                </DrawerTableTypography>
                {!isMediumScreen && (
                  <DrawerTableTypography
                    size="xSmall"
                    variant="secondary"
                  >{`${transaction.time}`}</DrawerTableTypography>
                )}
              </div>
            </DrawerTableCell>
            <DrawerTableCell whiteSpaceNowrap="nowrap">
              <Stack direction="row">
                {getIconFromTitle(transaction.transactionType, "small", theme.light)}{" "}
                {!isSmallScreen && transaction.transactionType}
              </Stack>
            </DrawerTableCell>
            <DrawerTableCell whiteSpaceNowrap="nowrap" textAlign="right">
              {transaction.transactionValue}
            </DrawerTableCell>
            <DrawerTableCell whiteSpaceNowrap="nowrap" textAlign="left">
              {transaction.cashier}
            </DrawerTableCell>
          </TableRow>
        )
      }}
    />
  )
}
