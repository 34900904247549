const coef = 100

// convert cents to dollars
export const entriesToMoney = (entires: number): number => {
  return entires / coef
}

// convert dollars to cents
export const moneyToEntries = (money: number): number => {
  return money * coef
}
