// pages/ComingSoon.tsx
import React from "react"
import comingSoonImage from "../assets/not-found.jpg"
import { IconButton, Box } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"

export const NotFound: React.FC = () => {
  const handleBack = () => {
    window.history.back()
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <IconButton color="primary" onClick={handleBack}>
        <ArrowBack />
      </IconButton>
      <Box>
        <img
          src={comingSoonImage}
          alt="Coming Soon"
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "calc(100vh - 48px)", // Subtracting the height of the IconButton
            margin: "0 auto",
          }}
        />
      </Box>
    </Box>
  )
}
