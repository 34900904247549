// TransactionTable.tsx
import React from "react"
import { Alert, Stack, TableRow, useMediaQuery, useTheme } from "@mui/material"
import { TableLayout } from "../../layouts/TableLayout"
import customStyles from "../../styles/tokens.json"
import { getColorFromTitle, TitleColor } from "../../constants/getColorFromTitle"
import { getIconFromTitle } from "../../constants/getIconFromTitle"
import { DrawerTableTypography } from "../../styles/styledComponents/DrawerTableTypography"
import { DrawerTableCell } from "../../styles/styledComponents/DrawerTableCell"

export const TransactionTable: React.FC<{
  users: unknown[]
  rowsPerPage: number
  page: number
  totalNumberOfRows: number
  onPageChange: (newPage: number) => void
  onRowsPerPageChange: (rowsPerPage: number) => void
}> = ({ users, rowsPerPage, page, totalNumberOfRows, onPageChange, onRowsPerPageChange }) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const headCells = isSmallScreen
    ? ["Account", "Date", "Tx.", "Amount"]
    : isMediumScreen
    ? ["Account", "Name", "Date", "Transaction", "Amount"]
    : ["Account", "Date", "Transaction", "Amount"]

  const headCellsAlign: ("center" | "inherit" | "left" | "right" | "justify")[] = isMediumScreen
    ? ["left", "left", "left", "left", "right"]
    : ["left", "left", "left", "right"]

  const sizeToWidthMap = {
    auto: "auto",
    xXSmall: customStyles.components.tables.size.xXSmall,
    xSmall: customStyles.components.tables.size.xSmall,
    small: customStyles.components.tables.size.small,
    big: customStyles.components.tables.size.big,
    xBig: customStyles.components.tables.size.xBig,
  }

  const widths = isMediumScreen
    ? (["xSmall", "big", "small", "big", "big"] as const).map((size) => sizeToWidthMap[size])
    : (["small", "xSmall", "xXSmall", "small"] as const).map((size) => sizeToWidthMap[size])

  if (users.length === 0 && page !== 1) {
    return (
      <Alert severity="error" sx={{ width: "100%", fontSize: "min(20px, calc(10px + 0.6vmin))" }}>
        No transactions have been made yet.
      </Alert>
    )
  }

  return (
    <TableLayout
      headCells={headCells}
      headCellsAlign={headCellsAlign}
      rows={users}
      width={widths}
      rowsPerPage={rowsPerPage}
      page={page}
      totalNumberOfRows={totalNumberOfRows}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      renderRow={(transaction) => {
        const themeColor: TitleColor = getColorFromTitle(transaction.transactionType) as TitleColor
        const theme = customStyles.themePalette[themeColor]

        return (
          <TableRow key={transaction.id}>
            <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
              <DrawerTableTypography size="small" variant="primary">
                {transaction.playerNumber}
              </DrawerTableTypography>

              {!isMediumScreen && !isSmallScreen && (
                <DrawerTableTypography size="big" variant="secondary">
                  {transaction.name}
                </DrawerTableTypography>
              )}

              {isSmallScreen && (
                <DrawerTableTypography size="small" variant="secondary">
                  {transaction.name}
                </DrawerTableTypography>
              )}
            </DrawerTableCell>

            {isMediumScreen && (
              <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
                <DrawerTableTypography
                  size="big"
                  variant="primary"
                >{`${transaction.name}`}</DrawerTableTypography>
              </DrawerTableCell>
            )}

            <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
              <div>
                <DrawerTableTypography size={isMediumScreen ? "big" : "xSmall"} variant="primary">
                  {`${transaction.date}`}

                  {isMediumScreen && ` ${transaction.time}`}
                </DrawerTableTypography>
                {!isMediumScreen && (
                  <DrawerTableTypography
                    size="xSmall"
                    variant="secondary"
                  >{`${transaction.time}`}</DrawerTableTypography>
                )}
              </div>
            </DrawerTableCell>

            <DrawerTableCell whiteSpaceNowrap="nowrap">
              <Stack direction="row">
                {getIconFromTitle(transaction.transactionType, "small", theme.light)}{" "}
                {!isSmallScreen && transaction.transactionType}
              </Stack>
            </DrawerTableCell>

            <DrawerTableCell whiteSpaceNowrap="nowrap" textAlign="right">
              {transaction.transactionValue}
            </DrawerTableCell>
          </TableRow>
        )
      }}
    />
  )
}
