import React from "react"
import {
  useMediaQuery,
  useTheme,
  Box,
  Pagination,
  Tooltip,
  PaginationItem,
  PaginationItemProps,
} from "@mui/material"
import { ShiftLogShiftOutput } from "../../types/IShift"
import { Accordion, AccordionSummary } from "../../styles/styledComponents/AccordionStyles"
import { MemoizedAccordionItem } from "./AccordionItemMemo"

export const ShiftTable: React.FC<{
  users: ShiftLogShiftOutput[]
  total: number
  onPageChange: (page: number) => void
  currentPage: number // Add this line
}> = ({ users, total, onPageChange, currentPage }) => {
  const ITEMS_PER_PAGE = 10
  const totalPages = Math.ceil(total / ITEMS_PER_PAGE)

  const theme = useTheme()
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down(325))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"))
  const paginationSize = matchesXS ? "small" : "medium"
  const paginationhandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value)
    window.scrollTo({ top: 0 })
  }
  const accordionHeadings = isSmallScreen
    ? ["Cashier", "Start", "End", "Balance"]
    : isMediumScreen
    ? ["Cashier", "Start Date", "End Date", "Balance"]
    : ["Cashier", "Start Date", "End Date", "Start Balance", "End Balance"]

  const currentUsers = users
  const renderTooltipItem = (itemProps: PaginationItemProps, label: string) => (
    <Tooltip title={label} placement="bottom-start">
      <PaginationItem {...itemProps} />
    </Tooltip>
  )
  return (
    <Box>
      <Accordion className="header" sx={{ backgroundColor: "#F2F3F4" }}>
        <AccordionSummary>
          <Box
            sx={{
              marginLeft: isSmallScreen ? "8px" : "24px",
              flexBasis: "100%",
              display: "flex",
            }}
          >
            {accordionHeadings.map((heading, index) => (
              <Box
                key={heading}
                sx={{
                  textAlign: isSmallScreen
                    ? index <= 2
                      ? "left"
                      : "right"
                    : index <= 2
                    ? "left"
                    : "right",

                  fontWeight: "bold",
                  fontSize: isSmallScreen
                    ? "min(20px,calc(10px + 1.4vmin))"
                    : "min(16px, calc(10px + 0.6vmin))",
                  minWidth: "56px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  flexBasis: isSmallScreen
                    ? index === 0
                      ? "27%"
                      : index === 1
                      ? "22%"
                      : index === 2
                      ? "22%"
                      : "29%"
                    : isMediumScreen
                    ? index === 0
                      ? "25%"
                      : index === 1
                      ? "23%"
                      : index === 2
                      ? "23%"
                      : index === 3
                      ? "29%"
                      : "25%"
                    : !isSmallScreen && !isMediumScreen
                    ? index === 0
                      ? "22%"
                      : index === 1
                      ? "20%"
                      : index === 2
                      ? "20%"
                      : index === 3
                      ? "19%"
                      : index === 4
                      ? "19%"
                      : "20%"
                    : `${Math.round(100 / accordionHeadings.length)}%`,
                }}
              >
                {heading}
              </Box>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>
      {currentUsers.map((shift) => (
        <MemoizedAccordionItem
          key={shift.id}
          shift={shift}
          isSmallScreen={isSmallScreen}
          isMediumScreen={isMediumScreen}
          isXSmallScreen={isXSmallScreen}
          isLargeScreen={isLargeScreen}
        />
      ))}
      <div className="flex my-8 justify-center">
        <Pagination
          size={paginationSize}
          page={currentPage}
          count={totalPages}
          boundaryCount={1}
          siblingCount={1}
          onChange={paginationhandleChange}
          renderItem={(itemProps) =>
            itemProps.type === "previous" ? (
              renderTooltipItem(itemProps, "Go to previous page")
            ) : itemProps.type === "next" ? (
              renderTooltipItem(itemProps, "Go to next page")
            ) : (
              <PaginationItem {...itemProps} />
            )
          }
          sx={{
            backgroundColor: "transparent",
            "& .MuiPagination-ul": {
              flexWrap: "nowrap",
            },
            "& .MuiPaginationItem-previousNext": {},
            "& .MuiPaginationItem-root": {
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&:hover": {
                backgroundColor: matchesXS ? "transparent" : "undefined",
              },
            },
          }}
        />
      </div>
    </Box>
  )
}

export const ShiftTableMemo = React.memo(ShiftTable)
