import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Backdrop,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogContentText,
  Grid,
  CircularProgress,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import customStyles from "../tokens.json"
interface CustomDialogProps {
  open: boolean
  title: string
  subHeader?: string
  content?: React.ReactNode
  dialogContentText?: string
  primaryActionText?: string
  secondaryActionText?: string
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
  amount?: string
  handleClose?: () => void
  handleAmountChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  doubleCheckThreshold?: number
  disabledPrimaryAction?: boolean
  isLoading?: boolean
  disableHandleClose?: boolean
  removeClose?: boolean
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  title,
  removeClose = true,
  subHeader,
  content,
  dialogContentText,
  primaryActionText,
  secondaryActionText,
  onPrimaryAction,
  onSecondaryAction,
  disableHandleClose,
  amount,
  handleClose,
  handleAmountChange,
  doubleCheckThreshold = 10000,
  disabledPrimaryAction = false,
  isLoading,
}) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))
  const isSmallScreen = useMediaQuery("(max-width:325px)")
  const direction = isSmallScreen ? "column" : "row"

  const [showDoubleCheck, setShowDoubleCheck] = useState(false)

  useEffect(() => {
    if (amount && parseFloat(amount) > doubleCheckThreshold) {
      setShowDoubleCheck(true)
    } else {
      setShowDoubleCheck(false)
    }
  }, [amount, doubleCheckThreshold])
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dialog open={open} hideBackdrop={true} fullWidth fullScreen={isPhone}>
        {removeClose ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            disabled={disableHandleClose}
            sx={{
              alignSelf: "end",
              margin: "20px 24px 0 0",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <div style={{ width: 48, height: 48 }} /> // Placeholder div
        )}

        <Box
          sx={{
            margin: "auto 0",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <DialogTitle
            sx={{
              fontSize: isPhone
                ? "min(25px, calc(10px + 5vmin))"
                : "min(30px, calc(24px + 0.8vmin))",
              fontWeight: "bold",
              paddingTop: 0,
              paddingLeft: "8px",
              paddingBottom: "20px",
            }}
          >
            {title}
            {subHeader && (
              <DialogContent
                sx={{
                  fontSize: "min(20px, calc(12px + 0.8vmin))",
                  // color: customStyles.colors.text.disabled,
                  // fontWeight: "normal",
                  paddingTop: 1,
                  paddingLeft: "0",
                  paddingBottom: "0",
                }}
              >
                {subHeader}
              </DialogContent>
            )}
          </DialogTitle>
          <DialogContent
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            {dialogContentText && (
              <DialogContentText sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                {dialogContentText}
              </DialogContentText>
            )}
            {content}
          </DialogContent>
          {primaryActionText && (
            <DialogActions
              sx={{
                marginBottom: "20px",
                paddingRight: "8px",
              }}
            >
              <Grid
                container
                direction={direction}
                spacing={1}
                justifyContent={isSmallScreen ? "center" : "flex-end"}
                alignItems={isSmallScreen ? "center" : "flex-end"}
              >
                {secondaryActionText && (
                  <Grid item sx={{ width: isSmallScreen ? "100%" : {} }}>
                    <Button
                      sx={{ fontSize: "min(16px, calc(10px + 0.8vmin))", width: "100%" }}
                      variant="contained"
                      color="primary"
                      onClick={onSecondaryAction}
                    >
                      {secondaryActionText}
                    </Button>
                  </Grid>
                )}
                <Grid item sx={{ width: isSmallScreen ? "100%" : {} }}>
                  <Button
                    sx={{
                      fontSize: "min(16px, calc(10px + 0.8vmin))",
                      width: "100%",
                      bgcolor: customStyles.components.buttons.primary.color,
                      ":hover": { bgcolor: customStyles.components.buttons.primary.hoverColor },
                    }}
                    variant="contained"
                    onClick={onPrimaryAction}
                    disabled={disabledPrimaryAction}
                  >
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    {primaryActionText}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </Backdrop>
  )
}
