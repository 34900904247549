import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MAIN_URL,
  }),
  endpoints: (builder) => ({
    getEmployee: builder.query({
      query: () => ({
        method: "POST",
        url: "GetEmployee",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getShiftTransactions: builder.query({
      query: (body) => ({
        method: "POST",
        url: "GetShiftTransactions",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    getCashierList: builder.query({
      query: (body) => ({
        method: "POST",
        url: "GetCashierList",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    getUserList: builder.query({
      query: (body) => ({
        method: "POST",
        url: "GetUserList",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    searchUsers: builder.query({
      query: (body) => ({
        method: "POST",
        url: "SearchUsers",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "CreateUser",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    sendVerificationCode: builder.mutation({
      query: (body) => ({
        url: "SendVerificationCode",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }),
    }),

    completeVerificationCode: builder.mutation({
      query: (body) => ({
        url: "CompleteVerificationCode",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }),
    }),

    checkVerificationCompleted: builder.query({
      query: (body) => ({
        url: "CheckVerificationCompleted",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    getUser: builder.query({
      query: (body) => ({
        url: "GetUser",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    setUser: builder.mutation({
      query: (body) => ({
        url: "GetUser",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    // kiosk changes: called getPlayerTransactions
    getPlayerTransaction: builder.query({
      query: (body) => ({
        url: "GetPlayerTransaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    // kiosk changes: called getPlayerTransactions
    setPlayerTransaction: builder.mutation({
      query: (body) => ({
        url: "GetPlayerTransaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    updateUser: builder.mutation({
      query: (body) => ({
        url: "UpdateUser",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    add: builder.mutation({
      query: (body) => ({
        url: "Fill",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    remove: builder.mutation({
      query: (body) => ({
        url: "Bleed",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
    cancelTransaction: builder.mutation({
      query: (body) => ({
        url: "CancelTransaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    getCancelTransactionDetails: builder.mutation({
      query: (body) => ({
        url: "GetCancelTransactionDetails",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    createShift: builder.mutation({
      query: (body) => ({
        url: "CreateShift",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    closeShift: builder.mutation({
      query: (body) => ({
        url: "CloseShift",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    // kiosk changes: GetPeriodicReport is used instead of GetAdminSummary and GetAdminTransaction
    getPeriodicReport: builder.query({
      query: (body) => ({
        url: "GetPeriodicReport",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    // dev3 equivalent of kiosk's GetPeriodicReport summaries field
    getAdminSummary: builder.query({
      query: (body) => ({
        url: "GetAdminSummary",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    // dev3 equivalent of kiosk's GetPeriodicReport transactions field
    getAdminTransaction: builder.query({
      query: (body) => ({
        url: "GetAdminTransaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
      keepUnusedDataFor: 0,
    }),

    getShiftHistory: builder.query({
      query: (body) => ({
        url: "GetShiftHistory",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    purchase: builder.mutation({
      query: (body) => ({
        url: "Purchase",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),

    redeem: builder.mutation({
      query: (body) => ({
        url: "RedeemBalance",
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body,
      }),
    }),
  }),

  keepUnusedDataFor: 0,
})

export const {
  useGetEmployeeQuery,
  useGetShiftTransactionsQuery,
  useGetCashierListQuery,
  useGetUserListQuery,
  useSearchUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  useSetUserMutation,
  useGetPlayerTransactionQuery,
  useUpdateUserMutation,
  useAddMutation,
  useRemoveMutation,
  useCancelTransactionMutation,
  useGetCancelTransactionDetailsMutation,
  useGetPeriodicReportQuery,
  useCreateShiftMutation,
  useCloseShiftMutation,
  useGetShiftHistoryQuery,
  usePurchaseMutation,
  useRedeemMutation,
  useSetPlayerTransactionMutation,
  useGetAdminSummaryQuery,
  useGetAdminTransactionQuery,
  useSendVerificationCodeMutation,
  useCompleteVerificationCodeMutation,
  useCheckVerificationCompletedQuery,
} = userApi
