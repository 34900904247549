import { Card, Typography, Box, ButtonBase } from "@mui/material"
import customStyles from "../../styles/tokens.json"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PeopleIcon from "@mui/icons-material/People"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DrawerButtonProps } from "../../types/IDrawerButtonProps"
import { useCustomSnackbar } from "../multiSnackbar"

const ICONS = {
  Remove: RemoveIcon,
  Add: AddIcon,
  Transactions: PointOfSaleIcon,
  Accounts: PeopleIcon,
}

export default function DrawerButton({
  id,
  title,
  href,
  onClick,
  color = "primary",
  sx = {},
  balance,
  ...other
}: DrawerButtonProps & {
  sx?: object
  balance?: string
}) {
  const theme = customStyles.themePalette[color]

  const IconComponent = useMemo(() => ICONS[title as keyof typeof ICONS], [title])

  const navigate = useNavigate()

  const showSnackbar = useCustomSnackbar()
  const message = "The drawer balance is zero."
  const handleClick = () => {
    if (title === "Remove" && balance === "$0.00") {
      showSnackbar(message, "error")
    } else if (href) {
      navigate(href)
    } else if (onClick) {
      onClick()
    }
  }
  return (
    <>
      <ButtonBase
        onClick={handleClick}
        sx={{ width: "100%", borderRadius: customStyles.borders.radius }}
      >
        <Card
          id={id}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: "calc(5px + 7vmin)",
            boxShadow: 0,
            textAlign: "center",
            color: theme.darker,
            bgcolor: theme.lighter,
            borderRadius: customStyles.borders.radius,
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              boxShadow: 0,
              transform: "scale(1.02)",
            },
            height: "100%",
            width: "100%",
            ...sx,
          }}
          {...other}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              gap: "calc(8px + 2vmin)",
            }}
          >
            {IconComponent && (
              <IconComponent style={{ color: theme.darker, fontSize: "calc(24px + 0.8vmin)" }} />
            )}

            <Typography
              sx={{
                px: 0.5,
                fontSize: "calc(14px + 1.2vmin)",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Card>
      </ButtonBase>
    </>
  )
}
