import React, { useEffect, useState } from "react"
// import {
//   useGetPlayerTransactionsQuery,
//   useGetTransactionsMutation,
// } from "../../redux/user/user.api"
import { Alert, Stack, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import { TableLayout } from "../../layouts/TableLayout"
import customStyles from "../../styles/tokens.json"
import { getColorFromTitle, TitleColor } from "../../constants/getColorFromTitle"
import { getIconFromTitle } from "../../constants/getIconFromTitle"
import { DrawerTableTypography } from "../../styles/styledComponents/DrawerTableTypography"
import { DrawerTableCell } from "../../styles/styledComponents/DrawerTableCell"
import { PlayerTransactionOutput } from "../../types/IUserTransactions"
import { flattenPlayerTransactions } from "../../utils/flatteningFunctions/flattenPlayerTransaction"
import TableSkeleton, { SizeConfig, AlignmentConfig } from "../../layouts/Skeletons/TableSkeleton"

const useScreenSize = () => {
  const theme = useTheme()
  return {
    isMediumScreen: useMediaQuery(theme.breakpoints.up("md")),
    isSmallScreen: useMediaQuery(theme.breakpoints.down("sm")),
  }
}

export const PlayerTransactionsDetails: React.FC<{
  playerId: string
  createdOn: string
  getTransactions: () => Promise<void>
  queryParams: any
  setQueryParams: any
  isLoading: boolean
  error: any
  transactionsApiResponse: any
  transactions: any
  setTransactions: any
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}> = ({
  getTransactions,
  queryParams,
  setQueryParams,
  isLoading,
  error,
  transactionsApiResponse,
  transactions,
  setTransactions,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const { isMediumScreen, isSmallScreen } = useScreenSize()

  useEffect(() => {
    getTransactions()
  }, [queryParams])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)

    setQueryParams((prev: any) => ({
      ...prev,
      page: newPage + 1,
      refetchKey: prev.refetchKey + 1,
    }))
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(0)
    setRowsPerPage(newRowsPerPage)
    setQueryParams((prev: any) => ({
      ...prev,
      page: 1,
      rowsPerPage: newRowsPerPage,
      refetchKey: queryParams.refetchKey + 1,
    }))
  }

  useEffect(() => {
    if (transactionsApiResponse) {
      const flattenedData = flattenPlayerTransactions(transactionsApiResponse)

      setTransactions(flattenedData.response)
    }
  }, [transactionsApiResponse])

  const headCellsConfig = {
    small: ["Date", "Tx.", "Amount", "Cashier"],
    medium: ["Date", "Transaction", "Amount", "Location", "Cashier"],
    large: ["Date", "Transaction", "Amount", "Location", "Cashier"],
  }
  const sizeStylesConfig: SizeConfig = {
    small: ["small", "xSmall", "auto", "small"],
    medium: ["big", "xSmall", "big", "xSmall", "small"],
    large: ["big", "xSmall", "big", "xSmall", "small"],
  }

  const alignmentsConfig: AlignmentConfig = {
    small: ["left", "left", "right", "left"],
    medium: ["left", "left", "right", "left", "left"],
    large: ["left", "left", "right", "left", "left"],
  }

  if (isLoading)
    return (
      <TableSkeleton
        rows={rowsPerPage}
        headCellsConfig={headCellsConfig}
        alignmentsConfig={alignmentsConfig}
        sizeStylesConfig={sizeStylesConfig}
      />
    )
  if (error || !transactionsApiResponse)
    return (
      <Alert severity="error" sx={{ marginBottom: 2 }}>
        <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
          Error retrieving transactions details.
        </Typography>
      </Alert>
    )

  const headCells = isMediumScreen
    ? ["Date", "Transaction", "Amount", "Location", "Cashier"]
    : ["Date", "Tx.", "Amount", "Cashier"]

  const headCellsAlign: ("center" | "inherit" | "left" | "right" | "justify")[] = isMediumScreen
    ? ["left", "left", "right", "left", "left"]
    : ["left", "left", "right", "left"]

  const sizeToWidthMap = {
    auto: "auto",
    xXSmall: customStyles.components.tables.size.xXSmall,
    xSmall: customStyles.components.tables.size.xSmall,
    small: customStyles.components.tables.size.small,
    big: customStyles.components.tables.size.big,
    xBig: customStyles.components.tables.size.xBig,
  }

  const widths = isMediumScreen
    ? (["big", "xSmall", "big", "xSmall", "small"] as const).map((size) => sizeToWidthMap[size])
    : (["xSmall", "xXSmall", "big", "small"] as const).map((size) => sizeToWidthMap[size])

  const startIndex = page * rowsPerPage
  const endIndex = Math.min(startIndex + rowsPerPage, transactionsApiResponse?.total)
  if (transactionsApiResponse?.total === 0 && page !== 1) {
    return (
      <Alert severity="error" sx={{ width: "100%", fontSize: "min(20px, calc(10px + 0.6vmin))" }}>
        No transactions have been made yet.
      </Alert>
    )
  }

  return (
    <TableLayout
      headCells={headCells}
      headCellsAlign={headCellsAlign}
      width={widths}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPage={rowsPerPage}
      page={page}
      rows={transactions}
      totalNumberOfRows={transactionsApiResponse?.total || 0}
      renderRow={(transaction: PlayerTransactionOutput) => {
        const themeColor: TitleColor = getColorFromTitle(transaction.transactionType) as TitleColor
        const theme = customStyles.themePalette[themeColor]
        return (
          <TableRow key={transaction.id}>
            <DrawerTableCell whiteSpaceNowrap="nowrap">
              <div>
                <DrawerTableTypography size={isMediumScreen ? "big" : "xSmall"} variant="primary">
                  {`${transaction.date}`}

                  {isMediumScreen && ` ${transaction.time}`}
                </DrawerTableTypography>
                {!isMediumScreen && (
                  <DrawerTableTypography
                    size="xSmall"
                    variant="secondary"
                  >{`${transaction.time}`}</DrawerTableTypography>
                )}
              </div>
            </DrawerTableCell>

            <DrawerTableCell whiteSpaceNowrap="nowrap">
              <Stack direction="row">
                {getIconFromTitle(transaction.transactionType, "small", theme.light)}{" "}
                {isMediumScreen && transaction.transactionType}
              </Stack>
            </DrawerTableCell>

            <DrawerTableCell whiteSpaceNowrap="nowrap" textAlign="right">
              {transaction.transactionValue}
            </DrawerTableCell>

            <DrawerTableCell whiteSpaceNowrap="nowrap">
              <DrawerTableTypography size="xSmall" variant="primary">
                {transaction.storeNumber}
              </DrawerTableTypography>

              {!isMediumScreen && !isSmallScreen && (
                <DrawerTableTypography size="small" variant="secondary">
                  {transaction.cashier}
                </DrawerTableTypography>
              )}

              {isSmallScreen && (
                <DrawerTableTypography size="small" variant="secondary">
                  {transaction.cashier}
                </DrawerTableTypography>
              )}
            </DrawerTableCell>

            {isMediumScreen && (
              <DrawerTableCell whiteSpaceNowrap="nowrap">
                <DrawerTableTypography
                  size="small"
                  variant="primary"
                >{`${transaction.cashier}`}</DrawerTableTypography>
              </DrawerTableCell>
            )}
          </TableRow>
        )
      }}
    />
  )
}
