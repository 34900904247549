import { TransactionTypeAlt, UnknownTransactionType } from "../../constants/transactionTypes"
import { summariesData } from "../../data/summariesData"
import type { ShiftInput, ShiftOutput } from "../../types/IShift"
import { moneyToEntries } from "../entriesToMoney"
import { formatAmount } from "../formattingFunctions/formatAmount"
import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
import { formatName } from "../formattingFunctions/formatName"
import { formatPlayerNumber } from "../formattingFunctions/formatPlayerNumber"

export function flattenDrawer(input: ShiftInput): ShiftOutput {
  const transactions = input.transactions.map((transaction) => {
    const { formattedDate, formattedTime } = formatDateAndTime(transaction.timestamp)
    const name = formatName(
      transaction.firstName,
      transaction.lastName,
      transaction.userName,
      transaction.playerNumber
    )
    const playerNumber = formatPlayerNumber(transaction.userName, transaction.playerNumber)
    const transactionValue =
      transaction.transactionType === "Bounceback" ||
      transaction.transactionType === "Free Play" ||
      transaction.transactionType === "Cancel Bounceback"
        ? moneyToEntries(transaction.transactionValue).toLocaleString()
        : formatAmount(transaction.transactionValue)
    const transactionType =
      TransactionTypeAlt(transaction.transactionType) || UnknownTransactionType

    return {
      ...transaction,
      name,
      playerNumber,
      date: formattedDate,
      time: formattedTime,
      transactionValue: transactionValue,
      transactionType: transactionType,
    }
  })

  const summaries = summariesData(input)

  const output: ShiftOutput = {
    shiftPOCO: input.shiftPOCO,
    total: input.total,
    transactions: transactions,
    summaries: summaries,
  }

  return output
}
