export function formatAmount(amount: number): string {
  if (typeof amount !== "number") {
    console.error(`Expected a number, but got ${typeof amount}`)
    return "N/A"
  }

  // Check if the amount is negative
  if (amount < 0) {
    const formattedValue = Math.abs(amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return `-$${formattedValue}`
  } else {
    const formattedValue = amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return `$${formattedValue}`
  }
}
