import { ReactComponent as Add } from "../assets/Icons/add.svg"
import { ReactComponent as Adjustment } from "../assets/Icons/adjustment.svg"
import { ReactComponent as Balance } from "../assets/Icons/balance.svg"
import { ReactComponent as FreePlay } from "../assets/Icons/free_play.svg"
import { ReactComponent as Promo } from "../assets/Icons/promo.svg"
import { ReactComponent as Purchase } from "../assets/Icons/purchase.svg"
import { ReactComponent as Redeem } from "../assets/Icons/redeem_b.svg"
import { ReactComponent as Remove } from "../assets/Icons/remove.svg"
import { ReactComponent as StartBalance } from "../assets/Icons/start_balance.svg"

export const getIconFromTitle = (title: string, size: string, color?: string) => {
  const largeSize = "calc(28px + 1.4vmin)"
  const smallSize = "20px"
  const iconSize = size === "small" ? smallSize : largeSize

  switch (title) {
    case "Add":
      return <Add style={{ color }} width={iconSize} height={iconSize} />
    case "Remove":
      return <Remove style={{ color }} width={iconSize} height={iconSize} />
    case "Purchase POS":
    case "Purchase External":
    case "Purchase":
      return <Purchase style={{ color }} width={iconSize} height={iconSize} />
    case "Redeem":
      return <Redeem style={{ color }} width={iconSize} height={iconSize} />
    case "Adjustment":
    case "Cancel Purchase":
    case "Cancel Promo":
    case "Cancel Redeem":
    case "Cancel Promo":
      return <Adjustment style={{ color }} width={iconSize} height={iconSize} />
    case "Free Play":
      return <FreePlay style={{ color }} width={iconSize} height={iconSize} />
    // case "Retention":
    //   return <ReplayIcon  fontSize={size} />
    case "Promo":
    case "Comp":
      return <Promo style={{ color }} width={iconSize} height={iconSize} />
    case "Start Balance":
      return <StartBalance style={{ color }} width={iconSize} height={iconSize} />
    case "Balance":
    case "End Balance":
    case "Net":
      return <Balance style={{ color }} width={iconSize} height={iconSize} />
    default:
      return null
  }
}
