import React from "react"
import { Table, TableBody, TableRow, TableCell } from "@mui/material"

interface ReceiptDetailsProps {
  receiptName?: string | undefined
  currentCashierName?: string
  storeNum?: number
  storeName?: string
  formattedDate?: string
  formattedTime?: string
  customerReceipts?: string[]
  authShift?: any
  formatPhoneNumber: (phone: string) => string
}

const ReceiptDetails: React.FC<ReceiptDetailsProps> = ({
  receiptName,
  currentCashierName,
  storeNum,
  storeName,
  formattedDate,
  formattedTime,
  customerReceipts,
  authShift,
  formatPhoneNumber,
}) => {
  const accountType = authShift.response?.accountType
  const accountClassification = authShift.response?.classification
  let roleLabel
  if (accountClassification === 1) {
    if (accountType === 1) {
      roleLabel = "Cashier"
    } else if (accountType === 2) {
      roleLabel = "Admin"
    }
  } else if (accountClassification === 5) {
    roleLabel = "Manager"
  }

  return (
    <Table
      style={{
        width: "100%",
        marginBottom: "4px",
      }}
    >
      <TableBody>
        <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
          <TableCell
            style={{
              padding: "0",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "left",
              border: "none",
            }}
          >
            {receiptName === "Shift" ? `Printed By: ` : `${roleLabel}: `}
          </TableCell>
          <TableCell
            style={{
              padding: "0",
              paddingRight: "2px",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "right",
              border: "none",
            }}
          >
            {currentCashierName}
          </TableCell>
        </TableRow>
        {!customerReceipts?.includes(receiptName || "") && (
          <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
            <TableCell
              style={{
                padding: "0",
                margin: "0",
                fontSize: "0.65rem",
                textAlign: "left",
                border: "none",
              }}
            >
              Date:
            </TableCell>
            <TableCell
              style={{
                textAlign: "right",
                padding: "0",
                margin: "0",
                border: "none",
                fontSize: "0.65rem",
              }}
            >
              {formattedDate} {formattedTime}
            </TableCell>
          </TableRow>
        )}
        <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
          <TableCell
            style={{
              padding: "0",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "left",
              border: "none",
              verticalAlign: "top",
            }}
          >
            Store ID:
          </TableCell>
          <TableCell
            style={{
              textAlign: "right",
              padding: "0",
              margin: "0",
              border: "none",
              fontSize: "0.65rem",
              verticalAlign: "top",
            }}
          >
            {storeNum}
          </TableCell>
        </TableRow>
        <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
          <TableCell
            style={{
              padding: "0",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "left",
              whiteSpace: "nowrap",
              border: "none",
              verticalAlign: "top",
            }}
          >
            Store Name:
          </TableCell>
          <TableCell
            style={{
              textAlign: "right",
              padding: "0",
              margin: "0",
              border: "none",
              fontSize: "0.65rem",
              verticalAlign: "top",
            }}
          >
            {storeName}
          </TableCell>
        </TableRow>
        <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
          <TableCell
            style={{
              padding: "0",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "left",
              border: "none",
              verticalAlign: "top",
            }}
          >
            Address:
          </TableCell>
          <TableCell
            style={{
              textAlign: "right",
              padding: "0",
              margin: "0",
              border: "none",
              fontSize: "0.65rem",
              verticalAlign: "top",
            }}
          >
            {authShift?.response?.parameters?.customerAddress}
          </TableCell>
        </TableRow>
        <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
          <TableCell
            style={{
              padding: "0",
              margin: "0",
              fontSize: "0.65rem",
              textAlign: "left",
              border: "none",
              verticalAlign: "top",
            }}
          >
            Phone:
          </TableCell>
          <TableCell
            style={{
              textAlign: "right",
              padding: "0",
              margin: "0",
              border: "none",
              fontSize: "0.65rem",
              verticalAlign: "top",
            }}
          >
            {formatPhoneNumber(authShift?.response?.parameters?.storeContactPhone)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ReceiptDetails
