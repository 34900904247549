import React, { ReactNode } from "react"
import { CustomError } from "../utils/errorHandler"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { SerializedError } from "@reduxjs/toolkit"
import { Box, IconButton, Typography } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"
import error500 from "../assets/error500.jpg"
import { useOidc } from "@axa-fr/react-oidc"

type ErrorType = CustomError | null | FetchBaseQueryError | SerializedError | undefined

interface Props {
  error: ErrorType
  children: ReactNode
  message?: string
}

export function ErrorLayout({ error, children, message = "Something went wrong!" }: Props) {
  const { logout } = useOidc()

  const handleBack = async () => {
    if (window.location.href.endsWith("/home")) {
      sessionStorage.clear()
      localStorage.clear()
      await logout("/")
      return
    }
    window.history.back()
  }

  if (error) {
    if ("status" in error) {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <IconButton color="primary" onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Return</Typography>
            </Box>
            <div>
              {message} {error?.status}
            </div>
            <Box></Box>
          </Box>
        </div>
      )
    }

    return (
      <div>
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <IconButton color="primary" onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <div>{message}</div>
          <Box>
            <img
              src={error500}
              alt="Coming Soon"
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: "calc(100vh - 48px)", // Subtracting the height of the IconButton
                margin: "0 auto",
              }}
            />
          </Box>
        </Box>
      </div>
    )
  }

  return <>{children}</>
}
