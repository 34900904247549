import { BottomNav } from "../components/BottomNav"
import { SideNav } from "../components/SideNav"
interface Props {
  children: JSX.Element
}

export function NavLayout(props: Props) {
  return (
    <div>
      <div>
        <div className="hidden absolute left-0 sm:block">
          <SideNav />
        </div>

        <div className="block absolute bottom-0 sm:hidden">
          <BottomNav />
        </div>
      </div>
      <div className="max-w-6xl mx-auto pb-20 sm:pl-20 sm:pr-5">{props.children}</div>
    </div>
  )
}
