import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { store } from "./redux/store"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"

import { ThemeProvider } from "@mui/material/styles"
import { customTheme } from "./styles/theme"
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc"
import NotAuthenticated from "./callbacks/NotAuthenticated"
import AuthSuccessComp from "./callbacks/AuthSuccessComp"
import { LoadingComponent } from "./callbacks/LoadingComponent"
import { SessionLostComponent } from "./callbacks/SessionLostComponent"
import config from "./config"

const Loading = () => <LoadingComponent />
const AuthenticatingError = () => <NotAuthenticated />
const Authenticating = () => <LoadingComponent />
const SessionLost = () => <SessionLostComponent />
const ServiceWorkerNotSupported = () => <p>Not supported</p>
const CallBackSuccess = () => <AuthSuccessComp />

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <OidcProvider
        configuration={config}
        loadingComponent={Loading}
        authenticatingErrorComponent={AuthenticatingError}
        authenticatingComponent={Authenticating}
        sessionLostComponent={SessionLost}
        serviceWorkerNotSupportedComponent={ServiceWorkerNotSupported}
        callbackSuccessComponent={CallBackSuccess}
      >
        <ThemeProvider theme={customTheme}>
          <OidcSecure>
            <App />
          </OidcSecure>
        </ThemeProvider>
      </OidcProvider>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
