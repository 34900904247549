import { useLayoutEffect, useRef, useState } from "react"
import { useCreateShiftMutation } from "../redux/user/user.api"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { NumericFormat } from "react-number-format"
import { IEmployee } from "../types/IEmployee"
import { ShiftResponse } from "../types/TCreateShift"
import { useOidc } from "@axa-fr/react-oidc"
import { formatAmount } from "../utils/formattingFunctions/formatAmount"
import { entriesToMoney, moneyToEntries } from "../utils/entriesToMoney"
import { ShiftDialogSkeleton } from "../layouts/Skeletons/CurrentShitPopupSkeleton"
import { CustomDialog } from "../styles/styledComponents/CustomDialog"
import { asyncErrorHandler } from "../utils/errorHandler"
import { useReactToPrint } from "react-to-print"
import { ReceiptContent } from "./Print"
import { useCustomSnackbar } from "./multiSnackbar"
import { formatDateAndTime } from "../utils/formattingFunctions/formatDateAndTime"

interface Props {
  employee: IEmployee
  isLoading: boolean
}

type STATUS = "INITIAL" | "CREATE" | "CONFIRM"

export default function CurrentShiftPopup({ employee, isLoading }: Props) {
  const MAX_ADD_CASH_LIMIT = 99999
  const [open, setOpen] = useState(true)
  const [status, setStatus] = useState<STATUS>("INITIAL")
  const [balance, setBalance] = useState<number>(0)
  const [createShift] = useCreateShiftMutation()
  const { logout } = useOidc()
  const formattedBalance = formatAmount(balance)
  const [loading, setLoading] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)
  const getSessionStorage = sessionStorage.getItem("CONFIRM_CURRENT_SHIFT")
  const [printDialogOpen, setPrintDialogOpen] = useState(false)
  const [shiftResponse, setShiftResponse] = useState<any>(null)
  const showSnackbar = useCustomSnackbar()
  useLayoutEffect(() => {
    window.blur()
    window.focus()
    if (sessionStorage.getItem("CONFIRM_CURRENT_SHIFT") === "true") {
      onClose()
    }
  }, [])
  const startShiftReceiptRef = useRef<HTMLDivElement>(null)
  const handleOpenPrintDialog = () => {
    setPrintDialogOpen(true)
  }
  const handleClosePrintDialog = () => {
    setPrintDialogOpen(false)
  }

  const handlePrint = useReactToPrint({
    content: () => startShiftReceiptRef.current,
    removeAfterPrint: false,
  })
  const onClose = () => setOpen(false)
  const closeDialog = () => cancelShiftHandler()
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))
  const createShiftHandler = asyncErrorHandler(async () => {
    try {
      setLoading(true)
      setApiLoading(true)

      const response = await createShift({
        StartAmount: moneyToEntries(balance),
        Username: employee?.response?.loginName,
      })

      if ("error" in response) {
        console.error("An error occurred:", response.error)
        return
      }
      if (!("error" in response)) {
        setShiftResponse(response.data)
        const res: ShiftResponse = response as ShiftResponse
        const authShiftString = sessionStorage.getItem("authShift")
        const authShift = authShiftString ? JSON.parse(authShiftString) : {}
        authShift.currentShift = res.data
        sessionStorage.setItem("authShift", JSON.stringify(authShift))
        localStorage.removeItem("selectedUser")

        confirmCurrentShift()
      }
      handleOpenPrintDialog()
    } finally {
      setLoading(false)
      setApiLoading(false)
    }
  }, showSnackbar)

  const cancelShiftHandler = async () => {
    sessionStorage.clear()
    localStorage.clear()
    await logout("/")
  }

  const confirmCurrentShift = () => {
    sessionStorage.setItem("CONFIRM_CURRENT_SHIFT", "true")
    localStorage.removeItem("selectedUser")
    onClose() // Closes the current dialog
  }
  const { formattedDate, formattedTime } = formatDateAndTime(employee?.currentShift?.starting)

  const getConfig = (status: STATUS) => {
    switch (status) {
      case "INITIAL":
        return {
          title: employee?.currentShift ? "Shift Started" : "Start a New Shift",
          dialogContentText: employee?.currentShift
            ? "Do you want to continue the current shift or create a new one?"
            : "Currently, there is no open shift. To continue, a new shift must be started. If you wish to end your session, please select the log out option.",
          subHeader: employee?.currentShift ? `On ${formattedDate} At ${formattedTime}` : null,
          primaryButtonText: employee?.currentShift ? "Continue" : "Create new shift",
          secondaryButtonText: employee?.currentShift ? "Create new shift" : "Log Out",
          onPrimaryButtonClick: employee?.currentShift
            ? confirmCurrentShift
            : () => setStatus("CREATE"),
          onSecondaryButtonClick: employee?.currentShift
            ? () => setStatus("CREATE")
            : cancelShiftHandler,
          content: null,
        }
      case "CREATE":
        return {
          title: "New Shift",
          dialogContentText: "Please enter starting balance",
          primaryButtonText: "Create",
          secondaryButtonText: "Back",
          onPrimaryButtonClick: () => setStatus("CONFIRM"),
          onSecondaryButtonClick: () => setStatus("INITIAL"),
          content: (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-amount">Starting Balance</InputLabel>
              <NumericFormat
                id="outlined-adornment-amount"
                label="Starting Balance"
                autoFocus
                value={balance === 0 ? "" : balance}
                thousandSeparator=","
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                type="tel"
                customInput={OutlinedInput}
                autoComplete="off"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return (
                    floatValue === undefined || (floatValue > 0 && floatValue <= MAX_ADD_CASH_LIMIT)
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values
                  setBalance(Number(value))
                }}
              />
            </FormControl>
          ),
        }
      case "CONFIRM":
        return {
          title: "Confirm",
          content: () => (
            <>
              <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                  Starting Balance:
                </Typography>
                <Typography variant="h6">{formattedBalance}</Typography>
              </Stack>
              <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                Do you want to proceed?
              </Typography>
            </>
          ),
          primaryButtonText: "Yes",
          secondaryButtonText: "No",
          onPrimaryButtonClick: () => {
            createShiftHandler()
          },
          onSecondaryButtonClick: () => setStatus("CREATE"),
          dialogContentText: null,
        }
      default:
        return {
          title: "",
          dialogContentText: "",
          primaryButtonText: "",
          secondaryButtonText: "",
          onPrimaryButtonClick: () => {
            //TODO
          },
          onSecondaryButtonClick: () => {
            //TODO
          },
          content: null,
        }
    }
  }

  const {
    title,
    subHeader,
    dialogContentText,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    content,
  } = getConfig(status)

  if (isLoading) {
    return <ShiftDialogSkeleton />
  }
  console.log("shiftResponse", shiftResponse)
  console.log("shiftResponse?.starting", shiftResponse?.starting)
  console.log("shiftResponse?.startAmount", shiftResponse?.startAmount)
  return (
    <>
      <div style={{ display: "none" }}>
        <ReceiptContent
          ref={startShiftReceiptRef}
          starting={shiftResponse?.starting}
          startBalance={formatAmount(entriesToMoney(shiftResponse?.startAmount))}
          receiptName="Start of Shift"
        />
      </div>
      <div>
        {getSessionStorage !== "true" ? (
          <CustomDialog
            disableHandleClose={true}
            removeClose={false}
            open={open}
            title={title}
            subHeader={subHeader ?? undefined}
            content={typeof content === "function" ? content() : content}
            dialogContentText={dialogContentText ?? undefined}
            primaryActionText={primaryButtonText}
            secondaryActionText={secondaryButtonText}
            onPrimaryAction={onPrimaryButtonClick}
            onSecondaryAction={onSecondaryButtonClick}
            handleClose={closeDialog}
            isLoading={apiLoading}
            disabledPrimaryAction={apiLoading}
          />
        ) : null}
      </div>
      <CustomDialog
        open={printDialogOpen}
        title="Print Start of Shift"
        content={
          <Typography>
            Your shift has been started. Would you like to print the shift details?
          </Typography>
        }
        primaryActionText="Print"
        secondaryActionText="Close"
        onPrimaryAction={() => {
          handlePrint()
          handleClosePrintDialog()
        }}
        onSecondaryAction={handleClosePrintDialog}
        handleClose={handleClosePrintDialog}
      />
    </>
  )
}
