import { Typography, Box } from "@mui/material"
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import { styled } from "@mui/material/styles"

export const SummarySection = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
})

export const SummaryItem = styled(Typography)({
  flex: "1 1 auto",
  flexDirection: "row-reverse",
  minWidth: "150px",
  textAlign: "center",
})

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: `1px solid rgba(128, 128, 128, .125)`,
  },
  "&:before": {
    display: "none",
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(128, 128, 128, .125)",
}))
