import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { useGetAdminSummaryQuery, useGetAdminTransactionQuery } from "../../redux/user/user.api"
import { TransactionTypeNumbers } from "../../constants/transactionTypes"
import { ISummaries } from "../../types/ISummaries"
import { TransactionOutput } from "../../types/ITransactionReport"
import { QueryParameters } from "../../types/IQueryParameters"
import { flattenTransactions } from "../../utils/flatteningFunctions/flattenTransactions"
import { OverviewSummaryGridSkeleton } from "../../layouts/Skeletons/OverviewSummarySkeleton"
import { ErrorLayout } from "../../layouts/ErrorLayout"
import { OverviewSummaryGrid } from "../../components/OverviewSummary/OverviewSummaryGrid"
import { RenderCalendar } from "../../components/TransactionsReport/RenderCalendar"
import { TransactionsReportTable } from "../../components/TransactionsReport/TransactionsReportTable"
import { asyncErrorHandler } from "../../utils/errorHandler"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import IconButton from "@mui/material/IconButton"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import Collapse from "@mui/material/Collapse"
import { formatDateAndTime } from "../../utils/formattingFunctions/formatDateAndTime"
import TableSkeleton, { AlignmentConfig, SizeConfig } from "../../layouts/Skeletons/TableSkeleton"
import { useCustomSnackbar } from "../../components/multiSnackbar"
export const TransactionsReport: React.FC = () => {
  const startOfDate = dayjs().startOf("day")
  const endOfDate = dayjs().endOf("day")

  const [giveMargin, setGiveMargin] = useState(false)
  const [transactions, setTransactions] = useState<TransactionOutput[]>([])
  const [summaries, setSummaries] = useState<ISummaries[]>([])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    startOfDate,
    endOfDate,
  ])
  const [page, setPage] = useState<number>(1)

  const [loadingTransactions, setLoadingTransactions] = useState(true)
  const formatToISOString = (date: Dayjs | null) => {
    return date ? dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
  }
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [loadingReport, setLoadingReport] = useState(true)
  const [summaryQueryParameters, setSummaryQueryParameters] = useState<QueryParameters>({
    transactionTypes: [],
    page: 1,
    perPage: rowsPerPage,
    from: formatToISOString(startOfDate.hour(4).minute(0).second(0).millisecond(0)),
    to: formatToISOString(endOfDate.hour(3).minute(59).second(59).millisecond(999).add(1, "day")),
  })

  const [transactionQueryParameters, setTransactionQueryParameters] = useState<QueryParameters>({
    transactionTypes: [],
    page: 1,
    perPage: rowsPerPage,
    from: formatToISOString(startOfDate.hour(4).minute(0).second(0).millisecond(0)),
    to: formatToISOString(endOfDate.hour(3).minute(59).second(59).millisecond(999).add(1, "day")),
  })

  const [queryParams] = useSearchParams()
  const [refetchKey, setRefetchKey] = useState(0)
  const [currentTReportFrom, setCurrentTReportFrom] = useState<string | undefined>(undefined)
  const [currentTReportTo, setCurrentTReportTo] = useState<string | undefined>(undefined)
  const transactionQuery = useGetAdminTransactionQuery({
    ...transactionQueryParameters,
    refetchKey,
  })
  const summaryQuery = useGetAdminSummaryQuery({ ...summaryQueryParameters, refetchKey })

  const isError = summaryQuery.isError || transactionQuery.isError
  const error = summaryQuery.error || transactionQuery.error

  const [applyingFilters, setApplyingFilters] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [isExpanded, setIsExpanded] = useState(true)

  useEffect(() => {
    setLoadingReport(true)
    setApplyingFilters(true)
    const titleFromParams = queryParams.get("title")
    const titleFromParamsArray = titleFromParams ? titleFromParams.split(",") : []
    const numbersFromParamsArray = titleFromParamsArray
      .map((title) => {
        if (title === "Adjustment") {
          return [
            TransactionTypeNumbers["Cancel Purchase"],
            TransactionTypeNumbers["Cancel Redeem"],
          ]
        }
        return TransactionTypeNumbers[title]
      })
      .flat()
    setPage(1)
    setTransactions([])
    setTransactionQueryParameters((prevParams) => ({
      ...prevParams,
      page: 1,
      transactionTypes: numbersFromParamsArray,
    }))
    setPage(1)
  }, [queryParams])

  useEffect(() => {
    if (transactionQueryParameters.from) {
      const { formattedDate, formattedTime } = formatDateAndTime(
        transactionQueryParameters.from,
        false,
        true
      )
      console.log("formattedDate", formattedDate)
      setCurrentTReportFrom(formattedDate)
    }
    console.log("transactionQueryParameters.to", transactionQueryParameters.to)
    console.log("transactionQueryParameters.from", transactionQueryParameters.from)

    if (transactionQueryParameters.to) {
      const adjustedToPresented = dayjs(transactionQueryParameters.to).subtract(1, "day")
      const { formattedDate, formattedTime } = formatDateAndTime(
        formatToISOString(adjustedToPresented),
        false,
        true
      )
      setCurrentTReportTo(formattedDate)
    }
  }, [transactionQueryParameters])

  const fetchReport = useCallback(() => {
    const defaultStartOfDate = dayjs().hour(4).minute(0).second(0).millisecond(0)
    const defaultEndOfDate = dayjs().hour(3).minute(59).second(59).millisecond(999).add(1, "day")

    const adjustedFrom = formatToISOString(
      headerDateRange[0]
        ? headerDateRange[0].hour(4).minute(0).second(0).millisecond(0)
        : defaultStartOfDate
    )

    const adjustedTo = formatToISOString(
      headerDateRange[1]
        ? headerDateRange[1].hour(3).minute(59).second(59).millisecond(999).add(1, "day")
        : defaultEndOfDate
    )

    setTransactionQueryParameters((prevParams) => ({
      ...prevParams,
      from: adjustedFrom,
      to: adjustedTo,
    }))
    setSummaryQueryParameters((prevParams) => ({
      ...prevParams,
      from: adjustedFrom,
      to: adjustedTo,
    }))
  }, [headerDateRange, startOfDate, endOfDate])

  useEffect(() => {
    if (summaryQuery.data && transactionQuery.data) {
      const { transactions: fetchedTransactions, summaries: fetchedSummaries } =
        flattenTransactions({ ...summaryQuery.data, ...transactionQuery.data })
      setTransactions(fetchedTransactions)
      setSummaries(fetchedSummaries)
      setLoadingReport(false)
      setLoadingTransactions(false)
      if (firstLoad) setFirstLoad(false)
      setApplyingFilters(false)
    }
    if (isError && error) {
      setLoadingReport(false)
      setApplyingFilters(false)
    }
  }, [transactionQuery.data, summaryQuery.data])
  useEffect(() => {
    setPage(1)
  }, [headerDateRange])

  const handleExpandClick = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded)
  }
  const onGetReportClick = async () => {
    setFirstLoad(true)
    setLoadingReport(true)
    setRefetchKey((prevKey) => prevKey + 1)
    setPage(1)
    await fetchReport()
    if (isSmallScreen) {
      setIsExpanded(false)
    }
  }
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  useEffect(() => {
    if (!isSmallScreen) {
      setIsExpanded(true)
    }
  }, [isSmallScreen])
  const handlePageChange = (np: number) => {
    const newPage = np + 1
    setPage(newPage)

    setTransactionQueryParameters((prev) => ({
      ...prev,
      page: newPage,
    }))

    setLoadingTransactions(true)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1) // This will reset the page to the first page

    setRowsPerPage(newRowsPerPage)
    setTransactionQueryParameters((prev) => ({
      ...prev,
      page: 1, // Ensure the API request is also set to fetch the first page
      perPage: newRowsPerPage,
    }))
    setRefetchKey((prevKey) => prevKey + 1) // Force a refetch
  }
  const headCellsConfig = {
    small: ["Account", "Date", "Tx.", "Amount", "Cashier"],
    medium: ["Account", "Name", "Date", "Transaction", "Amount", "Cashier"],
    large: ["Account", "Date", "Transaction", "Amount", "Cashier"],
  }
  const sizeStylesConfig: SizeConfig = {
    small: ["small", "xSmall", "auto", "small", "small"],
    medium: ["small", "big", "big", "small", "big", "small", "small"],
    large: ["small", "small", "auto", "small", "small"],
  }

  const alignmentsConfig: AlignmentConfig = {
    small: ["left", "left", "left", "right", "left"],
    medium: ["left", "left", "left", "left", "right", "left"],
    large: ["left", "left", "left", "right", "left"],
  }
  const showSnackbar = useCustomSnackbar()
  return (
    <ErrorLayout error={error}>
      <>
        <Box
          bgcolor="primary.main"
          color="white"
          p={0.5}
          mb={1}
          display="grid"
          gridTemplateColumns={isSmallScreen ? "min-content 1fr min-content" : "1fr"}
          alignItems="center"
        >
          {isSmallScreen && (
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              {isExpanded ? (
                <ExpandLessIcon sx={{ color: "white" }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "white" }} />
              )}
            </IconButton>
          )}

          <Typography variant="h5" align="center">
            Transactions Report
          </Typography>
          {isSmallScreen && <div style={{ width: "28px" }}></div>}
        </Box>
        {isSmallScreen ? (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Card
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: isSmallScreen ? 0 : 4,
                borderBottomRightRadius: isSmallScreen ? 0 : 4,
                paddingY: 3,
              }}
            >
              <Grid container spacing={1} alignItems="stretch" paddingX={!isSmallScreen ? 2 : 0}>
                <Grid item xs={12} sm={9}>
                  <Box display="flex" justifyContent="flex-start" marginX={isSmallScreen ? 3 : 0}>
                    <RenderCalendar
                      disabled={loadingReport}
                      headerDateRange={headerDateRange}
                      setReportDateRange={setHeaderDateRange}
                      setGiveMargin={setGiveMargin}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginTop={isSmallScreen ? 1 : 0}
                    sx={{ height: "100%" }}
                  >
                    <Button
                      sx={{
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        fontSize: "min(16px,calc(10px + 0.6vmin))",
                        height: "100%",
                        marginX: isSmallScreen ? 3 : 0,
                      }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={loadingReport}
                      onClick={asyncErrorHandler(async () => {
                        await onGetReportClick()
                      }, showSnackbar)}
                    >
                      {loadingReport && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      Get Report
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Collapse>
        ) : (
          <Card
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: isSmallScreen ? 0 : 4,
              borderBottomRightRadius: isSmallScreen ? 0 : 4,
              paddingY: 3,
            }}
          >
            <Grid container spacing={1} alignItems="stretch" paddingX={!isSmallScreen ? 2 : 0}>
              <Grid item xs={12} sm={9}>
                <Box display="flex" justifyContent="flex-start" marginX={isSmallScreen ? 3 : 0}>
                  <RenderCalendar
                    disabled={loadingReport}
                    headerDateRange={headerDateRange}
                    setReportDateRange={setHeaderDateRange}
                    setGiveMargin={setGiveMargin}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box display="flex" justifyContent="flex-end" marginTop={0} sx={{ height: "100%" }}>
                  <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      fontSize: "min(16px,calc(10px + 0.6vmin))",
                      height: "100%",
                      marginX: isSmallScreen ? 3 : 0,
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingReport}
                    onClick={asyncErrorHandler(async () => {
                      await onGetReportClick()
                    }, showSnackbar)}
                  >
                    {loadingReport && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    Get Report
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        )}
        <Box marginBottom={0} marginLeft={0} marginRight={0} marginTop={2}>
          <Box
            style={{
              opacity: applyingFilters ? 0.3 : 1,
              pointerEvents: applyingFilters ? "none" : "auto",
            }}
          >
            {loadingReport && firstLoad ? (
              <OverviewSummaryGridSkeleton itemCount={8} />
            ) : (
              <OverviewSummaryGrid
                hasFilters
                totalNumTransactions={transactionQuery?.data?.total}
                summaries={summaries}
                overviewHeader={
                  <div>
                    Report For: {currentTReportFrom} to {currentTReportTo}
                  </div>
                }
                starting={currentTReportFrom}
                ending={currentTReportTo}
                receiptName="Transactions Report"
              />
            )}
          </Box>

          {(loadingReport && firstLoad) || applyingFilters ? (
            <TableSkeleton
              rows={rowsPerPage}
              headCellsConfig={headCellsConfig}
              alignmentsConfig={alignmentsConfig}
              sizeStylesConfig={sizeStylesConfig}
            />
          ) : (
            <Box marginTop={2}>
              <TransactionsReportTable
                users={transactions}
                onPageChange={handlePageChange}
                totalNumberOfRows={transactionQuery?.data?.total}
                onRowsPerPageChange={handleRowsPerPageChange}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                loading={loadingTransactions}
              />
            </Box>
          )}
        </Box>
      </>
    </ErrorLayout>
  )
}
