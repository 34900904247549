import React, { useState, useCallback, useEffect } from "react"
import { FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material"
import { CustomDialog } from "../styles/styledComponents/CustomDialog"
import { NumericFormat } from "react-number-format"
import {
  useSendVerificationCodeMutation,
  useCompleteVerificationCodeMutation,
  useCheckVerificationCompletedQuery,
} from "../redux/user/user.api"
import { useCustomSnackbar } from "./multiSnackbar"

interface PhoneVerificationDialogProps {
  open: boolean
  onClose: (success: boolean) => void
  playerId: string
}

const PhoneVerificationDialog: React.FC<PhoneVerificationDialogProps> = ({
  open,
  onClose,
  playerId,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [verificationCode, setVerificationCode] = useState("")
  const { data: checkVerificationData, refetch } = useCheckVerificationCompletedQuery(
    { playerId },
    { skip: !showDialog }
  )
  const [sendVerificationCode] = useSendVerificationCodeMutation()
  const [completeVerificationCode, { isLoading: isCompletingCode }] =
    useCompleteVerificationCodeMutation()
  const [message, setMessage] = useState({ text: "", severity: "" })
  const showSnackbar = useCustomSnackbar()
  const handleSendVerificationCode = useCallback(async () => {
    try {
      const response = await sendVerificationCode({ playerId }).unwrap()
      if (response.success) {
        setShowDialog(true)
      } else {
        let errorMessage = response.message || "Failed to send verification code."
        if (
          errorMessage.includes(" is not a valid phone number") ||
          errorMessage.includes("Invalid 'To' Phone Number:")
        ) {
          errorMessage = "Phone Number is not Valid"
        }
        showSnackbar(errorMessage, "warning")
        setShowDialog(false)
        onClose(false)
      }
    } catch (error) {
      showSnackbar("An error occurred while sending the verification code.", "error")
      setShowDialog(false)
      onClose(false)
    }
  }, [playerId, sendVerificationCode])

  const handleVerification = useCallback(async () => {
    try {
      const response = await completeVerificationCode({
        playerId,
        submittedCode: verificationCode,
      }).unwrap()
      console.log("response.isValid", response.isValid)
      if (response?.isValid === true && response?.success === true) {
        onClose(true)
      }
      if (!response?.isValid) {
        showSnackbar(response?.message, "error")
      }
    } catch (error) {
      showSnackbar("An error occurred during verification.", "error")
    }
  }, [playerId, verificationCode, completeVerificationCode, onClose])

  const [verificationSuccessSnackbarShown, setVerificationSuccessSnackbarShown] = useState(false)

  useEffect(() => {
    let intervalId: NodeJS.Timer | null = null
    let timeoutId: NodeJS.Timeout | null = null
    const maxDuration = 300000

    if (showDialog && playerId) {
      refetch()
      intervalId = setInterval(() => {
        refetch()
      }, 5000)
      timeoutId = setTimeout(() => {
        if (intervalId) clearInterval(intervalId)
        setShowDialog(false)
        onClose(false)
      }, maxDuration)
    }

    if (checkVerificationData?.isComplete && !verificationSuccessSnackbarShown) {
      if (intervalId) clearInterval(intervalId)
      showSnackbar("Phone number verified!", "success")
      setVerificationSuccessSnackbarShown(true)
      onClose(true)
      setShowDialog(false)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [checkVerificationData, showDialog])
  useEffect(() => {
    if (!open) {
      setVerificationSuccessSnackbarShown(false)
    }
  }, [open])

  useEffect(() => {
    if (open && playerId) {
      handleSendVerificationCode()
    }
  }, [open, playerId, handleSendVerificationCode])

  return (
    <>
      <CustomDialog
        open={showDialog}
        title="Phone Number Verification"
        disabledPrimaryAction={!verificationCode}
        content={
          <>
            <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
              The verification SMS was sent to the customer&apos;s phone number.
            </Typography>
            <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))", mt: 1 }}>
              It may take up to 2 minutes for the SMS to arrive.
            </Typography>
            <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))", mt: 1 }}>
              Please ask the customer to click on the activation link or to provide the verification
              code they received.
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-amount">Code</InputLabel>
              <NumericFormat
                id="outlined-adornment-amount"
                label="Amount"
                type="tel"
                autoFocus
                value={verificationCode}
                allowNegative={false}
                autoComplete="off"
                customInput={OutlinedInput}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </FormControl>
            {message.text && (
              <Typography sx={{ mt: 2, color: message.severity === "error" ? "red" : "green" }}>
                {message.text}
              </Typography>
            )}
          </>
        }
        primaryActionText="Verify"
        isLoading={isCompletingCode}
        secondaryActionText="Cancel"
        onPrimaryAction={handleVerification}
        onSecondaryAction={() => {
          onClose(false)
          setShowDialog(false)
        }}
        handleClose={() => {
          onClose(false)
          setShowDialog(false)
        }}
      />
    </>
  )
}

export default PhoneVerificationDialog
