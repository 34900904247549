import { Skeleton } from "@mui/material"

export const ShiftTableSkeleton: React.FC = () => {
  return (
    <div>
      <Skeleton style={{ backgroundColor: "#0000000f" }} variant="rectangular" height={5} />

      {[...Array(10)].map((_, index) => (
        <div key={index} style={{ marginBottom: "1px" }}>
          <Skeleton style={{ backgroundColor: "#0000000f" }} variant="rectangular" height={25} />
          <Skeleton style={{ backgroundColor: "#0000000f" }} variant="rectangular" height={25} />
        </div>
      ))}

      <div className="flex my-8 justify-center">
        <Skeleton
          style={{ backgroundColor: "#0000000f" }}
          variant="rectangular"
          width={200}
          height={20}
        />
      </div>
    </div>
  )
}
