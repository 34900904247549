import React, { ReactNode } from "react"
import { Typography } from "@mui/material"
import customStyles from "../tokens.json"

interface OverviewTypographyProps {
  variant?: "overviewTitle" | "overviewAmount"
  amount?: boolean
  children: ReactNode
}

const OverviewTypography: React.FC<OverviewTypographyProps> = ({ variant, children, amount }) => {
  const conditionalStyles = amount
    ? { fontWeight: "normal", color: customStyles.colors.text.tertiary }
    : { fontWeight: "bold" }

  return (
    <Typography
      variant={variant}
      sx={{
        fontSize: "min(16px,calc(8px + 1.5vmin))",
        width: "12ch",
        maxWidth: "12ch",
        whiteSpace: "normal",
        ...conditionalStyles,
      }}
    >
      {children}
    </Typography>
  )
}

export default OverviewTypography
