import React from "react"
import { Box } from "@mui/material"
import customStyles from "../tokens.json"

interface DrawerBoxProps {
  children: React.ReactNode
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  paddingLeft?: number
  paddingRight?: number
  marginRight?: number
  borderRadius?: number
  className?: string
  isTable?: boolean
}

export const DrawerBox: React.FC<DrawerBoxProps> = ({
  children,
  marginTop = 8,
  marginRight = 0,
  marginBottom = 8,
  marginLeft = 0,
  paddingLeft,
  paddingRight,
  borderRadius = customStyles.borders.radius,
  className = "",
}) => {
  return (
    <Box
      className={className}
      sx={{
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingLeft,
        paddingRight,
        borderRadius,
      }}
    >
      {children}
    </Box>
  )
}
