import { forwardRef } from "react"
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { formatDateAndTime } from "../utils/formattingFunctions/formatDateAndTime"
import { formatPhoneNumber } from "../utils/formattingFunctions/formatPhoneNumber"
import ReceiptFooter from "./Receipt/ReceiptFooter"
import ReceiptDetails from "./Receipt/ReceiptDetails"
import ReceiptInfoSections from "./Receipt/ReceiptInfoSections"

export interface ReceiptProps {
  accountId?: string | number | null
  freeEntries?: string | number
  winPoints?: string
  promo?: string
  purchase?: string
  purchases?: number
  comp?: string
  startBalance?: string
  endBalance?: string
  closeBalance?: number
  prizePoints?: number
  redeem?: string
  redeems?: string
  promos?: number
  freePlay?: string
  freePlays?: number
  adjustments?: number
  adjustment?: string
  from?: string
  to?: string
  add?: string
  remove?: string
  balance?: string
  cancelPurchase?: string
  cancelRedeem?: string
  cencelPromo?: string
  bleeds?: number
  fills?: number
  bouncebacks?: number
  cancels?: number
  startAmount?: number
  endAmount?: number
  starting?: string
  ending?: string
  net?: string
  cashierName?: string
  receiptName?: string
  fromDateFormatted?: string
  toDateFormatted?: string
  fromTimeFormatted?: string
  toTimeFormatted?: string
}

export const ReceiptContent = forwardRef<HTMLDivElement, ReceiptProps>((props, ref) => {
  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
  const headerTitle = authShift?.response?.platformVendor?.header1
  // const headerMsg = authShift?.response?.platformVendor?.header2
  const siteURL = authShift?.response?.platformVendor?.siteUrl
    ?.replace(/^(https?:\/\/)?(www\.)?/, "")
    .replace(/\/+$/, "")

  const capitalizedSiteURL = siteURL ? siteURL.charAt(0).toUpperCase() + siteURL.slice(1) : null

  const currentCashierName = authShift?.response?.accountName
  const storeNum = authShift?.response?.customerNumber
  const storeName = authShift?.response?.storeLabel
  const { formattedDate, formattedTime } = formatDateAndTime(new Date().toISOString(), true)
  const formattedStarting = props.starting ? formatDateAndTime(props.starting, true) : null
  const formattedEnding = props.ending ? formatDateAndTime(props.ending, true) : null
  const disclaimerRows = authShift?.response?.parameters?.disclaimerRows

  const fieldsToShow = [
    { label: "From", value: props.from },
    { label: "To", value: props.to },
    { label: "Start Balance", value: props.startAmount },
    { label: "End Balance", value: props.endAmount },
    { label: "Add", value: props.add },
    { label: "Added", value: props.fills },
    { label: "Remove", value: props.remove },
    { label: "Removed", value: props.bleeds },
    { label: "Purchase", value: props.purchase },
    { label: "Purchased", value: props.purchases },
    { label: "Redeem", value: props.redeem },
    { label: "Redeemed", value: props.redeems },
    { label: "Canceled Purchase", value: props.cancelPurchase },
    { label: "Canceled Redeem", value: props.cancelRedeem },
    { label: "Canceled Promo", value: props.cencelPromo },
    { label: "Adjustment", value: props.adjustment },
    { label: "Adjustments", value: props.cancels },
    { label: "Promo", value: props.promo },
    { label: "Promos", value: props.bouncebacks },
    // { label: "Comp", value: props.comp },
    // { label: "Comps", value: props.promos },
    // { label: "Promos", value: props.promos },
    { label: "Free Play", value: props.freePlay },
    { label: "Free Play", value: props.freePlays },
    // { label: "Adjustments", value: props.adjustments },
    { label: "Winnings", value: props.winPoints },
    { label: "Free Entries", value: props.freeEntries },
    { label: "Start Balance", value: props.startBalance },
    { label: "End Balance", value: props.endBalance },
    { label: "Balance", value: props.balance },
    { label: "Net", value: props.net },
  ].filter((field) => field.value !== undefined)
  const transactionReceipts = ["Purchase", "Redeem", "Free Play", "Cancel", "Comp"]
  const customerReceipts = ["Purchase", "Redeem", "Free Play", "Cancel", "Comp", "Account Details"]
  const summaryTypes = ["Shift", "End of Shift", "Start of Shift"]
  const noSuffixTypes = ["Current Shift", "Transactions Report", "Account Details"]
  const drawer = ["Drawer Add", "Drawer Remove"]
  const theme = useTheme()
  const isHandHeld = useMediaQuery(theme.breakpoints.between(359, 361))
  const renderTitle = (receiptName: string | null | undefined) => {
    if (
      (receiptName && transactionReceipts.includes(receiptName)) ||
      (receiptName && drawer.includes(receiptName))
    ) {
      return (
        <Typography style={{ textAlign: "center" }} variant="overviewTitle">
          {receiptName + " Receipt"}
        </Typography>
      )
    } else if (receiptName && summaryTypes.includes(receiptName)) {
      return (
        <Typography style={{ textAlign: "center" }} variant="overviewTitle">
          {receiptName + " Summary"}
        </Typography>
      )
    } else if (receiptName && (noSuffixTypes.includes(receiptName) || receiptName)) {
      return (
        <Typography style={{ textAlign: "center" }} variant="overviewTitle">
          {receiptName}
        </Typography>
      )
    }
    return null
  }
  return (
    <Box
      ref={ref}
      className={`${isHandHeld ? "mr-auto  ml-auto" : "mr-3  ml-2"} mt-2`}
      style={{
        maxWidth: isHandHeld ? "48mm" : "275px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography style={{ textAlign: "center" }} variant="overviewTitle">
        {headerTitle}
      </Typography>
      {customerReceipts.includes(props.receiptName || "") && (
        <>
          <Typography style={{ textAlign: "center", fontSize: "0.75rem" }}>
            {authShift?.response?.platformVendor?.header2}
          </Typography>
          <Typography style={{ textAlign: "center", fontSize: "0.75rem", paddingBottom: "2px" }}>
            {capitalizedSiteURL}
          </Typography>
        </>
      )}
      {!customerReceipts.includes(props.receiptName || "") && (
        <Typography style={{ textAlign: "center", fontSize: "0.75rem", paddingBottom: "2px" }}>
          {capitalizedSiteURL}
        </Typography>
      )}
      <div
        style={{ width: "100%", height: "2px", backgroundColor: "#000", margin: "2px 0 2px 0" }}
      ></div>
      {renderTitle(props.receiptName)}

      <ReceiptInfoSections
        accountId={props.accountId}
        starting={props.starting}
        ending={props.ending}
        receiptName={props.receiptName}
        cashierName={props.cashierName}
        fromDateFormatted={props.fromDateFormatted}
        fromTimeFormatted={props.fromTimeFormatted}
        toDateFormatted={props.toDateFormatted}
        toTimeFormatted={props.toTimeFormatted}
        formattedDate={formattedDate}
        formattedTime={formattedTime}
        formattedStarting={formattedStarting}
        formattedEnding={formattedEnding}
      />
      <div
        style={{ width: "100%", height: "1px", backgroundColor: "#000", margin: "4px 0 4px 0" }}
      ></div>
      <Table
        style={{
          width: "100%",
        }}
      >
        <TableBody>
          {fieldsToShow.map((field, index) => (
            <TableRow
              key={index}
              style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}
            >
              <TableCell
                style={{
                  textAlign: "left",
                  border: "none",
                  padding: "0",
                  margin: "0",
                  fontSize: "0.75rem",
                }}
              >
                {field.label}:
              </TableCell>
              <TableCell
                style={{
                  textAlign: "right",
                  padding: "1px 0 0 0",
                  margin: "0",
                  border: "none",
                  fontSize: "0.75rem",
                }}
              >
                {field.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div
        style={{ width: "100%", height: "1px", backgroundColor: "#000", margin: "4px 0 2px 0" }}
      ></div>
      <ReceiptDetails
        receiptName={props.receiptName}
        currentCashierName={currentCashierName}
        storeNum={storeNum}
        storeName={storeName}
        customerReceipts={customerReceipts}
        formattedDate={formattedDate}
        formattedTime={formattedTime}
        authShift={authShift}
        formatPhoneNumber={formatPhoneNumber}
      />
      <ReceiptFooter
        authShift={authShift}
        disclaimerRows={disclaimerRows}
        customerReceipts={customerReceipts}
        receiptName={props.receiptName}
      />
    </Box>
  )
})

ReceiptContent.displayName = "ReceiptContent"
