import React, { useState, useEffect, useRef } from "react"
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material"
import { NumericFormat } from "react-number-format"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import { formatAmount } from "../../utils/formattingFunctions/formatAmount"
import { ReceiptContent } from "../Print"
import { useReactToPrint } from "react-to-print"
import { entriesToMoney, moneyToEntries } from "../../utils/entriesToMoney"

interface RemoveDialogProps {
  bleed: any
  open: boolean
  handleClose: () => void
  amount: string
  handleChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleConfirm: () => Promise<void>
  confirmPrintRemoveModal: boolean
  setConfirmPrintRemoveModal: React.Dispatch<React.SetStateAction<boolean>>
  balance: string
  isLoading: boolean
}

const RemoveDialog: React.FC<RemoveDialogProps> = ({
  bleed,
  open,
  handleClose,
  amount,
  handleChangeAmount,
  handleConfirm,
  confirmPrintRemoveModal,
  setConfirmPrintRemoveModal,
  balance,
  isLoading,
}) => {
  const MAX_REMOVE_CASH_LIMIT = 99999
  const [mainDialogOpen, setMainDialogOpen] = useState(open)
  const [firstConfirmOpen, setFirstConfirmOpen] = useState(false)
  const [secondConfirmOpen, setSecondConfirmOpen] = useState(false)

  const availableAmountEntry = entriesToMoney(bleed?.bleedResponse?.availableAmount)
  const availableAmountFormatted = formatAmount(availableAmountEntry)

  useEffect(() => {
    setMainDialogOpen(open)
  }, [open])

  const handleOpenFirstConfirm = () => {
    setMainDialogOpen(false)
    setFirstConfirmOpen(true)
  }

  const handleCloseFirstConfirm = () => {
    setFirstConfirmOpen(false)
    setMainDialogOpen(true)
  }

  const handleFinalConfirm = async () => {
    await handleConfirm()
    handleCloseFirstConfirm()
    handleCloseSecondConfirm()
    setMainDialogOpen(false)
  }

  const handleOpenSecondConfirm = () => {
    setFirstConfirmOpen(false)
    setSecondConfirmOpen(true)
  }

  const handleCloseSecondConfirm = () => {
    setSecondConfirmOpen(false)
    setMainDialogOpen(true)
  }
  const handleCloseCross = () => {
    setMainDialogOpen(false)
    setFirstConfirmOpen(false)
    setSecondConfirmOpen(false)
    handleClose()
  }
  const amountAsNumber = parseFloat(amount)
  const formattedAmount = formatAmount(amountAsNumber)

  const removeReceiptRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => removeReceiptRef.current,
    removeAfterPrint: false,
  })

  useEffect(() => {
    if (bleed?.bleedResponse?.redeemed === false) {
      setMainDialogOpen(true)

      const availableAmountEntry = entriesToMoney(bleed?.bleedResponse?.availableAmount)
      handleChangeAmount({
        target: { value: availableAmountEntry.toString() },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }, [bleed])

  return (
    <>
      <CustomDialog
        open={mainDialogOpen}
        disabledPrimaryAction={!amount}
        title="Remove Cash"
        content={
          <>
            {bleed?.bleedResponse?.redeemed === false && (
              <Typography color="error" sx={{ paddingBottom: 1 }}>
                The amount you specified exceeds the drawer balance. Maximum removable amount is{" "}
                {availableAmountFormatted}.
              </Typography>
            )}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
              <NumericFormat
                id="outlined-adornment-amount"
                label="Amount"
                autoFocus
                value={amount}
                thousandSeparator=","
                type="tel"
                allowLeadingZeros={false}
                allowNegative={false}
                fixedDecimalScale={true}
                autoComplete="off"
                decimalScale={2}
                customInput={OutlinedInput}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                isAllowed={(values) => {
                  const { floatValue } = values
                  const maxLimit =
                    bleed?.bleedResponse?.redeemed === false
                      ? availableAmountEntry
                      : MAX_REMOVE_CASH_LIMIT
                  return floatValue === undefined || (floatValue > 0 && floatValue <= maxLimit)
                }}
                onValueChange={(values) => {
                  const { value } = values
                  handleChangeAmount({
                    target: { value },
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
              />
            </FormControl>
          </>
        }
        primaryActionText="Confirm"
        secondaryActionText="Cancel"
        onPrimaryAction={handleOpenFirstConfirm}
        onSecondaryAction={handleClose}
        handleClose={handleClose}
      />

      {amount ? (
        <>
          <CustomDialog
            open={firstConfirmOpen}
            title="Confirm Remove"
            isLoading={isLoading}
            disabledPrimaryAction={isLoading}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                    Amount:
                  </Typography>
                  <Typography variant="h6">{formattedAmount}</Typography>
                </Stack>
                <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                  Do you want to proceed?
                </Typography>
              </>
            }
            primaryActionText="Yes"
            secondaryActionText="No"
            onPrimaryAction={
              parseFloat(amount) > 10000 ? handleOpenSecondConfirm : handleFinalConfirm
            }
            onSecondaryAction={handleCloseFirstConfirm}
            handleClose={handleCloseCross}
          />

          <CustomDialog
            open={secondConfirmOpen}
            title="Double Check"
            dialogContentText="The amount is quite large. Are you absolutely sure?"
            primaryActionText="Yes, I'm Sure"
            secondaryActionText="No, Go Back"
            onPrimaryAction={handleFinalConfirm}
            onSecondaryAction={handleCloseSecondConfirm}
            handleClose={handleCloseCross}
            isLoading={isLoading}
            disabledPrimaryAction={isLoading}
          />

          <CustomDialog
            open={confirmPrintRemoveModal}
            title={`Remove Confirmed`}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                    Amount:
                  </Typography>
                  <Typography variant="h6">{formattedAmount}</Typography>
                </Stack>
              </>
            }
            dialogContentText={""}
            primaryActionText="Print Receipt"
            secondaryActionText="Close"
            onPrimaryAction={() => {
              {
                setConfirmPrintRemoveModal(false)
                handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
              }
              handlePrint()
            }}
            onSecondaryAction={() => {
              setConfirmPrintRemoveModal(false)
              handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
            }}
            handleClose={() => {
              setConfirmPrintRemoveModal(false)
              handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
              handleClose()
            }}
          />
          <div style={{ display: "none" }}>
            <ReceiptContent
              ref={removeReceiptRef}
              remove={formattedAmount}
              balance={balance}
              receiptName="Drawer Remove"
            />
          </div>
        </>
      ) : null}
    </>
  )
}

export default RemoveDialog
