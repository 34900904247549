import React, { useState, useEffect, useRef } from "react"
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Typography,
  Stack,
} from "@mui/material"
import { NumericFormat } from "react-number-format"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import { formatAmount } from "../../utils/formattingFunctions/formatAmount"
import { useReactToPrint } from "react-to-print"
import { ReceiptContent } from "../Print"

interface AddDialogProps {
  open: boolean
  handleClose: () => void
  amount: string
  handleChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleConfirm: () => Promise<void>
  confirmPrintAddModal: boolean
  setConfirmPrintAddModal: React.Dispatch<React.SetStateAction<boolean>>
  balance: string
  isLoading: boolean
}

const AddDialog: React.FC<AddDialogProps> = ({
  open,
  handleClose,
  amount,
  handleChangeAmount,
  handleConfirm,
  confirmPrintAddModal,
  setConfirmPrintAddModal,
  balance,
  isLoading,
}) => {
  const MAX_ADD_CASH_LIMIT = 99999
  const [mainDialogOpen, setMainDialogOpen] = useState(open)
  const [firstConfirmOpen, setFirstConfirmOpen] = useState(false)
  const [secondConfirmOpen, setSecondConfirmOpen] = useState(false)

  useEffect(() => {
    setMainDialogOpen(open)
  }, [open])

  const handleOpenFirstConfirm = () => {
    if (!amount) {
      return
    }
    setMainDialogOpen(false)
    setFirstConfirmOpen(true)
  }

  const handleCloseFirstConfirm = () => {
    setFirstConfirmOpen(false)
    setMainDialogOpen(true)
  }

  const handleFinalConfirm = async () => {
    await handleConfirm()
    handleCloseFirstConfirm()
    handleCloseSecondConfirm()
    setMainDialogOpen(false)
  }

  const handleOpenSecondConfirm = () => {
    setFirstConfirmOpen(false)
    setSecondConfirmOpen(true)
  }

  const handleCloseSecondConfirm = () => {
    setSecondConfirmOpen(false)
    setMainDialogOpen(true)
  }
  const handleCloseCross = () => {
    setMainDialogOpen(false)
    setFirstConfirmOpen(false)
    setSecondConfirmOpen(false)
    handleClose()
  }
  const amountAsNumber = parseFloat(amount)
  const formattedAmount = formatAmount(amountAsNumber)

  const addReceiptRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => addReceiptRef.current,
    removeAfterPrint: false,
  })
  return (
    <>
      <CustomDialog
        open={mainDialogOpen}
        disabledPrimaryAction={!amount}
        title="Add Cash"
        content={
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <NumericFormat
              id="outlined-adornment-amount"
              label="Amount"
              type="tel"
              autoFocus
              value={amount}
              thousandSeparator=","
              allowLeadingZeros={false}
              allowNegative={false}
              fixedDecimalScale={true}
              autoComplete="off"
              decimalScale={2}
              customInput={OutlinedInput}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              isAllowed={(values) => {
                const { floatValue } = values
                return (
                  floatValue === undefined || (floatValue > 0 && floatValue <= MAX_ADD_CASH_LIMIT)
                )
              }}
              onValueChange={(values) => {
                const { value } = values
                handleChangeAmount({ target: { value } } as React.ChangeEvent<HTMLInputElement>)
              }}
            />
          </FormControl>
        }
        primaryActionText="Confirm"
        secondaryActionText="Cancel"
        onPrimaryAction={handleOpenFirstConfirm}
        onSecondaryAction={handleClose}
        handleClose={handleClose}
      />
      {amount ? (
        <>
          <CustomDialog
            open={firstConfirmOpen}
            title="Confirm Add"
            isLoading={isLoading}
            disabledPrimaryAction={isLoading}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                    Amount:
                  </Typography>
                  <Typography variant="h6">{formattedAmount}</Typography>
                </Stack>
                <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                  Do you want to proceed?
                </Typography>
              </>
            }
            primaryActionText="Yes"
            secondaryActionText="No"
            onPrimaryAction={
              parseFloat(amount) > 10000 ? handleOpenSecondConfirm : handleFinalConfirm
            }
            onSecondaryAction={handleCloseFirstConfirm}
            handleClose={handleCloseCross}
          />

          <CustomDialog
            open={secondConfirmOpen}
            title="Double Check"
            dialogContentText="The amount is quite large. Are you absolutely sure?"
            primaryActionText="Yes, I'm Sure"
            secondaryActionText="No, Go Back"
            onPrimaryAction={handleFinalConfirm}
            onSecondaryAction={handleCloseSecondConfirm}
            handleClose={handleCloseCross}
            isLoading={isLoading}
            disabledPrimaryAction={isLoading}
          />
          <CustomDialog
            open={confirmPrintAddModal}
            title={`Add Confirmed`}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                    Amount:
                  </Typography>
                  <Typography variant="h6">{formattedAmount}</Typography>
                </Stack>
              </>
            }
            dialogContentText={""}
            primaryActionText="Print Receipt"
            secondaryActionText="Close"
            onPrimaryAction={() => {
              {
                setConfirmPrintAddModal(false)
                handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
              }
              handlePrint()
            }}
            onSecondaryAction={() => {
              setConfirmPrintAddModal(false)
              handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
            }}
            handleClose={() => {
              setConfirmPrintAddModal(false)
              handleChangeAmount({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)
              handleClose()
            }}
          />
          <div style={{ display: "none" }}>
            <ReceiptContent
              ref={addReceiptRef}
              add={formattedAmount}
              balance={balance}
              receiptName="Drawer Add"
            />
          </div>
        </>
      ) : null}
    </>
  )
}

export default AddDialog
