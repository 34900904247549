import { ButtonBase, Card, Typography, Box } from "@mui/material"
import React from "react"
import customStyles from "../../styles/tokens.json"

interface HomeButtonProps {
  title: string
  href?: string
  onClick?: () => void
  icon: React.ReactElement
  color?: ButtonColor
  sx?: object
}

type ButtonColor = "orange" | "success" | "warning" | "info" | "primary" | "secondary" | "error"

export const HomeButton: React.FC<HomeButtonProps> = ({
  title,
  href,
  onClick,
  icon,
  color = "primary",
  sx = {},
}) => {
  const theme = customStyles.themePalette[color as keyof typeof customStyles.themePalette]

  const handleClick = () => {
    if (href) {
      window.location.href = href
    } else if (onClick) {
      onClick()
    }
  }

  const iconWithColor = React.cloneElement(icon, {
    style: { color: theme.darker, fontSize: "calc(24px + 0.8vmin)" },
  })
  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: "calc(5px + 7vmin)",
          boxShadow: 0,
          textAlign: "center",
          color: theme.darker,
          bgcolor: theme.lighter,
          borderRadius: customStyles.borders.radius,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            boxShadow: 0,
            transform: "scale(1.02)",
          },
          height: "calc(60px + 20vmin)",
          width: "calc(60px + 20vmin)",
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            gap: "calc(8px + 2vmin)",
          }}
        >
          {iconWithColor}
          <Typography
            sx={{
              px: 1,
              fontSize: "calc(14px + 1vmin)",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Card>
    </ButtonBase>
  )
}
