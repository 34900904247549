/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom"
import { Typography, Grid, Button, Box } from "@mui/material"
import EastIcon from "@mui/icons-material/East"
import { UserResponse } from "../types/TSearchUsers"
import { formatAmount } from "../utils/formattingFunctions/formatAmount"
import { entriesToMoney } from "../utils/entriesToMoney"

type PartialUserResponse = Partial<UserResponse>

type UserCardProps = {
  user: PartialUserResponse
}

export const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const navigate = useNavigate()

  const handleDetailsClick = () => {
    navigate(`/account-details/${user.playerNumber}`)
  }

  return (
    <Box sx={{ mt: 2, mx: 0 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="boldSubtitle1"
          sx={{ mb: 1, overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {user?.playerLabel ? `${user?.playerLabel} - ` : ""}
          {user?.userName || user?.playerNumber
            ? `${user?.userName || user?.playerNumber}`
            : "Player Name"}
        </Typography>
      </Box>
      <Grid container columnSpacing={{ xs: 0, sm: 1 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="subtitle1">Free Entries</Typography>
              <Typography variant="boldSubtitle1" textAlign="right">
                {user?.freeEntries !== undefined ? user?.freeEntries?.toLocaleString() : "--"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1">Winnings</Typography>
              <Typography variant="boldSubtitle1" textAlign="right">
                {user?.winPoints !== undefined
                  ? formatAmount(entriesToMoney(user?.winPoints))
                  : "--"}
              </Typography>
            </Box>
            <Box>
              <Button
                size="small"
                endIcon={<EastIcon />}
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={handleDetailsClick}
                disabled={user?.freeEntries === undefined}
              >
                Details
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
