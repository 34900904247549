import { TransactionTypeAlt, UnknownTransactionType } from "../../constants/transactionTypes"
import type { PlayerDetailsInput, PlayerDetailsOutput } from "../../types/IUserTransactions"
import { moneyToEntries } from "../entriesToMoney"
import { formatAmount } from "../formattingFunctions/formatAmount"
import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
import { formatName } from "../formattingFunctions/formatName"

export function flattenPlayerTransactions(data: PlayerDetailsInput): PlayerDetailsOutput {
  const flattenedTransactions = data.response.map((transaction) => {
    const name = formatName(
      transaction.firstName,
      transaction.lastName,
      transaction.userName,
      transaction.playerNumber
    )
    const transactionType =
      TransactionTypeAlt(transaction.transactionType) || UnknownTransactionType
    const transactionValue =
      transaction.transactionType === "Bounceback" ||
      transaction.transactionType === "Free Play" ||
      transaction.transactionType === "Cancel Bounceback"
        ? moneyToEntries(transaction.transactionValue).toLocaleString()
        : formatAmount(transaction.transactionValue)
    const { formattedDate, formattedTime } = formatDateAndTime(transaction.timestamp)

    return {
      ...transaction,
      name,
      date: formattedDate,
      time: formattedTime,
      transactionType: transactionType,
      transactionValue: transactionValue,
    }
  })

  return {
    response: flattenedTransactions,
    total: data.total,
  }
}
