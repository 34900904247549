import { snackbarMessages } from "../constants/snackbarMessages"
import { FieldConfig } from "../types/IAddAccount"

export const fieldsConfig: FieldConfig[] = [
  {
    name: "phone",
    label: "Phone",
    errorMessage: snackbarMessages.PHONE_REQUIRED,
    required: true,
    type: "tel",
    maxLength: 14,
  },
  {
    name: "password",
    label: "PIN",
    errorMessage: snackbarMessages.PIN_REQUIRED,
    required: true,
    type: "tel",
    maxLength: 8,
  },

  {
    name: "firstName",
    label: "First Name",
    errorMessage: snackbarMessages.FIRST_NAME_REQUIRED,
    required: false,
    maxLength: 25,
  },
  {
    name: "lastName",
    label: "Last Name",
    errorMessage: snackbarMessages.LAST_NAME_REQUIRED,
    required: false,
    maxLength: 25,
  },

  {
    name: "email",
    label: "Email",
    errorMessage: snackbarMessages.EMAIL_REQUIRED,
    required: false,
    type: "email",
    maxLength: 320,
  },
  {
    name: "driversLicense",
    label: "Driver's License",
    errorMessage: snackbarMessages.DRIVERS_LICENSE_REQUIRED,
    required: false,
    maxLength: 30,
  },
]
