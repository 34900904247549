import React, { useState, useCallback } from "react"
import { TextField, Typography, Grid } from "@mui/material"
import { useCreateUserMutation, useUpdateUserMutation } from "../../redux/user/user.api"
import {
  validateName,
  validatePin,
  validatePhone,
  validateEmail,
  validateDriversLicense,
} from "../../constants/AddAccountValidations"
import { snackbarMessages } from "../../constants/snackbarMessages"
import { fieldsConfig } from "../../data/addAccountConfigData"
import { AddAccountDialogProps, AddAccountUser } from "../../types/IAddAccount"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import PhoneVerificationDialog from "../PhoneVerificationDialog"
import { useCustomSnackbar } from "../multiSnackbar"

export const AddAccountDialog: React.FC<AddAccountDialogProps> = ({
  open,
  onClose,
  onUserUpdatedOrCreated,
  isEdit,
  initialUser,
  fetchUser,
}) => {
  const [newUser, setNewUser] = useState<AddAccountUser>(
    isEdit
      ? initialUser!
      : {
          playerNum: 0,
          firstName: "",
          lastName: "",
          password: "",
          phone: "",
          email: "",
          driversLicense: "",
        }
  )

  const [fieldError, setFieldError] = useState<AddAccountUser>({
    playerNum: 0,
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    email: "",
    driversLicense: "",
  })

  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [playerIdForVerification, setPlayerIdForVerification] = useState("")
  const [isPhoneVerificationDialogOpen, setIsPhoneVerificationDialogOpen] = useState(false)
  const resetForm = useCallback(() => {
    setNewUser({
      playerNum: 0,
      firstName: "",
      lastName: "",
      password: "",
      phone: "",
      email: "",
      driversLicense: "",
    })
    setFieldError({
      playerNum: 0,
      firstName: "",
      lastName: "",
      password: "",
      phone: "",
      email: "",
      driversLicense: "",
    })
  }, [])
  const showSnackbar = useCustomSnackbar()
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target as { name: keyof AddAccountUser; value: string }
      let newValue = value.trimStart()
      let errorMessage = ""

      switch (name) {
        case "firstName":
        case "lastName":
          newValue = newValue.replace(/[^a-zA-Z-' ]/g, "").replace(/  +/g, " ")
          errorMessage = !newValue ? snackbarMessages.INVALID_NAME : ""
          break
        case "password":
          newValue = newValue.replace(/\D/g, "")
          errorMessage = !newValue ? snackbarMessages.INVALID_PIN : ""
          break
        case "phone":
          newValue = newValue.replace(/\D/g, "")
          errorMessage = !newValue ? snackbarMessages.INVALID_PHONE : ""
          if (newValue.length > 6) {
            newValue = `${newValue.substring(0, 3)}-${newValue.substring(
              3,
              6
            )}-${newValue.substring(6, 10)}`
          } else if (newValue.length > 3) {
            newValue = `${newValue.substring(0, 3)}-${newValue.substring(3)}`
          } else if (newValue.length > 0) {
            newValue = `${newValue}`
          }
          break
        case "email":
          const MAX_LOCAL_PART_LENGTH = 64
          const MAX_DOMAIN_PART_LENGTH = 255

          const atIndex = newValue.indexOf("@")

          if (atIndex !== -1) {
            let localPart = newValue.substring(0, atIndex)
            let domainPart = newValue.substring(atIndex + 1)

            if (localPart.length >= MAX_LOCAL_PART_LENGTH) {
              localPart = localPart.substring(0, MAX_LOCAL_PART_LENGTH)
              newValue = localPart + "@" + domainPart
            }
            if (domainPart.length >= MAX_DOMAIN_PART_LENGTH) {
              domainPart = domainPart.substring(0, MAX_DOMAIN_PART_LENGTH)
              newValue = localPart + "@" + domainPart
            }
          } else {
            if (newValue.length >= MAX_LOCAL_PART_LENGTH) {
              newValue = newValue.substring(0, MAX_LOCAL_PART_LENGTH)
            }
          }
          break
        case "driversLicense":
          newValue = newValue.replace(/[^a-zA-Z0-9]/g, "")
          errorMessage = !newValue ? snackbarMessages.INVALID_DRIVERS_LICENSE : ""
          break
      }

      if (newValue !== newUser[name]) {
        setNewUser((prevState) => ({
          ...prevState,
          [name]: newValue,
        }))
      }

      if (errorMessage) {
        setFieldError((prevErrors) => ({ ...prevErrors, [name]: errorMessage }))
      }
    },
    [newUser]
  )
  const isFirstNameNotRequired = fieldsConfig.find((f) => f.name === "firstName")?.required || true
  const isLastNameNotRequired = fieldsConfig.find((f) => f.name === "lastName")?.required || true
  const isEmailNotRequired = fieldsConfig.find((f) => f.name === "email")?.required || true

  const handleInputBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    let errorMessage = ""
    const fieldConfigItem = fieldsConfig.find((field) => field.name === name)
    if (fieldConfigItem?.required && !value.trim() && (isEdit ? name !== "password" : true)) {
      errorMessage = snackbarMessages.Field_REQUIRED
    } else {
      switch (name) {
        case "firstName":
          errorMessage = validateName(value, isFirstNameNotRequired)
          break
        case "lastName":
          errorMessage = validateName(value, isLastNameNotRequired)
          break
        case "password":
          errorMessage = validatePin(value)
          break
        case "phone":
          errorMessage = validatePhone(value)
          break
        case "email":
          errorMessage = validateEmail(value, isEmailNotRequired)
          break
        case "driversLicense":
          errorMessage = validateDriversLicense(value)
          break
      }
    }
    setFieldError((prevErrors) => ({ ...prevErrors, [name]: errorMessage }))
  }, [])

  const handleUserAction = useCallback(async () => {
    setFormSubmitted(true)
    setIsLoading(true)
    if (!isEdit && (!newUser.password || !newUser.password.trim())) {
      showSnackbar(snackbarMessages.PIN_REQUIRED, "error")
      setIsLoading(false)
      setFormSubmitted(false)
      return
    }
    if (Object.values(fieldError).some((err) => err)) {
      showSnackbar("Please correct the errors before proceeding.", "error")
      setIsLoading(false)
      setFormSubmitted(false)
      return
    }

    const errorMessageMappings: { [key: string]: string } = {
      "Phone number in use": "This phone number is already associated with an existing account.",
    }
    type ServerErrorType = string

    const mapErrorMessage = (serverError: ServerErrorType): string => {
      if (errorMessageMappings[serverError]) {
        return errorMessageMappings[serverError]
      } else if (/password/i.test(serverError)) {
        return serverError.replace(/password/gi, "PIN")
      }
      return serverError
    }

    const passwordPayload =
      isEdit && (!newUser.password || !newUser.password?.trim())
        ? {}
        : { password: newUser.password?.trim() || "" }

    const trimmedUser = {
      ...(!isFirstNameNotRequired || (newUser.firstName && newUser.firstName.trim())
        ? { firstName: newUser.firstName?.trim() }
        : {}),
      ...(!isLastNameNotRequired || (newUser.lastName && newUser.lastName.trim())
        ? { lastName: newUser.lastName?.trim() }
        : {}),
      ...(!isEmailNotRequired || (newUser.email && newUser.email.trim())
        ? { email: newUser.email?.trim() }
        : {}),
      phone: newUser.phone.trim(),
      driversLicense: newUser.driversLicense?.trim(),
      ...passwordPayload,
    }

    const userPayload = {
      ...trimmedUser,
      ...(isEdit ? { playerNum: newUser.playerNum } : {}),
      phone: trimmedUser.phone.replace(/\D/g, ""),
      playerNum: isEdit ? initialUser?.playerNum : undefined,
    }

    const userDataString = sessionStorage.getItem("authShift")
    const userData = userDataString ? JSON.parse(userDataString) : null
    const phoneValidationRequired = userData?.response?.parameters?.phoneValidationRequired
    let res
    try {
      if (isEdit) {
        await updateUser(userPayload).unwrap()
      } else {
        res = await createUser(userPayload).unwrap()
      }
      if (fetchUser) await fetchUser(res.playerNumber)
      localStorage.setItem("selectedUser", JSON.stringify(res))
      resetForm()
      onClose()
      if (phoneValidationRequired && res?.id) {
        setPlayerIdForVerification(res.id)
        setIsPhoneVerificationDialogOpen(true)
      }

      onUserUpdatedOrCreated()
      showSnackbar(isEdit ? "User updated successfully!" : "User created successfully!", "success")
    } catch (error: any) {
      const errorMessage =
        mapErrorMessage(error?.data) || (isEdit ? "User update failed." : "User creation failed.")
      showSnackbar(errorMessage, "error")
    } finally {
      setIsLoading(false)
      setFormSubmitted(false)
    }
  }, [
    createUser,
    fieldError,
    newUser,
    onClose,
    onUserUpdatedOrCreated,
    resetForm,
    updateUser,
    isEdit,
    initialUser,
  ])

  const isFormChanged = (
    newUser: AddAccountUser,
    initialUser: AddAccountUser | undefined
  ): boolean => {
    if (!initialUser) return false
    return !Object.keys(newUser).every((key) => {
      const k = key as keyof AddAccountUser
      return newUser[k] === initialUser[k]
    })
  }

  const isCreateOperationValid = () => {
    return fieldsConfig.every((fieldConfig) => {
      if (!fieldConfig.required) {
        return true
      }
      const fieldValue = newUser[fieldConfig.name]
      return fieldValue && fieldValue.toString().trim() !== ""
    })
  }

  const FormContent = (
    <form autoComplete="off">
      <Grid container spacing={2} marginTop={1}>
        {fieldsConfig.map((field) => (
          <Grid item xs={12} key={field.name}>
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type}
              value={newUser[field.name] || ""}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              InputLabelProps={{ shrink: true }}
              required={field.required}
              error={Boolean(fieldError[field.name])}
              autoComplete={field.name === "password" ? "new-password" : "off"}
              helperText={fieldError[field.name] || field.helperText}
              inputMode={field.name === "password" ? "numeric" : undefined}
              placeholder={isEdit && field.name === "password" ? "********" : ""}
              inputProps={{
                maxLength: field.maxLength,
                className: field.name === "password" ? "key" : "",
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            PIN Policy: Please use complex PINs. DO NOT use consecutive numbers: ex: 12345. DO NOT
            use identical numbers: ex: 111111 or 111222.
          </Typography>
        </Grid>
      </Grid>
    </form>
  )

  return (
    <>
      <CustomDialog
        open={open}
        title={isEdit ? "Edit account" : "Create new account"}
        content={FormContent}
        primaryActionText={isEdit ? "Update" : "Create"}
        secondaryActionText="Close"
        onPrimaryAction={handleUserAction}
        isLoading={isLoading}
        disabledPrimaryAction={
          formSubmitted ||
          (isEdit ? !isFormChanged(newUser, initialUser) : !isCreateOperationValid())
        }
        onSecondaryAction={() => {
          onClose()
          resetForm()
        }}
        handleClose={() => {
          onClose()
          resetForm()
        }}
      />
      <PhoneVerificationDialog
        open={isPhoneVerificationDialogOpen}
        playerId={playerIdForVerification}
        onClose={() => setIsPhoneVerificationDialogOpen(false)}
      />
    </>
  )
}
