import { useState } from "react"
import { styled, type Theme, type CSSObject } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { homeButtons } from "../data/homepage"
import { useLocation, useNavigate } from "react-router-dom"
import Tooltip from "@mui/material/Tooltip"
import { Box, ClickAwayListener, Typography } from "@mui/material"
import customStyle from "../styles/tokens.json"
import CircleIcon from "@mui/icons-material/Circle"
import { LogoutModal } from "./LogoutModal"
const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open === true && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(open === false && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

export function SideNav() {
  const [open, setOpen] = useState(false)
  const [openLogoutModal, setOpenLogoutModal] = useState(false)

  const location = useLocation()

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerToggle = () => {
    open ? handleDrawerClose() : handleDrawerOpen()
  }

  const navigate = useNavigate()
  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
  const cashierName = authShift?.response?.accountName

  const signOutHandler = async () => {
    setOpenLogoutModal(true)
  }

  return (
    <>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <Drawer variant="permanent" open={open}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor: "transaparent",
                    color: "#ffffff",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="relative"
                      width="100%"
                      height="100%"
                    >
                      <CircleIcon sx={{ color: customStyle.colors.primary }} fontSize={"large"} />
                      <Box position="absolute" color={"#fff"}>
                        <Typography>{cashierName?.[0]}</Typography>
                      </Box>
                    </Box>
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={cashierName}
                      sx={{
                        color: "black",
                        wordWrap: "break-word",
                      }}
                    />
                  )}
                  <IconButton sx={{ padding: 0 }} onClick={handleDrawerToggle}>
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </ListItemButton>
              </ListItem>
            </List>
          </div>

          <Divider
            sx={{
              marginTop: 1,
            }}
          />
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <List>
              {homeButtons(signOutHandler).map((value, index) => (
                <ListItem
                  onClick={() => {
                    value.onclick != null ? value.onclick() : navigate(value.href ?? "/")
                    handleDrawerClose()
                  }}
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  <Tooltip
                    title={value.title}
                    arrow
                    placement="right"
                    disableFocusListener={open}
                    disableHoverListener={open}
                    disableTouchListener={open}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor:
                          value.href !== "/" &&
                          value.href !== undefined &&
                          value.activePaths &&
                          value.activePaths.some((path) => location.pathname.startsWith(path))
                            ? "primary.main"
                            : "transparent",
                        color:
                          value.href !== "/" &&
                          value.href !== undefined &&
                          value.activePaths &&
                          value.activePaths.some((path) => location.pathname.startsWith(path))
                            ? "#ffffff"
                            : "#676767",
                        "&:hover": {
                          backgroundColor:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            value.activePaths &&
                            value.activePaths.some((path) => location.pathname.startsWith(path))
                              ? "primary.main"
                              : customStyle.components.list.itemButton.hoverBackground,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            value.activePaths &&
                            value.activePaths.some((path) => location.pathname.startsWith(path))
                              ? "#fff"
                              : "",
                        }}
                      >
                        <value.Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={value.title}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </ClickAwayListener>
      <LogoutModal open={openLogoutModal} onClose={() => setOpenLogoutModal(false)} />
    </>
  )
}
