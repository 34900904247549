import React from "react"
import { Table, TableBody, TableRow, TableCell, Typography, Stack } from "@mui/material"

interface ReceiptInfoSectionsProps {
  accountId?: string | number | null
  starting?: string
  ending?: string
  receiptName?: string
  cashierName?: string
  fromDateFormatted?: string
  fromTimeFormatted?: string
  toDateFormatted?: string
  toTimeFormatted?: string
  formattedDate?: string
  formattedTime?: string
  formattedStarting: { formattedDate: string; formattedTime: string } | null
  formattedEnding: { formattedDate: string; formattedTime: string } | null
}

const ReceiptInfoSections: React.FC<ReceiptInfoSectionsProps> = (props) => {
  // Extract the props for easier usage
  const {
    accountId,
    starting,
    ending,
    receiptName,
    cashierName,
    fromDateFormatted,
    fromTimeFormatted,
    toDateFormatted,
    toTimeFormatted,
    formattedDate,
    formattedTime,
    formattedStarting,
    formattedEnding,
  } = props

  return (
    <>
      {accountId && (
        <Table
          style={{
            width: "100%",
          }}
        >
          <TableBody>
            <TableRow style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}>
              <Stack direction="column" spacing={0}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "0.75rem",
                    padding: "0",
                    margin: "0",
                    whiteSpace: "nowrap",
                  }}
                >
                  Account ID: {accountId}
                </Typography>
              </Stack>
            </TableRow>

            <TableRow style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}>
              <TableCell
                style={{
                  fontSize: "0.75rem",
                  textAlign: "center",
                  padding: "1px 0 0 0",
                  margin: "0",

                  border: "none",
                }}
                colSpan={10}
              >
                {formattedDate} {formattedTime}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {starting && (
        <>
          <Table
            style={{
              width: "100%",
            }}
          >
            <TableBody>
              <TableRow style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}>
                <TableCell
                  style={{
                    textAlign: "left",
                    border: "none",
                    padding: "0",
                    margin: "0",
                    fontSize: "0.65rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {receiptName === "Current Shift" ||
                  receiptName === "End of Shift" ||
                  receiptName === "Start of Shift"
                    ? `Start: `
                    : ` From: `}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    border: "none",
                    padding: "1px 0 0 0",
                    margin: "0",
                    fontSize: "0.65rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {receiptName === "Current Shift" ||
                  receiptName === "End of Shift" ||
                  receiptName === "Start of Shift"
                    ? `${formattedStarting?.formattedDate} ${formattedStarting?.formattedTime}`
                    : `${formattedStarting?.formattedDate}*`}
                </TableCell>
              </TableRow>
              {ending && (
                <TableRow
                  style={{ padding: "0", margin: "0", fontSize: "0.65rem", height: "10px" }}
                >
                  <TableCell
                    style={{
                      textAlign: "left",
                      border: "none",
                      padding: "0",
                      margin: "0",
                      fontSize: "0.65rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {receiptName === "Current Shift" || receiptName === "End of Shift"
                      ? `End: `
                      : ` To: `}{" "}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "right",
                      border: "none",
                      padding: "1px 0 0 0",
                      margin: "0",
                      fontSize: "0.65rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {receiptName === "Current Shift" || receiptName === "End of Shift"
                      ? `${formattedEnding?.formattedDate} ${formattedEnding?.formattedTime}`
                      : `${formattedEnding?.formattedDate}*`}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
      {receiptName === "Shift" && (
        <>
          <Table
            style={{
              width: "100%",
            }}
          >
            <TableBody>
              <TableRow style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}>
                <TableCell
                  style={{
                    textAlign: "left",
                    border: "none",
                    padding: "0",
                    margin: "0",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Cashier:
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    border: "none",
                    padding: "1px 0 0 0",
                    margin: "0",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {cashierName}
                </TableCell>
              </TableRow>
              <TableRow style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}>
                <TableCell
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontSize: "0.75rem",
                    padding: "0",
                    margin: "0",
                    whiteSpace: "nowrap",
                  }}
                >
                  Start:
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    border: "none",
                    padding: "1px 0 0 0",
                    margin: "0",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {fromDateFormatted} {fromTimeFormatted}
                </TableCell>
              </TableRow>
              <TableRow style={{ padding: "0", margin: "0", fontSize: "0.75rem", height: "10px" }}>
                <TableCell
                  style={{
                    textAlign: "left",
                    border: "none",
                    padding: "0",
                    margin: "0",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  End:
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    border: "none",
                    padding: "1px 0 0 0",
                    margin: "0",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {toDateFormatted} {toTimeFormatted}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </>
  )
}

export default ReceiptInfoSections
