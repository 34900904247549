import { snackbarMessages } from "./snackbarMessages"

export const validateName = (name: string, isNotRequired: boolean): string => {
  const trimmedName = name.trim()
  if (isNotRequired && trimmedName === "") return ""
  if (trimmedName.length < 2 || trimmedName.length > 25) return snackbarMessages.INVALID_NAME_LENGTH
  if (!/^[a-zA-Z-' ]+$/.test(trimmedName)) return snackbarMessages.INVALID_NAME
  return ""
}

export const validatePin = (pin: string): string => {
  const sessionData = sessionStorage.getItem("authShift")
  const minPassLength = sessionData
    ? JSON.parse(sessionData).response.parameters.minPassLength
    : null

  if (pin === "") return ""
  if (!/^\d+$/.test(pin)) return snackbarMessages.INVALID_PIN
  if (pin.length < minPassLength || pin.length > 8)
    return `PIN should be between ${minPassLength} and 8 digits long.`
  if (/^(\d)\1+$/.test(pin) || /^1234|4321/.test(pin)) return snackbarMessages.SIMPLE_PIN
  return ""
}

export const validatePhone = (phone: string): string => {
  if (!/^\d{3}-\d{3}-\d{4}$/.test(phone)) return snackbarMessages.INVALID_PHONE
  return ""
}

export const validateEmail = (email: string, isNotRequired: boolean): string => {
  const trimmedEmail = email.trim()
  if (isNotRequired && trimmedEmail === "") return ""
  const emailPattern = /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/
  if (!emailPattern.test(email)) return snackbarMessages.INVALID_EMAIL_FORMAT
  return ""
}

export const validateDriversLicense = (license: string): string => {
  if (!license) return ""
  if (license.length < 5 || license.length > 30)
    return snackbarMessages.INVALID_DRIVERS_LICENSE_LENGTH
  if (!/^[a-zA-Z0-9]+$/.test(license)) return snackbarMessages.INVALID_DRIVERS_LICENSE
  return ""
}
