// CancelDialogContent.tsx
import React from "react"
import { Typography, Table, TableBody, TableRow, TableCell } from "@mui/material"

interface CancelDialogContentProps {
  tableData: Array<{ label: string; value: string | number }>
}

const CancelDialogContent: React.FC<CancelDialogContentProps> = ({ tableData }) => (
  <>
    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))", paddingBottom: 2 }}>
      Upon confirmation, transaction below will be cancelled. This action cannot be undone.
    </Typography>
    <Table size="small">
      <TableBody>
        {tableData.map((data, index) => (
          <TableRow key={index}>
            <TableCell
              sx={{ borderBottom: "none", width: "min(25ch, calc(16ch + 5vmin))", padding: 0 }}
            >
              <Typography sx={{ fontSize: "min(20px, calc(10px + 0.8vmin))" }}>
                {data.label}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", padding: 0, paddingBottom: "1px" }}>
              <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }} fontWeight={"bold"}>
                {data.value}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))", paddingTop: 2 }}>
      Do you want to proceed?
    </Typography>
  </>
)

export default CancelDialogContent
