export function formatName(
  firstName: string | null,
  lastName: string | null,
  userName: string | null,
  playerNumber: string | null
): string {
  const capitalize = (str: string | null): string => {
    if (!str) return ""
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  if (!userName && !playerNumber) {
    const authShift = JSON.parse(sessionStorage.getItem("authShift") || "{}")
    const accountName = authShift.response?.accountName
    if (accountName) {
      const [first, last] = accountName.split(" ")
      return `${capitalize(first)} ${capitalize(last || "")}`.trim()
    }
  }
  // uncertain, no definition for guest, condition may change
  if (firstName === null && lastName === null) {
    return "Guest"
  }

  return `${capitalize(firstName)} ${capitalize(lastName)}`.trim()
}
