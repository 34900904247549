import { useEffect, useState } from "react"
import { RoutesContainer } from "./routes/RoutesContainer"
import { useGetEmployeeQuery } from "./redux/user/user.api"
import CurrentShiftPopup from "./components/CurrentShitPopup"
// import GitBranchWatermark from "./components/GitBranchWatermark"
import { useDispatch } from "react-redux"
import { AnyAction, Dispatch } from "@reduxjs/toolkit"
import { ErrorLayout } from "./layouts/ErrorLayout"
import { SnackbarProvider } from "notistack"
import { MaterialDesignContent } from "notistack"
import styled from "@emotion/styled"
import ReactGA from "react-ga"
import OfflineBackdrop from "./components/OfflineBackdrop"

// to access hooks withing functions  we need global dispatch, for our error handling
export let globalDispatch: Dispatch<AnyAction> | null = null

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GTAG_ID ?? "")

function App() {
  const { data, isLoading, isSuccess, error } = useGetEmployeeQuery({})
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setIsOffline(false)
    const handleOffline = () => setIsOffline(true)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOffline])

  const userClassification = data?.response?.classification
  const username = data?.response?.accountName
  const dispatch = useDispatch()

  globalDispatch = dispatch

  // Track pageviews
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
    ReactGA.event({
      category: "User Authentication",
      action: "login",
      label: username,
    })
  }, [location])

  //TODO error handling pending error handling comp, Loading pending Skeletons
  if (isSuccess) {
    sessionStorage.setItem("authShift", JSON.stringify(data))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent": {
      fontSize: "min(20px, calc(10px + 0.6vmin))",
    },
  }))

  return (
    <>
      <OfflineBackdrop isOffline={isOffline} />
      <ErrorLayout error={error}>
        <>
          <SnackbarProvider
            maxSnack={3}
            Components={{
              success: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
              info: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
            }}
          >
            <RoutesContainer classification={userClassification} />
            {/* <GitBranchWatermark /> */}
            {userClassification === 1 && (
              <CurrentShiftPopup employee={data} isLoading={isLoading} />
            )}
          </SnackbarProvider>
        </>
      </ErrorLayout>
    </>
  )
}

export default App
