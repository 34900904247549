// ReceiptFooter.tsx
import React from "react"
import { Table, TableBody, TableRow, TableCell, Typography } from "@mui/material"
import { TransactionsReport } from "../../pages/TransactionsReport"

interface ReceiptFooterProps {
  authShift?: any // Consider replacing 'any' with the actual expected type
  disclaimerRows?: string[]
  customerReceipts?: string[]
  receiptName?: string | undefined
}

const ReceiptFooter: React.FC<ReceiptFooterProps> = ({
  authShift,
  disclaimerRows,
  customerReceipts,
  receiptName,
}) => {
  return (
    <Table style={{ width: "100%" }}>
      <TableBody>
        {receiptName === "Transactions Report" && (
          <TableRow style={{ margin: 0, padding: 0, marginTop: "6px" }}>
            <TableCell
              style={{
                margin: 0,
                padding: 0,
                fontSize: "0.55rem",
                textAlign: "left",
                border: "none",
              }}
              colSpan={10}
            >
              <Typography
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "0.55rem",
                  whiteSpace: "pre-line",
                  paddingBottom: 1,
                }}
              >
                *This report reflects transactions beginning at 4:00 AM and concluding at 3:59 AM
                the following day.
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {disclaimerRows &&
          disclaimerRows.length > 0 &&
          customerReceipts?.includes(receiptName || "") && (
            <>
              <TableRow style={{ margin: 0, padding: 0, marginTop: "4px" }}>
                <TableCell
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: "4px",
                    fontSize: "0.55rem",
                    textAlign: "center",
                    border: "none",
                  }}
                  colSpan={10}
                >
                  <Typography variant="boldSubtitle2">
                    {authShift?.response?.platformVendor?.productName}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow style={{ margin: 0, padding: 0 }}>
                <TableCell
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "0.55rem",
                    textAlign: "left",
                    border: "none",
                  }}
                  colSpan={10}
                >
                  {disclaimerRows.map((disclaimer, index) => (
                    <Typography
                      key={index}
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "0.60rem",
                        whiteSpace: "pre-line",
                        paddingBottom: 1,
                      }}
                    >
                      {disclaimer}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
            </>
          )}
      </TableBody>
    </Table>
  )
}

export default ReceiptFooter
