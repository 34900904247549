export function formatPlayerNumber(userName: string | null, playerNumber: string | null): string {
  const authShift = JSON.parse(sessionStorage.getItem("authShift") || "{}")
  const accountType = authShift.response?.accountType

  let displayName = userName || playerNumber

  if (!displayName) {
    if (accountType === 1) {
      displayName = "Cashier"
    } else if (accountType === 2) {
      displayName = "Admin"
    } else {
      displayName = "-"
    }
  }

  return displayName // Return the displayName as a string
}
