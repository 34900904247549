import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Tab,
  Tabs,
  Stack,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { DrawerBox } from "../../styles/styledComponents/DrawerBox"
import { AddAccountDialog } from "../../components/Accounts/AddAccountDialog"
import { SearchDialog } from "../../components/SearchDialog"
import { UserCard } from "../../components/UserCard"
import {
  FormattedUserResponse,
  flattenSearchUser,
} from "../../utils/flatteningFunctions/flattenSearchUser"
import {
  useSetUserMutation,
  usePurchaseMutation,
  useRedeemMutation,
  useSearchUsersQuery,
} from "../../redux/user/user.api"
import { useReactToPrint } from "react-to-print"
import customStyles from "../../styles/tokens.json"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import { asyncErrorHandler } from "../../utils/errorHandler"
import { entriesToMoney, moneyToEntries } from "../../utils/entriesToMoney"
import { useError } from "../../hooks/useError"
import { formatAmount } from "../../utils/formattingFunctions/formatAmount"
import { NumericFormat } from "react-number-format"
import { Header } from "../../components/Header"
import { ReceiptContent } from "../../components/Print"
import { PromoMessages } from "../../components/Accounts/PromoMessages"
import { useCustomSnackbar } from "../../components/multiSnackbar"

type TabColors = { bgColor: string; textColor: string }
interface PurchasePayload {
  Amount: number
  PlayerId: string | undefined
  ShiftId: any
  IsEntitlement?: boolean
  IsPromo?: boolean
}

interface PromoItem {
  amount: number
  to: number
  from: number
}

const getTabStyle = (currentTabValue: number, tabIndex: number, colors: TabColors) => ({
  backgroundColor: currentTabValue === tabIndex ? colors.bgColor : colors.bgColor,
  color: currentTabValue === tabIndex ? colors.textColor : "text.primary",
  fontWeight: "bold",
  fontSize: "calc(12px + 0.6vmin)",
  letterSpacing: "1px",
  height: "56px",
  "&:first-of-type": {
    borderRadius: "4px 0 0 4px",
    borderRight: "2px solid white",
  },
  "&:last-child": {
    borderRadius: "0 4px 4px 0",
    borderLeft: "2px solid white",
  },
})

export const Accounts: React.FC = () => {
  const MIN_SEARCH_THRESHOLD = 2
  const authShift = JSON.parse(sessionStorage.getItem("authShift") || "")
  const promoItems = authShift?.response?.parameters?.items
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))
  const [userList, setUserList] = useState<FormattedUserResponse[]>([])
  const [searchInput, setSearchInput] = useState("")
  const [searchDialogOpen, setSearchDialogOpen] = useState(false)
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [reason, setReason] = useState<string | null>(null)
  const [reasonsModal, setReasonsModal] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [amount, setAmount] = useState("")
  const [purchase] = usePurchaseMutation()
  const [redeem] = useRedeemMutation()
  const [isEntitlement, setIsEntitlement] = useState(false)
  const [confirmPurchaseModal, setConfirmPurchaseModal] = useState(false)
  const [confirmPrintModal, setConfirmPrintModal] = useState(false)
  const [confirmStatus, setConfirmStatus] = useState(0)
  const amountAsEntry = formattedAmountToNumber(amount)
  const formattedAmount = formatAmount(amountAsEntry)
  const MAX_PURCHASE_LIMIT = 99999
  const purchaseReceiptRef = useRef<HTMLDivElement>(null)
  const [fetchUser] = useSetUserMutation()
  const [apiLoading, setApiLoading] = useState(false)
  const [refetchKey, setRefetchKey] = useState(0)
  const [data, setData] = useState<any>(null)
  const [redeemData, setRedeemData] = useState<any>(null)
  const [bouncebackAvailable, setBouncebackAvailable] = useState(false)
  const [drawerBalanceWarning, setDrawerBalanceWarning] = useState(false)
  const [queryParameters, setQueryParameters] = useState<any>({
    term: searchInput,
    page: 1,
    perPage: rowsPerPage,
  })
  const [selectedUser, setSelectedUser] = useState<any>({
    firstName: undefined,
    lastName: undefined,
    playerLabel: undefined,
    userName: undefined,
    playerNumber: undefined,
    freeEntries: undefined,
    winPoints: undefined,
  })
  const showSnackbar = useCustomSnackbar()

  // set the selected user in the search dialog and local storage
  const handleUserSelected = async (user: any) => {
    const playerNum = user.playerNumber
    const res: any = await fetchUser({
      PlayerNum: playerNum,
    })
    setSelectedUser(res.data)
    setSearchDialogOpen(false)
    localStorage.setItem("selectedUser", JSON.stringify(res.data))
    setAmount("")
    if (res?.data?.response?.winPoints === 0 || res?.data?.response?.winPoints === undefined) {
      setTabValue(0)
    }
  }
  const userDataUpdated = useRef(false)

  const fetchUserHandler = asyncErrorHandler(async (playerNumber?: number) => {
    try {
      if (playerNumber !== undefined || selectedUser?.response?.playerNumber !== undefined) {
        const res: any = await fetchUser({
          PlayerNum: playerNumber || selectedUser?.response?.playerNumber,
        })
        setData(res.data)
        setSelectedUser(res.data)
        localStorage.setItem("selectedUser", JSON.stringify(res.data))
        userDataUpdated.current = true
      }
    } catch (error) {
      console.error("Error in fetchUserHandler:", error)
      userDataUpdated.current = false
    }
  }, showSnackbar)

  useEffect(() => {
    fetchUserHandler()
  }, [selectedUser?.response?.playerNumber])

  useEffect(() => {
    setQueryParameters((prevState: any) => ({
      ...prevState,
      term: searchInput,
    }))

    if (searchInput.length >= MIN_SEARCH_THRESHOLD) {
      // Or whatever condition you want to set
      setPage(1)
      setRowsPerPage(rowsPerPage)
      setQueryParameters((prev: any) => ({
        ...prev,
        page: 1,
      }))
    }
  }, [searchInput])

  const {
    data: searchResultsData,
    isLoading,
    isFetching,
  } = useError(
    useSearchUsersQuery(
      { ...queryParameters, refetchKey },
      {
        skip: isInitialLoad || searchInput.length < MIN_SEARCH_THRESHOLD,
      }
    )
  )

  const handleTextFieldClick = () => {
    setSearchDialogOpen(true)
  }

  function formattedStringToNumber(str: string): number {
    const cleanStr = str.replace(/[^\d.]/g, "")
    return Math.floor(Number(cleanStr) * 100)
  }

  // this function is here to remove the dollar sign and commas from
  // the string like the one above however it doesnt have the *100.
  // Since the above function is needed in the multiple places we cant just change it
  // so we need to make a new one. why a function? because we need to use it in multiple places
  function formattedPurchaseStringToNumber(str: string): number {
    const cleanStr = str.replace(/[^\d.]/g, "")
    return Math.floor(Number(cleanStr))
  }

  const purchaseHandler = asyncErrorHandler(async () => {
    try {
      setApiLoading(true) // Start loading state

      const payload: PurchasePayload = {
        Amount: formattedPurchaseStringToNumber(amount),
        PlayerId: selectedUser?.response?.id,
        ShiftId: authShift.currentShift.id,
      }

      if (isEntitlement) {
        payload.IsEntitlement = true
      } else {
        payload.IsPromo = false
      }

      const res = await purchase(payload).unwrap()
      await fetchUserHandler()
      const updatedAuthShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
      updatedAuthShift.currentShift = res?.currentShift
      sessionStorage.setItem("authShift", JSON.stringify(updatedAuthShift))
      setConfirmStatus(0)
      setConfirmPurchaseModal(false)
      setConfirmPrintModal(true)
    } finally {
      setApiLoading(false)
    }
  }, showSnackbar)

  const redeemHandler = asyncErrorHandler(async () => {
    try {
      setApiLoading(true)

      const res = await redeem({
        Amount: formattedStringToNumber(amount),
        RedeemMusicOnly: false,
        PlayerId: selectedUser?.response?.id,
        ShiftId: authShift.currentShift.id,
      }).unwrap()

      await fetchUserHandler()
      const updatedAuthShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
      updatedAuthShift.currentShift = res?.currentShift
      sessionStorage.setItem("authShift", JSON.stringify(updatedAuthShift))
      setRedeemData(res.redeemResponse)

      if (!res.redeemResponse.redeemed) {
        const message = res.redeemResponse.messages[0] || "Something went wrong."
        if (res.currentShift.endAmount === 0 || res.redeemResponse.availableAmount === 0) {
          showSnackbar(message, "error")
        }
        return
      }

      setConfirmStatus(1)
      setConfirmPrintModal(true)
    } finally {
      setApiLoading(false)
      setConfirmPurchaseModal(false)
    }
  }, showSnackbar)

  const compHandler = asyncErrorHandler(async () => {
    try {
      setApiLoading(true)

      const res = await purchase({
        Amount: entriesToMoney(formattedStringToNumber(amount)),
        IsPromo: true,
        PlayerId: selectedUser?.response?.id,
        ShiftId: authShift.currentShift.id,
        ReasonId: reason,
      }).unwrap()

      await fetchUserHandler()

      setConfirmStatus(2)
      setConfirmPurchaseModal(false)
      setConfirmPrintModal(true)
      setReason(null)
    } finally {
      setApiLoading(false)
    }
  }, showSnackbar)

  const [isConfirmLoading, setIsConfirmLoading] = useState(false)
  const confirmHandler = async () => {
    if (!amount || parseFloat(amount) === 0) {
      const messageEmptyAmnount = "Transaction amount cannot be empty or zero"
      showSnackbar(messageEmptyAmnount, "error")
      return
    }

    setIsConfirmLoading(true)

    try {
      if (!isEntitlement) {
        await fetchUserHandler()
      }
      if (userDataUpdated.current) {
        const userStored = localStorage.getItem("selectedUser")
        if (userStored) {
          const updatedUser = JSON.parse(userStored)
          setBouncebackAvailable(updatedUser?.parameters?.bouncebackAvailable)
          setConfirmPurchaseModal(true)
          setRedeemData(null)
          userDataUpdated.current = false
        }
      }
    } catch (error) {
      console.error("Error in confirmHandler:", error)
    } finally {
      setIsConfirmLoading(false)
    }
  }

  const confirmCompHandler = () => {
    if (!amount || parseFloat(amount) === 0) {
      showSnackbar("Transaction amount cannot be empty or zero", "error")
      return
    }
    setReasonsModal(true)
  }

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue)
    setAmount("")
    setIsEntitlement(false)
  }

  const handleAmountClick = (value: number) => {
    setAmount(value.toString())
  }

  const determineTextColor = (currentTabValue: number) => {
    switch (currentTabValue) {
      case 0: // Purchase tab
        return "inherit"
      case 1: // Redeem tab
        return "inherit"
      case 2: // Comp tab
        return "inherit"
      default:
        return "primary"
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("selectedUser")
    if (storedUser) {
      setSelectedUser(JSON.parse(storedUser))
    }

    return () => {
      localStorage.removeItem("selectedUser")
    }
  }, [])

  useEffect(() => {
    if (searchInput.length >= MIN_SEARCH_THRESHOLD && searchResultsData && searchDialogOpen) {
      const result = flattenSearchUser(searchResultsData)

      setUserList(result.response)
      setTotalUsers(result.total)
    }
  }, [searchResultsData, searchInput, searchDialogOpen])

  useEffect(() => {
    if (!searchDialogOpen) {
      setPage(1)
      setUserList([])
      setRowsPerPage(10)
      setQueryParameters((prev: any) => ({
        ...prev,
        page: 1,
        perPage: 10,
      }))
    }
  }, [searchDialogOpen])

  useEffect(() => {
    setIsInitialLoad(false)
  }, [])

  function formattedAmountToNumber(str: string): number {
    const cleanStr = str.replace(/[^\d.]/g, "")
    return Number(cleanStr)
  }
  const [maxRedeemLimit, setMaxRedeemLimit] = useState(0)
  const [strMaxRedeemLimit, setStrMaxRedeemLimit] = useState("")
  const drawerBalance = authShift?.currentShift?.endAmount
  const responseAvailableAmount = data?.reedemResponse?.availableAmount
  useEffect(() => {
    let calculatedMaxRedeemLimit
    let calculatedStrMaxRedeemLimit

    if (responseAvailableAmount <= drawerBalance) {
      calculatedMaxRedeemLimit = entriesToMoney(responseAvailableAmount)
      calculatedStrMaxRedeemLimit = entriesToMoney(responseAvailableAmount).toLocaleString()
      setDrawerBalanceWarning(false)
    } else {
      calculatedMaxRedeemLimit = entriesToMoney(drawerBalance)
      calculatedStrMaxRedeemLimit = entriesToMoney(drawerBalance).toLocaleString()
      setDrawerBalanceWarning(true)
    }

    setMaxRedeemLimit(calculatedMaxRedeemLimit)
    setStrMaxRedeemLimit(calculatedStrMaxRedeemLimit)
  }, [data, drawerBalance])

  const handleUserCreated = () => {
    setTabValue(0)
    setAmount("")
    return
  }

  const handlePrint = useReactToPrint({
    content: () => purchaseReceiptRef.current,
    removeAfterPrint: false,
  })

  const findPromoAmount = (amount: string, promoItems: PromoItem[]): number | null => {
    const purchaseAmount = formattedPurchaseStringToNumber(amount)

    // checks if promos(bouncebacks) are disabled on the CRM
    if (promoItems === null) {
      return null
    }

    for (const item of promoItems) {
      const { from, to } = item
      if (purchaseAmount >= from && purchaseAmount <= to) {
        return item.amount
      }
    }

    return null
  }

  const promoAmount = findPromoAmount(amount, promoItems)
  const promoToEntries = promoAmount !== null ? moneyToEntries(promoAmount) : undefined
  const formattedPromoAmount = promoToEntries ? promoToEntries.toLocaleString() : undefined

  const getReceiptProps = () => {
    if (confirmStatus === 0) {
      return isEntitlement
        ? { freePlay: amount?.toLocaleString(), receiptName: "Free Play" }
        : {
            purchase: formattedAmount,
            receiptName: "Purchase",
            ...(formattedPromoAmount &&
            bouncebackAvailable &&
            authShift?.response?.parameters?.bouncebackEnabled
              ? { promo: formattedPromoAmount }
              : {}),
          }
    } else if (confirmStatus === 1) {
      return { redeem: formattedAmount, receiptName: "Redeem" }
    } else if (confirmStatus === 2) {
      return { comp: amount?.toLocaleString(), receiptName: "Comp" }
    }
  }

  const receiptProps = getReceiptProps()

  const handlePageChange = (np: number) => {
    const newPage = np + 1

    setPage(newPage)

    setQueryParameters((prev: any) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1) // This will reset the page to the first pag
    setRowsPerPage(newRowsPerPage)
    setQueryParameters((prev: any) => ({
      ...prev,
      page: 1, // Ensure the API request is also set to fetch the first page
      perPage: newRowsPerPage,
    }))
    setRefetchKey((prevKey) => prevKey + 1) // Force a refetch
  }
  const localSelectedUser = JSON.parse(localStorage?.getItem("selectedUser") ?? "null")
  const nonNullFName = selectedUser?.response?.firstName ? selectedUser?.response?.firstName : ""
  const nonNullLName = selectedUser?.response?.lastName ? selectedUser?.response?.lastName : ""
  return (
    <>
      <Header title="Accounts" />
      <DrawerBox
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
        marginTop={0}
        paddingLeft={isPhone ? 1 : 0}
        paddingRight={isPhone ? 1 : 0}
      >
        <DrawerBox marginBottom={2} marginTop={0}>
          <Grid container columnSpacing={1} alignItems="stretch">
            <Grid item xs={8} sm={8}>
              <TextField
                label="Search accounts"
                onClick={handleTextFieldClick}
                fullWidth
                sx={{
                  my: 0,
                  "& .MuiInputBase-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} sx={{ justifyContent: "right" }}>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  fontSize: "calc(10px + 0.6vmin)",
                  height: "48px",
                }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setDialogOpen(true)}
              >
                New Account
              </Button>
            </Grid>
          </Grid>
          <SearchDialog
            totalUsers={totalUsers}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            isLoading={isFetching}
            MIN_SEARCH_THRESHOLD={MIN_SEARCH_THRESHOLD}
            open={searchDialogOpen}
            onClose={() => setSearchDialogOpen(false)}
            onSearch={setSearchInput}
            searchResults={userList}
            data={searchResultsData}
            handleUserSelected={handleUserSelected}
          />
          {selectedUser && <UserCard user={selectedUser?.response} />}
          <AddAccountDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onUserUpdatedOrCreated={handleUserCreated}
            fetchUser={fetchUserHandler}
          />
        </DrawerBox>

        <Tabs
          value={tabValue}
          onChange={(_, newValue) => handleTabChange(newValue)}
          textColor={determineTextColor(tabValue)}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{ height: "56px" }}
        >
          <Tab
            label="Purchase"
            disabled={selectedUser?.response?.freeEntries === undefined}
            sx={getTabStyle(tabValue, 0, {
              bgColor: customStyles.themePalette.green.lighter,
              textColor: customStyles.themePalette.green.darkest,
            })}
          />
          <Tab
            label="Redeem"
            disabled={
              data?.response?.winPoints === 0 || data?.response?.winPoints === undefined
                ? true
                : false
            }
            sx={getTabStyle(tabValue, 1, {
              bgColor: customStyles.themePalette.blue.lighter,
              textColor: customStyles.themePalette.blue.darkest,
            })}
          />
          {/* <Tab
            label="Comp"
            disabled={!data?.parameters?.promoAvailable}
            sx={getTabStyle(tabValue, 2, {
              bgColor: customStyles.themePalette.purple.main,
              textColor: customStyles.themePalette.purple.darkest,
            })}
          /> */}
        </Tabs>

        {tabValue === 0 && (
          <Box sx={{ paddingY: 2, paddingX: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                {[
                  [1, 5],
                  [10, 20],
                  [50, 100],
                ].map((pair, rowIndex) => (
                  <Grid
                    container
                    item
                    key={rowIndex}
                    spacing={1}
                    style={{
                      marginBottom: rowIndex !== 2 ? "8px" : "0",
                    }}
                  >
                    {pair.map((value, colIndex) => (
                      <Grid item xs={6} key={colIndex}>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            backgroundColor: customStyles.themePalette.green.lightest,
                            borderColor: customStyles.themePalette.green.main,
                            color: customStyles.themePalette.green.darkest,
                            "@media (hover: hover)": {
                              "&:hover": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                              "&:active": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            "@media (hover: none)": {
                              "&:hover": {
                                backgroundColor: customStyles.themePalette.green.lightest,
                                borderColor: customStyles.themePalette.green.main,
                              },
                              "&:active": {
                                backgroundColor: customStyles.themePalette.green.lightest,
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            height: "48px",
                            fontWeight: "bold",
                            fontSize: "calc(16px + 0.8vmin)",
                          }}
                          disabled={selectedUser?.response?.freeEntries === undefined}
                          fullWidth
                          onClick={() => {
                            handleAmountClick(value)

                            setIsEntitlement(false)
                          }}
                        >
                          ${value}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={4}>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  <NumericFormat
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value)
                      setIsEntitlement(false)
                    }}
                    onValueChange={(values) => {
                      const { value } = values
                      setAmount(value)
                    }}
                    label="Amount"
                    fullWidth
                    thousandSeparator=","
                    type="tel"
                    allowLeadingZeros={false}
                    allowNegative={false}
                    autoComplete="off"
                    fixedDecimalScale={true}
                    disabled={selectedUser?.response?.freeEntries === undefined}
                    decimalScale={0}
                    customInput={TextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ marginRight: 2 }} position="start">
                          <span
                            style={{
                              fontSize: "min(20px,calc(15px + 0.8vmin)",
                            }}
                          >
                            {isEntitlement ? "" : "$"}
                          </span>
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          fontWeight: "bold",
                          fontSize: "min(20px,calc(15px + 0.8vmin)",
                        },
                      },
                    }}
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "48px",
                        paddingLeft: 1,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isAllowed={(values) => {
                      const { floatValue } = values
                      // Allow the value to be empty or within the range
                      return (
                        floatValue === undefined ||
                        (floatValue >= 0 && floatValue <= MAX_PURCHASE_LIMIT)
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  {authShift?.response?.parameters?.entitlementActive ? (
                    <Button
                      sx={{
                        backgroundColor: isEntitlement
                          ? customStyles.components.buttons.outlined.hoverBackground
                          : customStyles.themePalette.green.lightest,
                        borderColor: isEntitlement
                          ? customStyles.components.buttons.outlined.hoverColor
                          : customStyles.themePalette.green.main,
                        color: isEntitlement
                          ? customStyles.components.buttons.outlined.hoverColor
                          : customStyles.themePalette.green.darkest,
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        fontSize: "calc(10px + 0.6vmin)",
                        height: "48px",
                      }}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="large"
                      disabled={!data?.parameters?.isDailyEntitlementActive}
                      onClick={() => {
                        setIsEntitlement(true)
                        handleAmountClick(data?.parameters?.dailyEntitlementAmount)
                      }}
                    >
                      Free Play
                    </Button>
                  ) : (
                    <div style={{ height: "48px" }}></div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      fontSize: "calc(10px + 0.6vmin)",
                      height: "48px",
                      backgroundColor: customStyles.themePalette.green.main,
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={selectedUser?.response?.freeEntries === undefined}
                    onClick={confirmHandler}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ paddingY: 2, paddingX: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                {[
                  [1, 5],
                  [10, 20],
                  [100, 200],
                ].map((pair, rowIndex) => (
                  <Grid
                    container
                    item
                    key={rowIndex}
                    spacing={1}
                    style={{
                      marginBottom: rowIndex !== 2 ? "8px" : "0",
                    }}
                  >
                    {pair.map((value, colIndex) => (
                      <Grid item xs={6} key={colIndex}>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            backgroundColor: customStyles.themePalette.blue.lightest,
                            borderColor: customStyles.themePalette.blue.main,
                            color: customStyles.themePalette.blue.darkest,
                            "@media (hover: hover)": {
                              "&:hover": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                              "&:active": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            "@media (hover: none)": {
                              "&:hover": {
                                backgroundColor: customStyles.themePalette.blue.lightest,
                                borderColor: customStyles.themePalette.blue.main,
                              },
                              "&:active": {
                                backgroundColor: customStyles.themePalette.blue.lightest,
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            height: "48px",
                            fontWeight: "bold",
                            fontSize: "calc(16px + 0.8vmin)",
                          }}
                          fullWidth
                          onClick={() => handleAmountClick(value)}
                          disabled={maxRedeemLimit < value}
                        >
                          ${value}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Grid item spacing={1} xs={4}>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  <NumericFormat
                    value={amount}
                    onValueChange={(values) => {
                      const { value } = values
                      setAmount(value)
                    }}
                    label="Amount"
                    fullWidth
                    thousandSeparator=","
                    autoComplete="off"
                    disabled={maxRedeemLimit === 0}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    type="tel"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    customInput={TextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ marginRight: 2 }} position="start">
                          <span
                            style={{
                              fontSize: "min(20px,calc(15px + 0.8vmin)",
                            }}
                          >
                            {"$"}
                          </span>
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          fontWeight: "bold",
                          fontSize: "min(20px,calc(15px + 0.8vmin)",
                        },
                      },
                    }}
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "48px",
                        paddingLeft: 1,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isAllowed={(values) => {
                      const { floatValue } = values
                      return (
                        floatValue === undefined ||
                        (floatValue >= 0 && floatValue <= maxRedeemLimit)
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  <Button
                    sx={{
                      backgroundColor: customStyles.themePalette.blue.lightest,
                      borderColor: customStyles.themePalette.blue.main,
                      color: customStyles.themePalette.blue.darkest,
                      "@media (hover: hover)": {
                        "&:hover": {
                          borderColor: customStyles.components.buttons.outlined.hoverColor,
                        },
                        "&:active": {
                          borderColor: customStyles.components.buttons.outlined.hoverColor,
                        },
                      },
                      "@media (hover: none)": {
                        "&:hover": {
                          backgroundColor: customStyles.themePalette.blue.lightest,
                          borderColor: customStyles.themePalette.blue.main,
                        },
                        "&:active": {
                          backgroundColor: customStyles.themePalette.blue.lightest,
                          borderColor: customStyles.components.buttons.outlined.hoverColor,
                        },
                      },
                      height: "48px",
                      fontWeight: "bold",
                      fontSize: "calc(16px + 0.8vmin)",
                    }}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={maxRedeemLimit === 0}
                    onClick={() => (maxRedeemLimit ? handleAmountClick(maxRedeemLimit) : null)}
                  >
                    <Stack
                      direction={isPhone ? "column" : "row"}
                      spacing={0}
                      alignItems={isPhone ? "center" : "baseline"}
                    >
                      {maxRedeemLimit > 0 && (
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            fontSize: "calc(14px + 0.8vmin)",
                          }}
                        >
                          ${strMaxRedeemLimit}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          fontSize:
                            maxRedeemLimit > 0 ? "calc(6px + 0.8vmin)" : "calc(10px + 0.6vmin)",
                          marginLeft: maxRedeemLimit > 0 ? "4px" : "0",
                        }}
                      >
                        Max
                      </Typography>
                    </Stack>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      fontSize: "calc(10px + 0.6vmin)",
                      height: "48px",
                      backgroundColor: customStyles.themePalette.blue.main,
                    }}
                    disabled={maxRedeemLimit === 0}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={confirmHandler}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {tabValue === 2 && (
          <Box sx={{ paddingY: 2, paddingX: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                {[
                  [100, 500],
                  [1000, 2000],
                  [10000, 20000],
                ].map((pair, rowIndex) => (
                  <Grid
                    container
                    item
                    key={rowIndex}
                    spacing={1}
                    style={{
                      marginBottom: rowIndex !== 2 ? "8px" : "0",
                    }}
                  >
                    {pair.map((value, colIndex) => (
                      <Grid item xs={6} key={colIndex}>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            backgroundColor: customStyles.themePalette.purple.lightest,
                            borderColor: customStyles.themePalette.purple.main,
                            color: customStyles.themePalette.purple.darkest,
                            "@media (hover: hover)": {
                              "&:hover": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                              "&:active": {
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            "@media (hover: none)": {
                              "&:hover": {
                                backgroundColor: customStyles.themePalette.purple.lightest,
                                borderColor: customStyles.themePalette.purple.main,
                              },
                              "&:active": {
                                backgroundColor: customStyles.themePalette.purple.lightest,
                                borderColor: customStyles.components.buttons.outlined.hoverColor,
                              },
                            },
                            height: "48px",
                            fontWeight: "bold",
                            fontSize: "calc(16px + 0.8vmin)",
                          }}
                          fullWidth
                          onClick={() => handleAmountClick(value)}
                          disabled={MAX_COMP_LIMIT < value}
                        >
                          {value?.toLocaleString()}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Grid item spacing={1} xs={4}>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  <NumericFormat
                    value={amount}
                    onValueChange={(values) => {
                      const { value } = values
                      setAmount(value)
                    }}
                    label="Amount"
                    fullWidth
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    allowNegative={false}
                    autoComplete="off"
                    fixedDecimalScale={true}
                    decimalScale={0}
                    type="tel"
                    customInput={TextField}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontWeight: "bold",
                          fontSize: "min(20px,calc(15px + 0.8vmin)",
                        },
                      },
                    }}
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "48px",
                        paddingLeft: 1,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    isAllowed={(values) => {
                      const { floatValue } = values
                      return (
                        floatValue === undefined ||
                        (floatValue >= 0 && floatValue <= MAX_PURCHASE_LIMIT)
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                  <Button
                    sx={{
                      backgroundColor: customStyles.themePalette.purple.lightest,
                      borderColor: customStyles.themePalette.purple.main,
                      color: customStyles.themePalette.purple.darkest,
                      whiteSpace: "nowrap",
                      height: "48px",
                    }}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => (MAX_COMP_LIMIT ? handleAmountClick(MAX_COMP_LIMIT) : null)}
                  >
                    <Stack
                      direction={isPhone ? "column" : "row"}
                      spacing={0}
                      alignItems={isPhone ? "center" : "baseline"}
                    >
                      {MAX_COMP_LIMIT > 0 && (
                        <Typography
                          sx={{
                            color: customStyles.themePalette.purple.darkest,
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            fontSize: "calc(14px + 0.8vmin)",
                          }}
                        >
                          {STR_MAX_COMP_LIMIT}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          color: customStyles.themePalette.purple.darkest,
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          fontSize:
                            MAX_COMP_LIMIT > 0 ? "calc(6px + 0.8vmin)" : "calc(10px + 0.6vmin)",
                          marginLeft: MAX_COMP_LIMIT > 0 ? "4px" : "0",
                        }}
                      >
                        Max
                      </Typography>
                    </Stack>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      fontSize: "calc(10px + 0.6vmin)",
                      height: "48px",
                      backgroundColor: customStyles.themePalette.purple.main,
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => {
                      confirmCompHandler()
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )} */}

        <PromoMessages
          drawerBalance={drawerBalance}
          drawerBalanceWarning={drawerBalanceWarning}
          tabValue={tabValue}
          maxRedeemLimit={responseAvailableAmount}
          winPoints={data?.response?.winPoints}
          redeemLimitExists={!!authShift?.response?.parameters?.redeemLimit}
          bouncebackAvailable={localSelectedUser?.parameters?.bouncebackAvailable}
          selectedUserExists={localSelectedUser === null ? false : true}
        />

        <div style={{ display: "none" }}>
          <ReceiptContent
            ref={purchaseReceiptRef}
            accountId={
              selectedUser?.response?.userName
                ? selectedUser?.response?.userName
                : selectedUser?.response?.playerNumber
            }
            {...receiptProps}
            freeEntries={
              selectedUser?.response?.freeEntries !== undefined
                ? selectedUser?.response?.freeEntries?.toLocaleString()
                : ""
            }
            winPoints={
              selectedUser?.response?.winPoints !== undefined
                ? formatAmount(entriesToMoney(selectedUser?.response?.winPoints))
                : "--"
            }
          />
        </div>
        {amount && amount !== "" && amount !== "0" && (
          <CustomDialog
            open={confirmPurchaseModal}
            isLoading={apiLoading}
            disabledPrimaryAction={apiLoading}
            title={`Confirm  ${
              tabValue === 0
                ? isEntitlement
                  ? "Free Play"
                  : "Purchase"
                : tabValue === 1
                ? "Redeem"
                : "Comp"
            }`}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <>
                    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                      Amount:
                    </Typography>
                    <Typography variant="h6">
                      {isEntitlement
                        ? amount?.toLocaleString() + " points"
                        : tabValue !== 0 && tabValue !== 1
                        ? formattedAmount + " points"
                        : formattedAmount}
                    </Typography>
                  </>
                </Stack>

                {tabValue === 0 &&
                  !isEntitlement &&
                  authShift?.response?.parameters?.bouncebackEnabled && (
                    <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                      <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                        Promo Amount:
                      </Typography>
                      <Typography variant="h6">
                        {formattedPromoAmount !== undefined && bouncebackAvailable ? (
                          <>{formattedPromoAmount} points</>
                        ) : (
                          <>Not Available</>
                        )}
                      </Typography>
                    </Stack>
                  )}
                {nonNullFName !== "" || nonNullLName !== "" ? (
                  <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                      Player Name:
                    </Typography>
                    <Typography variant="h6">{`${nonNullFName + " " + nonNullLName}`}</Typography>
                  </Stack>
                ) : (
                  <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                      Player Number:
                    </Typography>
                    <Typography variant="h6">{`${
                      selectedUser?.response?.userName || selectedUser?.response?.playerNumber
                    }`}</Typography>
                  </Stack>
                )}
                <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                  Do you want to proceed?
                </Typography>
              </>
            }
            primaryActionText="Yes"
            secondaryActionText="No"
            onPrimaryAction={() => {
              if (tabValue === 0) {
                purchaseHandler()
              } else if (tabValue === 1) {
                redeemHandler()
              } else {
                compHandler()
              }
            }}
            onSecondaryAction={async () => {
              setConfirmPurchaseModal(false)
              userDataUpdated.current = true
              // setChangeRedeemAmount(false)
            }}
            handleClose={async () => {
              setConfirmPurchaseModal(false)
              userDataUpdated.current = true
              // setChangeRedeemAmount(false)
            }}
          />
        )}
        {amount && amount !== "" && amount !== "0" && (
          <CustomDialog
            open={confirmPrintModal}
            title={`${
              confirmStatus === 0
                ? isEntitlement
                  ? "Free Play"
                  : "Purchase"
                : confirmStatus === 1
                ? "Redeem"
                : "Comp"
            } Confirmed`}
            content={
              <>
                <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                  <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                    Amount:
                  </Typography>
                  <Typography variant="h6">
                    {isEntitlement
                      ? amount?.toLocaleString() + " points"
                      : confirmStatus !== 0 && confirmStatus !== 1
                      ? amount?.toLocaleString() + " points"
                      : formattedAmount}
                  </Typography>
                </Stack>
                {confirmStatus === 0 &&
                  !isEntitlement &&
                  authShift?.response?.parameters?.bouncebackEnabled && (
                    <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                      <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                        Promo Amount:
                      </Typography>
                      <Typography variant="h6">
                        {formattedPromoAmount !== undefined && bouncebackAvailable ? (
                          <>{formattedPromoAmount} points</>
                        ) : (
                          "Not Available"
                        )}
                      </Typography>
                    </Stack>
                  )}
                {nonNullFName !== "" || nonNullLName !== "" ? (
                  <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                      Player Name:
                    </Typography>
                    <Typography variant="h6">{`${nonNullFName + " " + nonNullLName}`}</Typography>
                  </Stack>
                ) : (
                  <Stack marginBottom={"10px"} direction="row" spacing={1} alignItems="baseline">
                    <Typography sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
                      Player Number:
                    </Typography>
                    <Typography variant="h6">{`${
                      selectedUser?.response?.userName || selectedUser?.response?.playerNumber
                    }`}</Typography>
                  </Stack>
                )}
              </>
            }
            dialogContentText={""}
            primaryActionText="Print Receipt"
            secondaryActionText="Close"
            onPrimaryAction={async () => {
              setConfirmPrintModal(false)
              setAmount("")

              handlePrint()
            }}
            onSecondaryAction={async () => {
              setConfirmPrintModal(false)
              setAmount("")
              userDataUpdated.current = true
            }}
            handleClose={async () => {
              setConfirmPrintModal(false)
              setAmount("")
              userDataUpdated.current = true
            }}
          />
        )}
        {amount && amount !== "" && amount !== "0" && (
          <CustomDialog
            open={reasonsModal}
            title={"Comp Reason"}
            content={
              <>
                <Typography sx={{ marginBottom: 1, fontSize: "min(20px, calc(14px + 0.8vmin))" }}>
                  Select a reason:
                </Typography>
                {authShift?.response?.parameters?.promoTemplate?.reasons?.map(function (reason: {
                  id: string | null
                  name: string
                }) {
                  return (
                    <div key={reason.id} style={{ marginBottom: 1 }}>
                      <label>
                        <input
                          onChange={function (e) {
                            if (e.target.checked) {
                              setReason(reason.id)
                            }
                          }}
                          type="radio"
                          name="reason"
                          style={{ fontSize: "min(20px, calc(12px + 0.8vmin))", paddingBottom: 1 }}
                        />
                        {reason.name}
                      </label>
                    </div>
                  )
                })}
              </>
            }
            dialogContentText={""}
            primaryActionText="Confirm"
            secondaryActionText="cancel"
            onPrimaryAction={() => {
              setConfirmPurchaseModal(true)
              setReasonsModal(false)
            }}
            onSecondaryAction={() => setReasonsModal(false)}
            handleClose={() => {
              setReasonsModal(false)
              setReason(null)
            }}
            disabledPrimaryAction={reason === null}
          />
        )}
      </DrawerBox>
    </>
  )
}
