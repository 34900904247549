// OfflineBackdrop.tsx

import React from "react"
import Backdrop from "@mui/material/Backdrop"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

interface OfflineBackdropProps {
  isOffline: boolean
}

const OfflineBackdrop: React.FC<OfflineBackdropProps> = ({ isOffline }) => {
  document.body.style.overflow = isOffline ? "hidden" : "unset"

  return (
    <Backdrop
      sx={{
        color: "#fffffd",
        zIndex: (theme) => theme.zIndex.tooltip + 1,
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      open={isOffline}
    >
      <CircularProgress color="inherit" style={{ marginBottom: 16, fontSize: 18 }} />

      <Typography variant="h4" color="secondary" style={{ textAlign: "center" }}>
        You are offline!
      </Typography>
      <Typography variant="body1">Please check your internet connection.</Typography>
    </Backdrop>
  )
}

export default OfflineBackdrop
