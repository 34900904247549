enum EnvironmentType {
  Local = "local",
  Development = "dev",
  QA = "qa",
  Staging = "staging",
  Production = "prod",
}

interface OidcConfig {
  client_id: string
  redirect_uri: string
  scope: string
  authority: string
  refresh_time_before_tokens_expiration_in_second: number
  service_worker_relative_url: string
  service_worker_only: boolean
  monitor_session: boolean
  token_renew_mode: string
  loadUserInfo: boolean
  post_logout_redirect_uri: string
  response_type: string
}

// Configurations for development and production
const environmentConfigurations: Record<string, OidcConfig> = {
  [EnvironmentType.Local]: {
    client_id: "SLNGPos",
    redirect_uri: "https://localhost:3000/",
    scope: "openid profile email SLNGApi",
    authority: "https://prem-pos-auth-dev3.pongstudios.ca",
    refresh_time_before_tokens_expiration_in_second: 40,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    monitor_session: false,
    token_renew_mode: "access_token_invalid",
    loadUserInfo: true,
    post_logout_redirect_uri: "https://localhost:3000/",
    response_type: "code",
  },
  [EnvironmentType.Development]: {
    client_id: "SLNGPos",
    redirect_uri: "https://webpos.pongstudios.ca/",
    scope: "openid profile email SLNGApi",
    authority: "https://prem-pos-auth-dev3.pongstudios.ca",
    refresh_time_before_tokens_expiration_in_second: 40,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    monitor_session: true,
    token_renew_mode: "access_token_invalid",
    loadUserInfo: true,
    post_logout_redirect_uri: "https://webpos.pongstudios.ca/",
    response_type: "code",
  },
  [EnvironmentType.QA]: {
    client_id: "SLNGPos",
    redirect_uri: "https://webposqa1.pongstudios.ca/",
    scope: "openid profile email SLNGApi",
    authority: "https://prem-pos-auth-qa1.pongstudios.ca",
    refresh_time_before_tokens_expiration_in_second: 40,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    monitor_session: true,
    token_renew_mode: "access_token_invalid",
    loadUserInfo: true,
    post_logout_redirect_uri: "https://webposqa1.pongstudios.ca/",
    response_type: "code",
  },
  [EnvironmentType.Staging]: {
    client_id: "SLNGPos",
    redirect_uri: "https://www.qc-pos.com/",
    scope: "openid profile email SLNGApi",
    authority: "https://prem-pos-auth-staging.pongstudios.ca",
    refresh_time_before_tokens_expiration_in_second: 40,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    monitor_session: true,
    token_renew_mode: "access_token_invalid",
    loadUserInfo: true,
    post_logout_redirect_uri: "https://www.qc-pos.com/",
    response_type: "code",
  },
  [EnvironmentType.Production]: {
    client_id: "SLNGPos",
    redirect_uri: "https://www.qcpos.net/",
    scope: "openid profile email SLNGApi",
    authority: "https://premium-pos-auth.playfh.com",
    refresh_time_before_tokens_expiration_in_second: 40,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
    monitor_session: true,
    token_renew_mode: "access_token_invalid",
    loadUserInfo: true,
    post_logout_redirect_uri: "https://www.qcpos.net/",
    response_type: "code",
  },
}

function getOidcConfig(): OidcConfig {
  const deploymentTarget = process.env.REACT_APP_DEPLOYMENT_TARGET || process.env.NODE_ENV
  return (
    environmentConfigurations[deploymentTarget as keyof typeof environmentConfigurations] ||
    environmentConfigurations[EnvironmentType.Development]
  )
}

const config = getOidcConfig()

// Export the configuration
export default config
