import customStyles from "../styles/tokens.json"
export enum TitleColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
  Orange = "orange",
  Green = "green",
  Red = "red",
  Pink = "pink",
  Grey = "grey",
  Yellow = "yellow",
  Purple = "purple",
  Blue = "blue",
  DarkGreen = "darkGreen",
}
export const themeColorPalette = customStyles.themePalette
export const getColorFromTitle = (title: string): TitleColor => {
  switch (title) {
    case "Add":
      return TitleColor.Orange
    case "Purchase POS":
    case "Purchase External":
    case "Purchase":
      return TitleColor.Green
    case "Free Play":
      return TitleColor.Yellow
    case "Promo":
      return TitleColor.Purple
    case "Remove":
      return TitleColor.Red
    case "Redeem":
      return TitleColor.Blue
    case "Adjustment":
    // case "Retention":
    case "Cancel Purchase":
    case "Cancel Promo":
    case "Cancel Redeem":
    case "Cancel Promo":
      return TitleColor.Grey

    case "Start Balance":
    case "Net":
    case "Balance":
    case "End Balance":
      return TitleColor.DarkGreen
    default:
      return TitleColor.PRIMARY
  }
}
