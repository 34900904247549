import React from "react"
import { useOidc } from "@axa-fr/react-oidc"
import { HomeSkeleton } from "../../layouts/Skeletons/HomeSkeleton"

export const AuthSuccessComp: React.FC = () => {
  const { isAuthenticated } = useOidc()

  React.useEffect(() => {
    if (isAuthenticated) {
      window.location.href = "/home"
    }
  }, [isAuthenticated])

  return isAuthenticated ? null : <HomeSkeleton />
}

export default AuthSuccessComp
