import React from "react"
import { Typography } from "@mui/material"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { entriesToMoney, moneyToEntries } from "../../utils/entriesToMoney"
import { formatDateAndTime } from "../../utils/formattingFunctions/formatDateAndTime"

interface PromoMessagesProps {
  tabValue: number
  bouncebackAvailable?: boolean
  maxRedeemLimit: number
  selectedUserExists: boolean
  drawerBalanceWarning: boolean
  drawerBalance: number
  redeemLimitExists: boolean
  winPoints: number
}
interface PromoData {
  amount: number
  from: number
  to: number
}

export const PromoMessages: React.FC<PromoMessagesProps> = ({
  tabValue,
  bouncebackAvailable,
  selectedUserExists: selectedUser,
  maxRedeemLimit,
  drawerBalanceWarning,
  drawerBalance,
  redeemLimitExists,
  winPoints,
}) => {
  const formatedDrawerBalance = entriesToMoney(drawerBalance)
  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
  const originalMessages: PromoData[] | null = authShift?.response?.parameters?.items || null
  const updatedMessages = originalMessages?.map((data) => {
    if (data.to == Number.MAX_VALUE) {
      return `$${data.from.toFixed(2)} or more are eligible for up to ${moneyToEntries(
        data.amount
      )} promo points.`
    }
    return `$${data.from.toFixed(2)} - $${data.to.toFixed(
      2
    )} are eligible for up to ${moneyToEntries(data.amount)} promo points.`
  })
  const localSelectedUser = JSON.parse(localStorage?.getItem("selectedUser") ?? "null")
  const createdOnTime = formatDateAndTime(localSelectedUser?.response?.createdOn).formattedTime
  const currentDate = new Date()
  const currentTime = formatDateAndTime(currentDate.toISOString(), false, false).formattedTime
  if (currentTime > createdOnTime) {
    currentDate.setDate(currentDate.getDate() + 1)
  }
  const { formattedDate } = formatDateAndTime(currentDate.toISOString(), true, false)
  const dateTimeString = `${createdOnTime} on ${formattedDate}`
  return (
    <>
      {tabValue === 0 && authShift?.response?.parameters?.bouncebackEnabled && (
        <>
          <Alert severity="success">
            <AlertTitle sx={{ fontWeight: 700 }}>Promos</AlertTitle>
            {updatedMessages &&
            (selectedUser ? bouncebackAvailable : true) &&
            updatedMessages.length > 0 ? (
              updatedMessages.map((message, index) => {
                return (
                  <Typography key={index} fontSize={"min(20px, calc(10px + 0.6vmin))"}>
                    {message}
                  </Typography>
                )
              })
            ) : (
              <Typography variant="body1">No promos available</Typography>
            )}
          </Alert>
          <Alert severity="info" sx={{ marginTop: 2 }}>
            <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
              {(authShift?.response?.parameters?.message || "").replace(/bounceback/g, "promo ")}{" "}
            </Typography>
          </Alert>
        </>
      )}
      {tabValue === 1 && (
        <>
          {redeemLimitExists && maxRedeemLimit === 0 && winPoints > maxRedeemLimit && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
                The daily redemption limit has been reached. The remaining redeemable balance will
                be available at {dateTimeString}.
              </Typography>
            </Alert>
          )}
          {drawerBalanceWarning &&
            (drawerBalance === 0 ? (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
                  Drawer balance is zero. Please add funds to the drawer.
                </Typography>
              </Alert>
            ) : (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>
                  Maximum redeemable amount is set to the available drawer balance of $
                  {formatedDrawerBalance}.
                </Typography>
              </Alert>
            ))}
        </>
      )}
    </>
  )
}
