import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
// import { summaryShiftData } from "../../data/summaryShiftData"
import { ShiftLogInput, ShiftLogOutput } from "../../types/IShift"
import { formatAmount } from "../formattingFunctions/formatAmount"
import { entriesToMoney } from "../entriesToMoney"

export function flattenShiftLog(apiResponse: ShiftLogInput): ShiftLogOutput {
  const shifts = apiResponse.shifts.map((shift) => {
    const { formattedDate: startingDate, formattedTime: startingTime } = formatDateAndTime(
      shift.starting
    )
    const { formattedDate: endingDate, formattedTime: endingTime } = formatDateAndTime(shift.ending)
    const startAmount = formatAmount(entriesToMoney(shift.startAmount))
    const endAmount = formatAmount(entriesToMoney(shift.endAmount))
    const summaries = [
      {
        title: "Add",
        amountFull: formatAmount(entriesToMoney(shift.fills)),
        count: shift.fillsCount,
      },
      {
        title: "Remove",
        amountFull: formatAmount(entriesToMoney(shift.bleeds)),
        count: shift.bleedsCount,
      },
      {
        title: "Purchase",
        amountFull: formatAmount(entriesToMoney(shift.purchases)),
        count: shift.purchasesCount,
      },
      {
        title: "Redeem",
        amountFull: formatAmount(entriesToMoney(shift.redeems)),
        count: shift.redeemsCount,
      },
      {
        title: "Adjustment",
        amountFull: formatAmount(
          entriesToMoney(shift.cancels !== 0 ? -Math.abs(shift.cancels) : 0)
        ),
        count: shift.cancelsCount,
      },
      {
        title: "Free Play",
        amountFull: shift.freePlays.toLocaleString(),
        count: shift.freePlaysCount,
      },
      {
        title: "Promo",
        amountFull: shift.bouncebacks.toLocaleString(),
        count: shift.bouncebacksCount,
      },
      // {
      //   title: "Retention",
      //   amountFull: formatAmount(entriesToMoney(shift.retentionPoints)) ,
      //   count: shift.retentionPointsCount,
      // },
      {
        title: "Start Balance",
        amountFull: formatAmount(entriesToMoney(shift.startAmount)),
      },
      {
        title: "End Balance",
        amountFull: formatAmount(entriesToMoney(shift.endAmount)),
      },
    ]
    return {
      ...shift,
      startAmount: shift.startAmount.toString(),
      endAmount: shift.startAmount.toString(),
      startAmountFull: startAmount,
      endAmountFull: endAmount,
      startingDate,
      startingTime,
      endingDate,
      endingTime,
      summaries,
    }
  })

  return {
    total: apiResponse.total,
    shifts: shifts,
  }
}
