import { useSnackbar } from "notistack"

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSnackbar = (
    message: string | null,
    severity: "default" | "error" | "success" | "warning" | "info" | undefined
  ) => {
    if (message !== "") {
      enqueueSnackbar(message, { variant: severity })
    }
  }

  return showSnackbar
}
