import { TransactionReportInput } from "../types/ITransactionReport"
import { ISummaries } from "../types/ISummaries"
import { formatAmount } from "../utils/formattingFunctions/formatAmount"
import { entriesToMoney } from "../utils/entriesToMoney"

export function summaryReportData(input: TransactionReportInput): ISummaries[] {
  const summaries = [
    {
      title: "Purchase",
      amountFull: formatAmount(entriesToMoney(input.totalSales)),
      count: input.salesCount,
    },
    {
      title: "Redeem",
      amountFull: formatAmount(entriesToMoney(input.totalRedeem)),
      count: input.redeemsCount,
    },
    {
      title: "Adjustment",
      amountFull: formatAmount(
        entriesToMoney(input.totalCancels) !== 0 ? -Math.abs(entriesToMoney(input.totalCancels)) : 0
      ),

      count: input.cancelsCount,
    },
    {
      title: "Free Play",
      amountFull: input.totalEntitlements.toLocaleString(),
      count: input.entitlementsCount,
    },
    {
      title: "Promo",
      amountFull: input.totalComps.toLocaleString(),
      count: input.compsCount,
    },
    {
      title: "Add",
      amountFull: formatAmount(entriesToMoney(input.totalFills)),
      count: input.fillsCount,
    },
    {
      title: "Remove",
      amountFull: formatAmount(entriesToMoney(input.totalBleeds)),
      count: input.bleedsCount,
    },
    {
      title: "Net",
      amountFull: formatAmount(entriesToMoney(input.net)),
    },
    // {
    //   title: "Retention",
    //   amountFull: formatAmount( input.retentionPoints ) ,
    //   count: input.retentionPointsCount,
    // },
    // {
    //   title: "Comp",
    //   amountFull: formatAmount( input.promos ) ,
    //   count: input.promosCount,
    // },
  ]

  return summaries
}
