import { ShiftInput } from "../types/IShift"
import { entriesToMoney } from "../utils/entriesToMoney"
import { formatAmount } from "../utils/formattingFunctions/formatAmount"

export function summariesData(input: ShiftInput) {
  const summaries = [
    {
      title: "Add",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.fills)),
      count: input.shiftPOCO.fillsCount,
    },
    {
      title: "Remove",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.bleeds)),
      count: input.shiftPOCO.bleedsCount,
    },
    {
      title: "Purchase",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.purchases)),
      count: input.shiftPOCO.purchasesCount,
    },
    {
      title: "Redeem",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.redeems)),
      count: input.shiftPOCO.redeemsCount,
    },
    {
      title: "Adjustment",
      amountFull: formatAmount(
        entriesToMoney(input.shiftPOCO.cancels !== 0 ? -Math.abs(input.shiftPOCO.cancels) : 0)
      ),
      count: input.shiftPOCO.cancelsCount,
    },
    {
      title: "Free Play",
      amountFull: input.shiftPOCO.freePlays.toLocaleString(),
      count: input.shiftPOCO.freePlaysCount,
    },
    {
      title: "Promo",
      amountFull: input.shiftPOCO.bouncebacks.toLocaleString(),
      count: input.shiftPOCO.bouncebacksCount,
    },
    // {
    //   title: "Retention",
    //   amountFull: formatAmount(entriesToMoney(input.shiftPOCO.retentionPoints)) ,
    //   count: input.shiftPOCO.retentionPointsCount,
    // },
    {
      title: "Start Balance",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.startAmount)),
    },
    {
      title: "Balance",
      amountFull: formatAmount(entriesToMoney(input.shiftPOCO.endAmount)),
    },
  ]

  return summaries
}
