import { useEffect } from "react"
import { useCustomSnackbar } from "../components/multiSnackbar"

// the result should be any type, like in rtk query originally

export function useError(result: any) {
  const showSnackbar = useCustomSnackbar()
  useEffect(() => {
    if (result.error) {
      showSnackbar(result.error.data || "Something went wrong!", "error")
    }
  }, [result.error])

  return result
}
