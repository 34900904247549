import React, { useEffect, useState } from "react"
import { Alert, Typography } from "@mui/material"

import { useGetShiftHistoryQuery } from "../../redux/user/user.api"

import { DrawerBox } from "../../styles/styledComponents/DrawerBox"
import { ShiftTableMemo } from "../../components/Shift/ShiftTable"
import { flattenShiftLog } from "../../utils/flatteningFunctions/flattenShifts"
import { ShiftLogShiftOutput } from "../../types/IShift"
import { ShiftTableSkeleton } from "../../layouts/Skeletons/ShiftTableSkeleton"
import { ErrorLayout } from "../../layouts/ErrorLayout"
import { Header } from "../../components/Header"

export const Shifts = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [requestPage, setRequestPage] = useState(1)
  const [visitedPages, setVisitedPages] = useState<number[]>([1])

  const [shifts, setShifts] = useState<Record<number, ShiftLogShiftOutput[]>>({})

  const { data, error, isLoading } = useGetShiftHistoryQuery({ page: requestPage, perPage: 10 })

  const [isPaginationLoading, setIsPaginationLoading] = useState(false)

  const handlePageChange = (newPage: number) => {
    if (!visitedPages.includes(newPage)) {
      setIsPaginationLoading(true) // Set loading when pagination changes
      setRequestPage(newPage)
      setVisitedPages([...visitedPages, newPage])
    }
    setCurrentPage(newPage)
  }

  useEffect(() => {
    if (data !== undefined && data !== null) {
      const result = flattenShiftLog(data)
      setShifts((prevShifts) => ({
        ...prevShifts,
        [currentPage]: result.shifts,
      }))
      setIsPaginationLoading(false) // Reset loading after fetching data
    }
  }, [data])

  if (isLoading || isPaginationLoading) {
    return (
      <>
        <Header title="Shift Logs" />
        <DrawerBox marginBottom={0} marginLeft={0} marginRight={0} marginTop={0}>
          <ShiftTableSkeleton />
        </DrawerBox>
      </>
    )
  }

  return (
    <ErrorLayout error={error}>
      <>
        <Header title="Shift Logs" />
        {data && data?.total !== 0 ? (
          <DrawerBox marginBottom={0} marginLeft={0} marginRight={0} marginTop={0}>
            <ShiftTableMemo
              users={shifts[currentPage] || []}
              total={data?.total}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </DrawerBox>
        ) : (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            <Typography fontSize={"min(20px, calc(10px + 0.6vmin))"}>No data to show</Typography>
          </Alert>
        )}
      </>
    </ErrorLayout>
  )
}
