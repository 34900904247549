import type { UserInput, UserOutput, GetUserParameters } from "../../types/IUser"
import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
import { formatAmount } from "../formattingFunctions/formatAmount"

export function flattenAccountDetails(
  input: UserInput,
  parameters: GetUserParameters
): UserOutput & { parameters: GetUserParameters } {
  const name = input?.playerLabel
  const playerNumber = input?.playerNumber
  const unrestrictedBalanceFull = formatAmount(input?.unrestrictedBalance)
  const potentialBalanceFull = formatAmount(input?.potentialBalance)
  const productBalanceFull = formatAmount(input?.productBalance)
  const output: UserOutput & { parameters: GetUserParameters } = {
    ...input,
    unrestrictedBalance: input?.unrestrictedBalance.toString(),
    potentialBalance: input?.potentialBalance.toString(),
    productBalance: input?.productBalance.toString(),
    unrestrictedBalanceFull: unrestrictedBalanceFull,
    productBalanceFull: productBalanceFull,
    potentialBalanceFull: potentialBalanceFull,
    playerNumber: playerNumber,
    name: name,
    parameters: parameters,
  }

  return output
}
