import { TransactionTypeAlt, UnknownTransactionType } from "../../constants/transactionTypes"

import { summaryReportData } from "../../data/summaryReportData"
import { ISummaries } from "../../types/ISummaries"
import type { TransactionOutput, TransactionReportInput } from "../../types/ITransactionReport"
import { moneyToEntries } from "../entriesToMoney"
import { capitalizeFirstLetter } from "../formattingFunctions/capitalizeFirstLetter"
import { formatAmount } from "../formattingFunctions/formatAmount"
import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"

export function generateSummaries(input: TransactionReportInput): ISummaries[] {
  const net = input.totalSales - input.totalRedeem - input.totalCancels

  const updatedInput = {
    ...input,
    net: net,
  }

  return summaryReportData(updatedInput)
}

export function flattenTransactions(input: TransactionReportInput): {
  transactions: TransactionOutput[]
  summaries: ISummaries[]
} {
  const transactions = input.response.map((transaction) => {
    const { formattedDate: date, formattedTime: time } = formatDateAndTime(transaction.timestamp)
    const playerNameOrNumber = transaction.userName ?? transaction.playerNumber ?? "[Drawer]"

    const fullName =
      (transaction?.firstName ? capitalizeFirstLetter(transaction.firstName) : "") +
      " " +
      (transaction?.lastName ? capitalizeFirstLetter(transaction.lastName) : "")

    const name = fullName.trim() ? fullName : "-"

    const transactionValueObj =
      transaction.transactionType === "Bounceback" ||
      transaction.transactionType === "Free Play" ||
      transaction.transactionType === "Cancel Bounceback"
        ? moneyToEntries(transaction.transactionValue).toLocaleString()
        : formatAmount(transaction.transactionValue)
    const transactionValue = transactionValueObj
    const transactionType =
      TransactionTypeAlt(transaction.transactionType) || UnknownTransactionType

    return {
      ...transaction,
      name: name,
      date,
      time,
      playerNumber: playerNameOrNumber,
      transactionValue: transactionValue,
      transactionType: transactionType,
    }
  })
  const summaries = generateSummaries(input)

  return {
    transactions: transactions,
    summaries: summaries,
  }
}
