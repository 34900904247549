/* eslint-disable react/prop-types */

import dayjs, { Dayjs } from "dayjs"
import { DateRangePicker } from "../DateRangePicker"

interface RenderCalendarProps {
  headerDateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  setReportDateRange: React.Dispatch<React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>>
  disabled?: boolean
  setGiveMargin: React.Dispatch<React.SetStateAction<boolean>>
}

export const RenderCalendar: React.FC<RenderCalendarProps> = ({
  headerDateRange,
  setReportDateRange,
  disabled = false,
  setGiveMargin,
}) => {
  const from = headerDateRange[0] ?? dayjs()
  const to = headerDateRange[1] ?? dayjs()
  const adjustedTo = to ? dayjs(to) : dayjs()
  return (
    <DateRangePicker
      disabled={disabled}
      value={[from, adjustedTo]}
      onChange={(dates: Array<Dayjs | null> | null) => {
        setReportDateRange(dates as [Dayjs | null, Dayjs | null])
      }}
      setGiveMargin={setGiveMargin}
    />
  )
}
