export interface CustomError {
  status?: number
  message: string
  data: string
}
type ShowSnackbarType = (
  message: string | null,
  severity: "default" | "error" | "success" | "warning" | "info" | undefined
) => void

const statusHandlers: {
  [key: number]: (showSnackbar: ShowSnackbarType, message?: string) => void
} = {
  401: (showSnackbar: ShowSnackbarType, message?: string) => {
    showSnackbar(message || "Unauthorized. Redirecting to login.", "error")
  },
  524: (showSnackbar: ShowSnackbarType, message?: string) => {
    showSnackbar(message || "Request Timed Out.", "error")
  },
  404: (showSnackbar: ShowSnackbarType, message?: string) => {
    showSnackbar(message || "A server could not find a client-requested API.", "error")
  },
  500: (showSnackbar: ShowSnackbarType, message?: string) => {
    showSnackbar(
      message ||
        "A server encountered an unexpected condition that prevented it from fulfilling the request.",
      "error"
    )
  },
}

export const asyncErrorHandler = <T extends unknown[], R>(
  asyncFunc: (...args: T) => Promise<R>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  showSnackbar: ShowSnackbarType
) => {
  return async (...params: T): Promise<R | void> => {
    try {
      return await asyncFunc(...params)
    } catch (error) {
      const typedError = error as CustomError

      const handler = typedError.status && statusHandlers[typedError.status]
      if (handler) {
        handler(showSnackbar, typedError.message)
      } else {
        showSnackbar(typedError.message || typedError.data || "Something went wrong!", "error")
      }
    }
  }
}
