import React, { useState, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material"

interface TableLayoutProps {
  headCells: string[]
  headCellsAlign?: ("left" | "center" | "right" | "justify" | "inherit")[]
  rows: any[]
  renderRow: (row: any) => JSX.Element
  showRowsPerPage?: boolean
  rowsPerPageOptions?: number[]
  initialRowsPerPage?: number
  width?: string[]
  onPageChange?: (newPage: number) => void
  totalNumberOfRows: number
  rowsPerPage: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  page: number
  hiddenPagination?: boolean
  maxHeight?: number
}
export const TableLayout: React.FC<TableLayoutProps> = ({
  headCells,
  headCellsAlign = [],
  rows,
  page,
  totalNumberOfRows,
  renderRow,
  showRowsPerPage = true,
  rowsPerPageOptions = [5, 10, 20],
  width = [],
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  hiddenPagination = false,
  maxHeight = 440,
}) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage)
    }
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    onRowsPerPageChange(newRowsPerPage)
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalNumberOfRows - page * rowsPerPage)
  return (
    <React.Fragment>
      <TableContainer sx={{ maxHeight: maxHeight, boxShadow: "none" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  align={headCellsAlign[index] || "inherit"}
                  key={headCell}
                  sx={{ width: width[index] || "auto", maxWidth: width[index] || "auto" }}
                >
                  {headCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => renderRow(row))}
            {!hiddenPagination && emptyRows > 0 && (
              <TableRow
                style={{
                  height: 34 * emptyRows,
                  pointerEvents: "none",
                }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!hiddenPagination && (
        <TablePagination
          rowsPerPageOptions={showRowsPerPage ? rowsPerPageOptions : []}
          component="div"
          count={totalNumberOfRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  )
}
