import React from "react"
import {
  Grid,
  Paper,
  Skeleton,
  Box,
  Stack,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material"

// Skeleton for individual summary item
const OverviewSummaryItemSkeleton: React.FC = () => {
  return (
    <Card elevation={0}>
      <CardContent
        style={{
          paddingTop: "min(14px,calc(0px + 1.2vmin))",
          paddingRight: 0,
          paddingBottom: "min(14px,calc(0px + 1.2vmin))",
          paddingLeft: 0,
        }}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          <Skeleton
            variant="circular"
            width={"min(50px, calc(32px + 1.4vmin))"}
            height={"min(50px, calc(32px + 1.4vmin))"}
            style={{ backgroundColor: "#dcdcdc" }}
          />
          <Box>
            <Skeleton
              sx={{
                backgroundColor: "#dcdcdc",
                fontSize: "min(16px,calc(12px + 0.6vmin))",
                width: "14ch",
                maxWidth: "14ch",
              }}
            />
            <Skeleton
              sx={{
                backgroundColor: "#dcdcdc",
                fontSize: "min(16px,calc(12px + 0.6vmin))",
                width: "14ch",
                maxWidth: "14ch",
              }}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

interface OverviewSummaryGridSkeletonProps {
  itemCount: number
}

// Skeleton for the grid containing summary items
export const OverviewSummaryGridSkeleton: React.FC<OverviewSummaryGridSkeletonProps> = ({
  itemCount,
}) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))

  const ROW_LENGTH = isLargeScreen ? 3 : isMediumScreen ? 3 : isSmallScreen ? 2 : 2

  // Create a fake "summaries" array filled with nulls for slicing
  const summaries = Array.from({ length: itemCount }, () => null)
  const typographySkeletonStyle = {
    width: 100,
    height: 24,
    marginLeft: "16px",
    backgroundColor: "#dcdcdc",
  }

  const iconButtonSkeletonStyle = {
    width: 24,
    height: 24,
    marginRight: "16px",
    backgroundColor: "#dcdcdc",
  }
  const getItemSx = () => {
    if (isLargeScreen) return { marginLeft: 2, marginRight: 2 }
    if (isMediumScreen) return { marginLeft: 5 }
    if (!isLargeScreen && !isMediumScreen) return { paddingLeft: 2, paddingRight: 2 }
    return {}
  }

  return (
    <Paper elevation={0} sx={{ marginBottom: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "min(14px,calc(2px + 1.2vmin))",
            marginBottom: !isSmallScreen
              ? "min(14px,calc(4px + 1.2vmin))"
              : "min(14px,calc(2px + 1.2vmin))",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Skeleton sx={typographySkeletonStyle} />
          <Skeleton variant="circular" sx={iconButtonSkeletonStyle} />
        </Grid>
        {Array.from({ length: Math.ceil(itemCount / ROW_LENGTH) }, (_, i) =>
          summaries.slice(i * ROW_LENGTH, i * ROW_LENGTH + ROW_LENGTH)
        ).map((rowSummaries, rowIndex) => (
          <Grid
            key={rowIndex}
            justifyContent="space-between"
            alignItems="center"
            container
            item
            spacing={0}
          >
            {rowSummaries.map((_, index) => (
              <Grid key={index} item xs={6} sm={6} md={3} lg={2} sx={getItemSx()}>
                <OverviewSummaryItemSkeleton />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}
