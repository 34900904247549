import { useState } from "react"
import { HomeButton } from "../../components/Home/HomeButton"
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { greeting } from "../../utils/greeting"
import { homeButtons } from "../../data/homepage"
import logo from "../../assets/logo.png"
import { LogoutModal } from "../../components/LogoutModal"
import { formatPhoneNumber } from "../../utils/formattingFunctions/formatPhoneNumber"

export function Home() {
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const authShiftString = sessionStorage.getItem("authShift")
  const authShift = authShiftString ? JSON.parse(authShiftString) : null
  const cashierName = authShift?.response?.accountName || ""
  const storeNumber = authShift?.response?.customerNumber
  const storeName = authShift?.response?.storeLabel
  const supportPhone = authShift?.response?.parameters?.supportPhone
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className="max-w-xl mx-auto flex flex-col items-center mb-10 mt-2 px-6">
      <img alt="Company Logo" src={logo} style={{ width: "min(200px,calc(50px + 15vmin))" }} />
      <Typography
        sx={{ fontSize: "min(20px,calc(12px + 0.8vmin))", whiteSpace: "nowrap" }}
        className="pt-5 pb-3"
      >
        {greeting()},
        <Typography
          component="span" // <-- changing the component to "span" makes it inline by default
          sx={{
            fontSize: "min(20px,calc(12px + 0.8vmin))",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            display: "inline", // <-- this ensures the element is inline
          }}
        >
          {" "}
          {cashierName}
          {"!"}
        </Typography>
      </Typography>
      <Grid
        marginTop={0}
        container
        paddingX={isSmallScreen ? "calc(-90px + 30vmin)" : 5}
        spacing={1}
        width={isSmallScreen ? {} : "calc(350px + 40vmin)"}
      >
        {homeButtons(() => setOpenLogoutModal(true))
          .slice(1)
          .map((value, index) => (
            <Grid key={index} item xs={6} sm={4}>
              <HomeButton
                key={index}
                href={value.href}
                onClick={value.onclick}
                color={value.color}
                icon={<value.Icon />}
                title={value.title}
              />
            </Grid>
          ))}
      </Grid>
      <Typography variant="body2" paddingTop={2}>
        Store Number: {storeNumber}
      </Typography>
      <Typography variant="body2" paddingTop={"1px"}>
        Store Name: {storeName}
      </Typography>
      {supportPhone && (
        <Typography variant="body2" paddingTop={"1px"}>
          Support Phone: {formatPhoneNumber(supportPhone)}
        </Typography>
      )}

      <Typography variant="body2" paddingTop={"1px"}>
        v{process.env.REACT_APP_VERSION}
      </Typography>
      <LogoutModal open={openLogoutModal} onClose={() => setOpenLogoutModal(false)} />
    </div>
  )
}
