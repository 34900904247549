import React from "react"
import { useNavigate } from "react-router-dom"
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined"
import Button from "@mui/material/Button"
import customStyles from "../styles/tokens.json"

const BackButton: React.FC = () => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1) // Navigates to the previous page
  }

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<ArrowBackIosOutlinedIcon />}
      onClick={handleBackClick}
      aria-label="Go back"
      sx={{ color: customStyles.components.buttons.primary.textColor }}
    >
      Back
    </Button>
  )
}

export default BackButton
