import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, Box, CardContent, CircularProgress, Stack, Badge } from "@mui/material"
import { getColorFromTitle, themeColorPalette, TitleColor } from "../../constants/getColorFromTitle"
import { getIconFromTitle } from "../../constants/getIconFromTitle"
import customStyles from "../../styles/tokens.json"
import OverviewTypography from "../../styles/styledComponents/OverviewTypography"
import { ISummaries } from "../../types/ISummaries"
import { formatNegativeAmount } from "../../utils/formattingFunctions/formatNegativeAmount"

interface ThemeColorPalette {
  light: string
  dark: string
  main: string
}

export const OverviewSummaryItem: React.FC<ISummaries> = ({
  hasFilter,
  title,
  amountFull,
  totalNumTransactions,
  count,
}) => {
  const themeColor: TitleColor = getColorFromTitle(title)
  const theme: ThemeColorPalette = themeColorPalette[themeColor]
  const navigate = useNavigate()
  const [isSelected, setIsSelected] = useState(false)

  const allowedTitles = [
    "Purchase",
    "Redeem",
    "Free Play",
    "Promo",
    "Add",
    "Remove",
    "Adjustment",
    "Net",
    "End Balance",
    "Balance",
    "Start Balance",
  ]

  const [queryParams] = useSearchParams()

  useEffect(() => {
    const titleFromParams = queryParams.get("title")

    const titleFromParamsArray = titleFromParams ? titleFromParams.split(",") : []

    if (titleFromParamsArray.includes(title)) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [queryParams])

  const handleClick = () => {
    if (!allowedTitles.includes(title)) {
      return
    }

    const currentUrl = new URL(window.location.href)
    const currentTitleParam = currentUrl.searchParams.get("title")
    const titlesArray = currentTitleParam ? currentTitleParam.split(",") : []

    // Get the current base route (either '/drawer' or '/transactions')
    const currentBaseRoute = window.location.pathname

    if (
      title === "Net" ||
      title === "Balance" ||
      title === "End Balance" ||
      title === "Start Balance"
    ) {
      currentUrl.searchParams.delete("title")
      navigate(`${currentBaseRoute}?title=`, { replace: true })
      return
    }
    const isPromoSelected = title === "Promo"
    const cancelPromoIndex = titlesArray.indexOf("Cancel Promo")

    if (titlesArray.includes(title)) {
      const index = titlesArray.indexOf(title)
      titlesArray.splice(index, 1)
      if (isPromoSelected) {
        const newCancelPromoIndex = titlesArray.indexOf("Cancel Promo")
        if (newCancelPromoIndex !== -1) {
          titlesArray.splice(newCancelPromoIndex, 1)
        }
      }
    } else {
      titlesArray.push(title)
      if (isPromoSelected && cancelPromoIndex === -1) {
        titlesArray.push("Cancel Promo")
      }
    }

    // Update the URL search params
    currentUrl.searchParams.set("title", titlesArray.join(","))
    // Navigate to the updated URL, keeping only the search params
    navigate(`${currentBaseRoute}${currentUrl.search}`, { replace: true })
  }

  const themeLightColor: string = themeColorPalette[themeColor].light
  const { displayAmount, amountStyle } = formatNegativeAmount(amountFull, customStyles)

  const renderCircularProgress = () => (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 1.5,
        cursor: hasFilter ? "pointer" : "default",
      }}
    >
      <CircularProgress
        thickness={2}
        sx={{ color: theme.light }}
        variant="determinate"
        size={"min(50px, calc(32px + 1.4vmin))"}
        value={100}
      />

      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {getIconFromTitle(title, "large", themeLightColor)}
      </Box>
    </Box>
  )

  return (
    <Card
      elevation={0}
      sx={{ overflow: "visible", backgroundColor: isSelected ? "#e6f7ff" : "#ffffff" }}
      onClick={hasFilter ? handleClick : undefined}
    >
      <CardContent
        sx={{
          borderRadius: customStyles.borders.radius,
          backgroundColor: isSelected ? "#e6f7ff" : "#ffffff",
        }}
        style={{
          paddingTop: "min(14px,calc(0px + 1.2vmin))",
          paddingRight: 0,
          paddingBottom: "min(14px,calc(0px + 1.2vmin))",
          paddingLeft: 0,
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Badge
            overlap="circular"
            badgeContent={count}
            showZero={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderCircularProgress()}
          </Badge>

          <Stack spacing={0}>
            <Box>
              <OverviewTypography>{title}</OverviewTypography>
            </Box>
            <Box>
              <OverviewTypography amount>
                <span style={amountStyle}>{displayAmount}</span>
              </OverviewTypography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
