import { createTheme } from "@mui/material/styles"
import customStyles from "./tokens.json"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    overviewTitle: React.CSSProperties
    overviewAmount: React.CSSProperties
    boldCaption: React.CSSProperties
    boldSubtitle1: React.CSSProperties
    boldSubtitle2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    overviewTitle?: React.CSSProperties
    overviewAmount?: React.CSSProperties
    boldCaption?: React.CSSProperties
    boldSubtitle1?: React.CSSProperties
    boldSubtitle2: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    overviewTitle: true
    overviewAmount: true
    boldCaption: true
    boldSubtitle1: true
    boldSubtitle2: true
  }
}

export const customTheme = createTheme({
  palette: {
    primary: {
      main: customStyles.colors.primary,
      contrastText: customStyles.colors.text.primary,
    },
    secondary: {
      main: customStyles.colors.secondary,
    },
    error: {
      main: customStyles.colors.error,
    },
    warning: {
      main: customStyles.colors.warning,
    },
    info: {
      main: customStyles.colors.info,
    },
    success: {
      main: customStyles.colors.success,
    },
    text: {
      primary: customStyles.colors.text.primary,
      secondary: customStyles.colors.text.secondary,
      disabled: customStyles.colors.text.disabled,
    },
    background: {
      default: customStyles.colors.background.default,
    },
  },
  typography: {
    fontFamily: customStyles.typography.fonts.primary,
    h1: {
      fontSize: customStyles.typography.sizes.header.h1,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    h2: {
      fontSize: customStyles.typography.sizes.header.h2,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    h3: {
      fontSize: customStyles.typography.sizes.header.h3,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    h4: {
      fontSize: customStyles.typography.sizes.header.h4,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    h5: {
      fontSize: customStyles.typography.sizes.header.h5,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    h6: {
      fontSize: customStyles.typography.sizes.header.h6,
      lineHeight: customStyles.typography.lineHeight.header,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    subtitle1: {
      fontSize: customStyles.typography.sizes.subheader,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
    },
    subtitle2: {
      fontSize: customStyles.components.tables.body.fontSize,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
    },
    boldSubtitle1: {
      fontSize: customStyles.typography.sizes.subheader,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    boldSubtitle2: {
      fontSize: customStyles.components.tables.body.fontSize,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
    overviewTitle: {
      fontSize: customStyles.typography.sizes.subheader,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.semiBold,
    },
    overviewAmount: {
      fontSize: customStyles.typography.sizes.subheader,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
      color: customStyles.colors.text.tertiary,
    },
    body1: {
      fontSize: customStyles.typography.sizes.body,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
    },
    body2: {
      fontSize: customStyles.typography.sizes.caption,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
      color: customStyles.colors.text.tertiary,
    },
    caption: {
      fontSize: customStyles.typography.sizes.caption,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.regular,
    },
    boldCaption: {
      fontSize: customStyles.typography.sizes.caption,
      lineHeight: customStyles.typography.lineHeight.body,
      fontWeight: customStyles.typography.fontWeight.bold,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: customStyles.typography.fonts.secondary,
          color: customStyles.components.buttons.primary.textColor,
        },
        containedPrimary: {
          "@media (hover: hover)": {
            "&:hover": {
              backgroundColor: customStyles.components.buttons.primary.hoverColor,
            },
          },
          "&:active": {
            backgroundColor: customStyles.components.buttons.primary.hoverColor,
          },
        },
        containedSecondary: {
          "@media (hover: hover)": {
            "&:hover": {
              backgroundColor: customStyles.components.buttons.secondary.hoverColor,
            },
          },
          "&:active": {
            backgroundColor: customStyles.components.buttons.secondary.hoverColor,
          },
        },
        outlinedPrimary: {
          color: customStyles.components.buttons.outlined.color, // set the text color
          borderColor: customStyles.components.buttons.outlined.color, // set the border color the same as text color
          background: customStyles.components.buttons.outlined.background, // set the background color to white
          "@media (hover: hover)": {
            "&:hover": {
              color: customStyles.components.buttons.outlined.hoverColor, // set the hover text color (should be your orange color)
              borderColor: customStyles.components.buttons.outlined.hoverColor, // set the hover border color the same as text color
            },
          },
          "&:active": {
            borderColor: customStyles.components.buttons.primary.hoverColor,
            color: customStyles.components.buttons.primary.hoverColor,
          },
        },
        textPrimary: {
          color: customStyles.components.buttons.text.textColor, // set the text color
          background: "transparent", // set the background color to transparent
          borderColor: "transparent", // no border for text variant
          "@media (hover: hover)": {
            "&:hover": {
              color: customStyles.components.buttons.text.hoverColor, // set the hover text color
              background: "transparent", // keep the background transparent on hover
            },
          },
          "&:active": {
            color: customStyles.components.buttons.text.hoverColor, // set the active text color (if needed)
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: customStyles.components.badge.background,
          color: customStyles.components.badge.textColor,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: customStyles.components.tables.border,
        },
        head: {
          backgroundColor: customStyles.components.tables.header.background,
          color: customStyles.components.tables.header.color,
          fontSize: customStyles.components.tables.header.fontSize,
          fontWeight: customStyles.components.tables.header.fontWeight,
          padding: customStyles.components.tables.header.padding,
        },
        body: {
          color: customStyles.components.tables.body.color,
          borderBottom: customStyles.components.tables.body.borderBottom,
          fontSize: customStyles.components.tables.body.fontSize,
          fontWeight: customStyles.components.tables.body.fontWeight,
          padding: customStyles.components.tables.body.padding,
          "&:hover": {
            backgroundColor: customStyles.components.tables.row.hoverBackground,
          },
        },
        footer: {
          backgroundColor: customStyles.components.tables.footer.background,
          color: customStyles.components.tables.footer.color,
          fontSize: customStyles.components.tables.footer.fontSize,
          fontWeight: customStyles.components.tables.footer.fontWeight,
          padding: customStyles.components.tables.footer.padding,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: customStyles.typography.fonts.secondary,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: customStyles.components.label.color,
          fontSize: customStyles.components.label.fontSize,
          fontWeight: customStyles.components.label.fontWeight,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: customStyles.components.forms.checkbox.border,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: customStyles.spacing.grid,
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: customStyles.spacing.stack,
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: "body1",
      },
    },
    MuiTable: {
      // styleOverrides: {
      //   root: {
      //     borderRadius: customStyles.components.forms.checkbox.borderRadius,
      //   },
      // },
      defaultProps: {
        size: "small",
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.tables.body.background,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: customStyles.components.tables.container.shadow,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.tables.header.background,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: customStyles.components.tables.row.hoverBackground,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.divider.color,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: customStyles.components.iconButton.color,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.snackbar.background,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.pagination.background,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: customStyles.components.textField.textColor,
          },
          "& .MuiFormLabel-root": {
            color: customStyles.components.textField.labelColor,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.list.background,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: customStyles.components.list.item.hoverBackground,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: customStyles.components.list.itemButton.hoverBackground,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.colors.secondary,
        },
      },
    },
    MuiAutocomplete: {},
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: customStyles.components.forms.input.background,
          borderColor: customStyles.components.forms.input.border,
        },
      },
    },
  },
})
