// import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
import { formatAmount } from "../formattingFunctions/formatAmount"
import { entriesToMoney } from "../entriesToMoney"
import { closeShiftInput, closeShiftOutput } from "../../types/closeShift"

export function flattenCloseShift(apiResponse: closeShiftInput): closeShiftOutput {
  const startAmount = formatAmount(entriesToMoney(apiResponse.startAmount))
  const endAmount = formatAmount(entriesToMoney(apiResponse.endAmount))
  const bouncebacks = apiResponse.bouncebacks.toLocaleString()
  const fills = formatAmount(entriesToMoney(apiResponse.fills))
  const purchases = formatAmount(entriesToMoney(apiResponse.purchases))
  const redeems = formatAmount(entriesToMoney(apiResponse.redeems))
  const freePlays = apiResponse.freePlays.toLocaleString()
  const bleeds = formatAmount(entriesToMoney(apiResponse.bleeds))
  const cancels = formatAmount(
    entriesToMoney(apiResponse.cancels !== 0 ? -Math.abs(apiResponse.cancels) : 0)
  )
  const promos = formatAmount(entriesToMoney(apiResponse.promos))

  return {
    ...apiResponse,
    startAmount: startAmount,
    endAmount: endAmount,
    bouncebacks: bouncebacks,
    fills: fills,
    purchases: purchases,
    redeems: redeems,
    freePlays: freePlays,
    bleeds: bleeds,
    cancels: cancels,
    promos: promos,
  }
}
