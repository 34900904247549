export const TransactionTypeAlt = (type: string): string | undefined => {
  const mapping: { [type: string]: string } = {
    "Purchase POS": "Purchase",
    Redeem: "Redeem",
    Bounceback: "Promo",
    Cancel: "Cancel Purchase",
    "Cancel Bounceback": "Cancel Promo",
    "Free Play": "Free Play",
    Promo: "Comp",
    Fill: "Add",
    Bleed: "Remove",
    "Cancel Redeem": "Cancel Redeem",
  }

  return mapping[type]
}

// export const TransactionTypeNames: { [key: number]: string } = {
//   0: "Purchase",
//   1: "Redeem",
//   2: "Promo",
//   3: "Cancel Purchase",
//   4: " Cancel Promo",
//   5: "Free Play",
//   7: "Comp",
//   8: "Add",
//   9: "Remove",
//   15: "Cancel Redeem",
// }

export const TransactionTypeNumbers: { [key: string]: number } = {
  Purchase: 0,
  Redeem: 1,
  Promo: 2,
  "Cancel Purchase": 3,
  "Cancel Promo": 4,
  "Free Play": 5,
  Comp: 7,
  Add: 8,
  Remove: 9,
  "Cancel Redeem": 15,
}

// Default transaction type for unhandled cases.
export const UnknownTransactionType = "Unknown"
