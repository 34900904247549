import { Backdrop, Dialog, DialogContent, DialogTitle, Skeleton, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"

export const ShiftDialogSkeleton = () => {
  const CONFIRM_CURRENT_SHIFT = sessionStorage.getItem("CONFIRM_CURRENT_SHIFT")
  const [open] = useState(CONFIRM_CURRENT_SHIFT !== "true")

  if (CONFIRM_CURRENT_SHIFT === "true") {
    return null
  }

  return (
    <CustomDialog
      open={open}
      title={"Shift"}
      disableHandleClose={true}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton
              sx={{
                backgroundColor: "#dcdcdc",
                fontSize: "min(20px, calc(12px + 0.8vmin))",
              }}
              variant="text"
              width={"100%"}
            />
            <Skeleton
              sx={{
                backgroundColor: "#dcdcdc",
                fontSize: "min(20px, calc(12px + 0.8vmin))",
              }}
              variant="text"
              width={"35%"}
            />
          </Grid>
          <Grid
            container
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            marginTop={3}
          >
            <Grid item xs={3}>
              <Skeleton
                sx={{ backgroundColor: "#dcdcdc", borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={40}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                sx={{ backgroundColor: "#dcdcdc", borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={40}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
