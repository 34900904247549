import React, { useEffect, useRef, useState } from "react"
import { Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { OverviewSummaryItem } from "./OverviewSummaryItem"
import customStyles from "../../styles/tokens.json"
import { ISummaries } from "../../types/ISummaries"
import PrintIcon from "@mui/icons-material/Print"
import { ReceiptContent, ReceiptProps } from "../Print"
import { useReactToPrint } from "react-to-print"
import { useSearchParams } from "react-router-dom"
interface Props {
  summaries?: ISummaries[]
  overviewHeader?: string | React.ReactNode
  accordionTable?: boolean
  hasFilters?: boolean
  receiptName?: string
  cashierName?: string
  fromDateFormatted?: string
  toDateFormatted?: string
  fromTimeFormatted?: string
  toTimeFormatted?: string
  starting?: string
  ending?: string
  totalNumTransactions?: number
}

export const OverviewSummaryGrid: React.FC<Props> = ({
  summaries = [],
  overviewHeader,
  accordionTable = false,
  hasFilters,
  receiptName,
  cashierName,
  fromDateFormatted,
  toDateFormatted,
  fromTimeFormatted,
  toTimeFormatted,
  ending,
  starting,
  totalNumTransactions,
}) => {
  const theme = useTheme()
  const [filteredSummaries, setFilteredSummaries] = useState<ISummaries[]>([])
  const [queryParams] = useSearchParams()

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const ROW_LENGTH = isLargeScreen ? 3 : isMediumScreen ? 3 : isSmallScreen ? 2 : 2
  const receiptRef = useRef<HTMLDivElement>(null)
  const handlePrintPlayer = useReactToPrint({
    content: () => receiptRef.current,
    removeAfterPrint: false,
  })
  function toCamelCase(str: string): string {
    return str
      .replace(/([-_\s][a-z])/gi, ($1: string) => {
        return $1.toUpperCase().replace("-", "").replace("_", "").replace(" ", "")
      })
      .replace(/^[A-Z]/, (firstLetter: string) => {
        return firstLetter.toLowerCase()
      })
  }

  const prepareReceiptData = (summaries: ISummaries[]) => {
    if (!summaries.length) return {}

    const receiptData: Partial<ReceiptProps> & Record<string, any> = {}

    summaries.forEach((summary) => {
      const camelCaseKey = toCamelCase(summary.title)
      receiptData[camelCaseKey] = summary.amountFull
    })

    return receiptData
  }

  useEffect(() => {
    //  Here i'm getting queryParams that we click on
    const filterParams = queryParams.get("title")
    const filterArray = filterParams ? filterParams.split(",") : []

    // Here I Filter the summaries array based on URL parameters
    const filtered = summaries.filter((summary) => filterArray.includes(summary.title))
    setFilteredSummaries(filterArray.length > 0 ? filtered : summaries)
  }, [summaries, queryParams])

  const receiptDataToSend = prepareReceiptData(filteredSummaries)

  return (
    <Paper
      elevation={accordionTable ? 0 : 1}
      sx={{
        borderRadius: accordionTable ? 0 : isSmallScreen ? customStyles.borders.radius : 0,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingTop: "min(14px,calc(2px + 1.2vmin))",
          paddingBottom: !isSmallScreen
            ? "min(14px,calc(4px + 1.2vmin))"
            : "min(14px,calc(2px + 1.2vmin))",
        }}
      >
        <Grid
          item
          xs={9}
          sm={9}
          md={8}
          lg={8}
          xl={9}
          sx={{ display: "flex", justifyContent: "flex-start", paddingLeft: 2 }}
        >
          <Typography
            variant={"overviewTitle"}
            sx={{
              paddingLeft: 2,
              fontSize: "min(16px,calc(8px + 1.5vmin))",
              whiteSpace: "nowrap",
            }}
          >
            {overviewHeader}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={4}
          lg={4}
          xl={3}
          sx={{ display: "flex", justifyContent: "flex-end", paddingRight: 2 }}
        >
          <IconButton onClick={handlePrintPlayer} sx={{ padding: 0 }}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        {Array.from({ length: Math.ceil(summaries.length / ROW_LENGTH) }, (_, i) =>
          summaries.slice(i * ROW_LENGTH, i * ROW_LENGTH + ROW_LENGTH)
        ).map((rowSummaries, rowIndex) => (
          <Grid
            key={rowIndex}
            justifyContent="space-between"
            alignItems="center"
            container
            item
            spacing={0}
          >
            {rowSummaries.map((summary, index) => (
              <Grid
                key={index}
                item
                xs={6}
                sm={6}
                md={3}
                lg={2}
                sx={{
                  ...(isMediumScreen && { marginLeft: 5 }),
                  ...(isLargeScreen && { marginLeft: 2, marginRight: 2 }),
                  ...(isLargeScreen === false && { paddingLeft: 2 }),
                }}
              >
                <OverviewSummaryItem
                  totalNumTransactions={totalNumTransactions}
                  hasFilter={hasFilters}
                  title={summary.title}
                  amountFull={summary.amountFull}
                  count={summary.count}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "none" }}>
        <ReceiptContent
          ref={receiptRef}
          {...receiptDataToSend}
          receiptName={receiptName}
          cashierName={cashierName}
          fromDateFormatted={fromDateFormatted}
          toDateFormatted={toDateFormatted}
          fromTimeFormatted={fromTimeFormatted}
          toTimeFormatted={toTimeFormatted}
          starting={starting}
          ending={ending}
        />
      </div>
    </Paper>
  )
}
