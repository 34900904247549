import React, { ReactElement } from "react"
import { TableRow, TableCell, useMediaQuery, useTheme, Skeleton } from "@mui/material"
import { TableLayout } from "../TableLayout"
import customStyles from "../../styles/tokens.json"

interface TableConfig {
  small: string[]
  medium: string[]
  large: string[]
}

type Alignment = "center" | "left" | "right" | "justify" | "inherit"
type Size = "xSmall" | "small" | "big" | "auto"
export interface AlignmentConfig {
  small: Alignment[]
  medium: Alignment[]
  large: Alignment[]
}
export interface SizeConfig {
  small: Size[]
  medium: Size[]
  large: Size[]
}

interface TableSkeletonProps {
  rows?: number
  headCellsConfig: TableConfig
  alignmentsConfig: AlignmentConfig
  sizeStylesConfig: SizeConfig
}
const TableSkeleton: React.FC<TableSkeletonProps> = ({
  rows = 10,
  headCellsConfig,
  alignmentsConfig,
  sizeStylesConfig,
}): ReactElement => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const getConfig = () => {
    if (isSmallScreen) {
      return {
        headCells: headCellsConfig.small,
        alignments: alignmentsConfig.small,
        sizeStyles: sizeStylesConfig.small,
      }
    }
    if (isMediumScreen) {
      return {
        headCells: headCellsConfig.medium,
        alignments: alignmentsConfig.medium,
        sizeStyles: sizeStylesConfig.medium,
      }
    }
    return {
      headCells: headCellsConfig.large,
      alignments: alignmentsConfig.large,
      sizeStyles: sizeStylesConfig.large,
    }
  }

  const { headCells, alignments, sizeStyles } = getConfig()
  const widths = sizeStyles.map(
    (size) =>
      customStyles.components.tables.size[size as keyof typeof customStyles.components.tables.size]
  )
  return (
    <TableLayout
      hiddenPagination={true}
      headCells={headCells}
      headCellsAlign={alignments}
      totalNumberOfRows={0}
      rowsPerPage={10}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onRowsPerPageChange={() => {}}
      page={0}
      rows={Array(rows).fill({})}
      renderRow={() => (
        <TableRow>
          {headCells.map((_, idx) => (
            <TableCell key={idx} style={{ width: widths[idx], padding: "6px 12px" }}>
              <Skeleton
                style={{ backgroundColor: "#0000000f" }}
                variant="rectangular"
                height={"21px"}
                animation="wave"
              />
            </TableCell>
          ))}
        </TableRow>
      )}
    />
  )
}

export default TableSkeleton
