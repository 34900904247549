import React, { useEffect, useState } from "react"
import {
  SummarySection,
  SummaryItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../styles/styledComponents/AccordionStyles"
import { ShiftLogShiftOutput } from "../../types/IShift"
import { OverviewSummaryGrid } from "../OverviewSummary/OverviewSummaryGrid"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Collapse, Stack, Typography } from "@mui/material"
import { OverviewSummaryGridSkeleton } from "../../layouts/Skeletons/OverviewSummarySkeleton"

interface AccordionItemProps {
  shift: ShiftLogShiftOutput
  isSmallScreen: boolean
  isMediumScreen: boolean
  isXSmallScreen: boolean
  isLargeScreen: boolean
}

const FONT_SIZE = "min(16px, calc(7px + 1vmin))"

const AccordionItemMemo: React.FC<AccordionItemProps> = ({
  shift,
  isSmallScreen,
  isMediumScreen,
  isXSmallScreen,
  isLargeScreen,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? shift.id : false)

    if (isExpanded) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }

  const renderTime = (date: string, time: string, condition: boolean) => (
    <>
      <Typography style={{ fontSize: FONT_SIZE }}>
        {date} {condition && time}
      </Typography>
      {!condition && (
        <Typography className="hidden sm:block" style={{ fontSize: FONT_SIZE }}>
          {time}
        </Typography>
      )}
    </>
  )
  const [shouldRenderGrid, setShouldRenderGrid] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (expanded === shift.id) {
      setShouldRenderGrid(true)
    } else {
      timer = setTimeout(() => {
        setShouldRenderGrid(false)
      }, 300)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [expanded, shift.id])

  return (
    <Accordion
      key={shift.id}
      expanded={expanded === shift.id}
      onChange={handleAccordionChange}
      sx={{ flexBasis: isSmallScreen ? "100%" : {} }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#DEE2E6",
          margin: isSmallScreen ? 0 : {},
          padding: isSmallScreen ? 0 : {},
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <SummarySection sx={{ flexBasis: isSmallScreen ? "97%" : "100%" }}>
          <SummaryItem
            sx={{
              textAlign: "left",
              minWidth: "68px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              flexBasis: isSmallScreen ? "24%" : "22%",
              alignSelf: "center",
            }}
          >
            <Typography style={{ paddingRight: "2px", fontSize: FONT_SIZE }}>
              {shift.cashierName}
            </Typography>
          </SummaryItem>
          <SummaryItem
            className="hidden sm:block"
            sx={{ textAlign: "left", minWidth: "68px", flexBasis: "20%" }}
          >
            {renderTime(shift.startingDate, shift.startingTime, isLargeScreen)}
          </SummaryItem>
          <SummaryItem
            className="hidden sm:block"
            sx={{ textAlign: "left", minWidth: "68px", flexBasis: "20%" }}
          >
            {renderTime(shift.endingDate, shift.endingTime, isLargeScreen)}
          </SummaryItem>
          <SummaryItem
            className="block sm:hidden"
            sx={{ textAlign: "left", minWidth: "30px", flexBasis: isSmallScreen ? "19%" : "20%" }}
          >
            <Stack direction="column" spacing={0}></Stack>
            <Typography style={{ fontSize: FONT_SIZE }}>
              {shift.startingDate}
              <Typography style={{ fontSize: FONT_SIZE }}>{shift.startingTime}</Typography>
            </Typography>

            <Stack direction="column" spacing={0}></Stack>
          </SummaryItem>
          <SummaryItem
            className="block sm:hidden"
            sx={{ textAlign: "left", minWidth: "30px", flexBasis: isSmallScreen ? "19%" : "20%" }}
          >
            <Stack direction="column" spacing={0}></Stack>

            <Typography className="block sm:hidden" style={{ fontSize: FONT_SIZE }}>
              {shift.endingDate}
              <Typography style={{ fontSize: FONT_SIZE }}>{shift.endingTime}</Typography>
            </Typography>
            <Stack direction="column" spacing={0}></Stack>
          </SummaryItem>
          <SummaryItem
            className="hidden md:block"
            sx={{ textAlign: "right", minWidth: "68px", flexBasis: isMediumScreen ? "27%" : "19%" }}
          >
            <Typography style={{ fontSize: FONT_SIZE }}>{shift.startAmountFull}</Typography>
          </SummaryItem>
          <SummaryItem
            sx={{ textAlign: "right", minWidth: "68px", flexBasis: isMediumScreen ? "25%" : "19%" }}
          >
            <Stack direction="column" spacing={0}>
              <Typography style={{ fontSize: FONT_SIZE }}>
                {isMediumScreen && shift.startAmountFull}
              </Typography>
              <Typography style={{ fontSize: FONT_SIZE }}>{shift.endAmountFull}</Typography>
            </Stack>
          </SummaryItem>
        </SummarySection>
      </AccordionSummary>
      <AccordionDetails>
        <Collapse in={shouldRenderGrid} timeout={500}>
          {shouldRenderGrid && (
            <OverviewSummaryGrid
              summaries={shift.summaries}
              accordionTable={true}
              receiptName={"Shift"}
              cashierName={shift.cashierName}
              fromDateFormatted={shift.startingDate}
              toDateFormatted={shift.endingDate}
              fromTimeFormatted={shift.startingTime}
              toTimeFormatted={shift.endingTime}

              // overviewHeader={"Shift Summaries"}
            />
          )}
        </Collapse>
      </AccordionDetails>
    </Accordion>
  )
}

export const MemoizedAccordionItem = React.memo(AccordionItemMemo)
