import React from "react"
import { CircularProgress } from "@mui/material"

export const LoadingComponent: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress color="primary" size={50} />
    </div>
  )
}
