import React from "react"
import { TableCell } from "@mui/material"

interface DrawerTableCellProps {
  children: React.ReactNode
  textAlign?: "right" | "left"
  whiteSpaceNowrap?: "nowrap" | "normal"
  verticalAlign?: "top"
}

export const DrawerTableCell: React.FC<DrawerTableCellProps> = ({
  children,
  textAlign = "left",
  whiteSpaceNowrap,
  verticalAlign,
}) => {
  return (
    <TableCell
      sx={{
        whiteSpaceNowrap,
        verticalAlign: verticalAlign,
        textAlign: textAlign,
        whiteSpace: whiteSpaceNowrap,
      }}
    >
      {children}
    </TableCell>
  )
}
