import { useEffect, useState } from "react"
import { useOidc } from "@axa-fr/react-oidc"
import { Outlet } from "react-router-dom"

export const NotAuthenticated = () => {
  const { login, isAuthenticated } = useOidc()
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const loginAsync = async () => {
      console.log("loginAsync")
      try {
        if (!isAuthenticated) {
          await login("/")
        }
      } catch (e) {
        setError(e as Error)
      }
    }
    loginAsync()
  }, [isAuthenticated, login])

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return <Outlet />
}

export default NotAuthenticated
