import { Skeleton, Grid, useMediaQuery, useTheme } from "@mui/material"

export function HomeSkeleton() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className="max-w-xl mx-auto flex flex-col items-center mb-10 mt-2 px-6">
      <div>
        <Skeleton variant="rectangular" width="min(500px,calc(200px + 15vmin))" height={35} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "18px",
          paddingTop: "8px",
          width: "100%",
        }}
      >
        <Skeleton variant="text" width="60%" height={25} />
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Grid
          container
          spacing={1}
          marginTop={0}
          paddingX={isSmallScreen ? "calc(-90px + 30vmin)" : 5}
          width={isSmallScreen ? {} : "calc(350px + 40vmin)"}
          style={{ minWidth: isSmallScreen ? "initial" : "calc(350px + 40vmin)" }}
        >
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <Grid key={index} item xs={6} sm={4}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="calc(60px + 20vmin)"
                  sx={{ borderRadius: "4px" }}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  )
}
