import React from "react"
import { Typography } from "@mui/material"
import customStyles from "../tokens.json"

interface DrawerTableTypographyProps {
  children: React.ReactNode
  size?: "big" | "small" | "xSmall" | "medium" | "xBig"
  overflowHidden?: boolean
  variant: "primary" | "secondary"
  textOverflow?: "ellipsis" | "clip"
  width?: string
  whiteSpace?: "nowrap" | "normal"
}

export const DrawerTableTypography: React.FC<DrawerTableTypographyProps> = ({
  children,
  size = "small",
  overflowHidden = true,
  variant,
  textOverflow = "ellipsis",
  width,
  whiteSpace = "nowrap",
}) => {
  const typographyVariant = variant === "primary" ? "subtitle2" : "body2"

  const sizeToWidthMap = {
    xSmall: customStyles.components.tables.size.xSmall,
    small: customStyles.components.tables.size.small,
    medium: customStyles.components.tables.size.medium,
    big: customStyles.components.tables.size.big,
    xBig: customStyles.components.tables.size.xBig,
  }

  const widthValue = sizeToWidthMap[size]

  return (
    <Typography
      variant={typographyVariant}
      sx={{
        width: widthValue,
        maxWidth: widthValue,
        overflow: overflowHidden ? "hidden" : "visible",
        textOverflow: textOverflow,
        whiteSpace: whiteSpace,
        wordWrap: "break-word",
        overflowWrap: "break-word",
      }}
    >
      {children}
    </Typography>
  )
}
