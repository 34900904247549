import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"

import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import { type SvgIconTypeMap, type SvgIconProps } from "@mui/material"
import { type OverridableComponent } from "@mui/material/OverridableComponent"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
// import AssignmentIcon from "@mui/icons-material/Assignment"
import PeopleIcon from "@mui/icons-material/People"

interface Link {
  title: string
  href?: string
  color?: "orange" | "success" | "warning" | "info" | "primary" | "secondary" | "error"
  onclick?: () => void
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string
  }
  iconProps?: SvgIconProps
  className?: string
  activePaths: string[]
}

export const homeButtons = (signOutHandler: () => void): Link[] => {
  const userDataString = sessionStorage.getItem("authShift")
  const userData = userDataString ? JSON.parse(userDataString) : null
  const classification = userData?.response?.classification
  const allLinks: Link[] = [
    {
      title: "Homepage",
      href: "/home",
      Icon: HomeOutlinedIcon,
      activePaths: ["/home"],
    },
    {
      title: "Drawer",
      href: "/drawer?title=",
      Icon: DashboardOutlinedIcon,
      color: "success",
      activePaths: ["/drawer"],
    },
    {
      title: "Accounts",
      href: "/accounts",
      Icon: PeopleIcon,
      color: "info",
      activePaths: ["/accounts", "/account-details"],
    },
    {
      title: "Transactions Report",
      href: "/transactions?title=",
      Icon: AssessmentOutlinedIcon,
      color: "orange",
      activePaths: ["/transactions"],
    },
    {
      title: "Shift Logs",
      href: "/shifts",
      Icon: TrackChangesIcon,
      color: "warning",
      activePaths: ["/shifts"],
    },
    // {
    //   title: "Training",
    //   href: "/training",
    //   Icon: AssignmentIcon,
    //   color: "secondary",
    //   activePaths: ["/training"],
    // },
    {
      title: "Log Out",
      onclick: signOutHandler,
      Icon: LogoutOutlinedIcon,
      color: "error",
      activePaths: [],
    },
  ]
  if (classification === 1) {
    return allLinks
  } else {
    return allLinks.filter((link) =>
      ["Homepage", "Transactions Report", "Shift Logs", "Log Out"].includes(link.title)
    )
  }
}
