import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { userApi } from "./user/user.api"

export const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
