import { Box, Typography } from "@mui/material"
import BackButton from "./BackButton" // Assuming you have this component

interface HeaderProps {
  title: string
  display?: string
  justifyContent?: string
  gridTemplateColumns?: string
  withBackButton?: boolean
}

export function Header(props: HeaderProps) {
  const {
    title,
    display = "flex",
    justifyContent = "center",
    gridTemplateColumns,
    withBackButton = false,
  } = props

  return (
    <Box
      bgcolor="primary.main"
      color="white"
      p={0.5}
      mb={2}
      display={display}
      justifyContent={justifyContent}
      gridTemplateColumns={gridTemplateColumns}
      alignItems="center"
    >
      {withBackButton && <BackButton />}
      <Typography variant="h5" align="center">
        {title}
      </Typography>
    </Box>
  )
}
