import React from "react"
import { TableRow, useMediaQuery, useTheme } from "@mui/material"
import { TableLayout } from "../../layouts/TableLayout"
import { DrawerTableTypography } from "../../styles/styledComponents/DrawerTableTypography"
import { DrawerTableCell } from "../../styles/styledComponents/DrawerTableCell"
import { FormattedUserResponse } from "../../utils/flatteningFunctions/flattenSearchUser"
import { formatPhoneNumber } from "../../utils/formattingFunctions/formatPhoneNumber"

type Props = {
  totalUsers: number
  onPageChange: (newPage: number) => void
  rowsPerPage: number
  page: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  users: FormattedUserResponse[]
  handleUserClick: (player: FormattedUserResponse) => void
}

export const AccountsTable: React.FC<Props> = ({
  totalUsers,
  onPageChange,
  rowsPerPage,
  page,
  onRowsPerPageChange,
  users,
  handleUserClick,
}) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const headCells = isSmallScreen
    ? ["Account", "Name", "Contact", "Driver's License"]
    : isMediumScreen
    ? ["Account", "Name", "Email", "Phone", "Driver's License"]
    : ["Account", "Name", "Email", "Phone", "Driver's License"]

  const headCellsAlign: ("center" | "inherit" | "left" | "right" | "justify")[] = isMediumScreen
    ? ["left", "left", "left", "left", "left"]
    : ["left", "left", "left"]

  return (
    <TableLayout
      maxHeight={340}
      headCells={headCells}
      headCellsAlign={headCellsAlign}
      showRowsPerPage={true}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      rowsPerPage={rowsPerPage}
      page={page}
      totalNumberOfRows={totalUsers}
      rows={users}
      renderRow={(player) => {
        return (
          <TableRow
            sx={{ cursor: "pointer" }}
            key={player.id}
            onClick={() => handleUserClick(player)}
          >
            <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
              <DrawerTableTypography size="small" variant="primary">
                {player.userName ? player.userName : player.playerNumber}
              </DrawerTableTypography>
            </DrawerTableCell>
            <DrawerTableCell verticalAlign="top">
              <DrawerTableTypography whiteSpace="normal" size="medium" variant="primary">
                {player.firstName} {player.lastName}
              </DrawerTableTypography>
            </DrawerTableCell>
            {!isSmallScreen && (
              <DrawerTableCell verticalAlign="top">
                <DrawerTableTypography whiteSpace="normal" size="big" variant="primary">
                  {player.email}
                </DrawerTableTypography>
              </DrawerTableCell>
            )}
            {!isSmallScreen && (
              <DrawerTableCell whiteSpaceNowrap="nowrap" verticalAlign="top">
                <DrawerTableTypography size="small" variant="primary">
                  {formatPhoneNumber(player.phone)}
                </DrawerTableTypography>
              </DrawerTableCell>
            )}
            {isSmallScreen && (
              <DrawerTableCell verticalAlign="top">
                <DrawerTableTypography whiteSpace="normal" size="big" variant="primary">
                  {player.email}
                </DrawerTableTypography>
                <DrawerTableTypography size="big" variant="secondary">
                  {formatPhoneNumber(player.phone)}
                </DrawerTableTypography>
              </DrawerTableCell>
            )}
            <DrawerTableCell verticalAlign="top">
              <DrawerTableTypography whiteSpace="normal" size="medium" variant="primary">
                {player.driverLicense}
              </DrawerTableTypography>
            </DrawerTableCell>
          </TableRow>
        )
      }}
    />
  )
}
