import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

export function formatDateAndTime(timestamp: string, showFullYear = false, useUTC = false) {
  let date
  let month, day, year, hours, minutes

  if (useUTC) {
    date = dayjs(timestamp).utc()
    month = date.format("MMM")
    day = date.format("DD")
    year = showFullYear ? date.format("YYYY") : `'${date.format("YY")}`
    hours = date.hour()
    minutes = date.minute()
  } else {
    date = new Date(timestamp)
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    month = monthNames[date.getMonth()]
    day = date.getDate().toString().padStart(2, "0")
    year = showFullYear
      ? date.getFullYear().toString()
      : `'${date.getFullYear().toString().slice(-2)}`
    hours = date.getHours()
    minutes = date.getMinutes()
  }

  const amOrPm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12 || 12 // Convert to 12-hour format
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`

  const formattedDate = `${month} ${day}, ${year}`
  return { formattedDate, formattedTime }
}
