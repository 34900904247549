import React, { useState } from "react" // Import useState from React

export function useDebounce(value: string, delay: number) {
  // Provide types for value and delay
  const [debouncedValue, setDebouncedValue] = useState(value)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
