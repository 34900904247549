import { formatAmount } from "../formattingFunctions/formatAmount"
import { formatDateAndTime } from "../formattingFunctions/formatDateAndTime"
import { UserResponse, SearchAPIResponse } from "../../types/TSearchUsers"

export type FormattedUserResponse = Omit<
  UserResponse,
  "unrestrictedBalance" | "potentialBalance" | "productBalance" | "createdOn" | "lastBonusGame"
> & {
  unrestrictedBalance: string
  potentialBalance: string
  productBalance: string
  createdOn: { formattedDate: string; formattedTime: string }
  lastBonusGame: { formattedDate: string; formattedTime: string }
}

export type FormattedSearchAPIResponse = {
  response: FormattedUserResponse[]
  total: number
}

export function flattenSearchUser(input: SearchAPIResponse): FormattedSearchAPIResponse {
  const response = input.response.map((user: UserResponse): FormattedUserResponse => {
    const unrestrictedBalance = formatAmount(user.unrestrictedBalance)
    const potentialBalance = formatAmount(user.potentialBalance)
    const productBalance = formatAmount(user.productBalance)
    const { formattedDate: createdOnDate, formattedTime: createdOnTime } = formatDateAndTime(
      user.createdOn
    )
    const { formattedDate: lastBonusGameDate, formattedTime: lastBonusGameTime } =
      formatDateAndTime(user.lastBonusGame)

    return {
      ...user,
      unrestrictedBalance,
      potentialBalance,
      productBalance,
      createdOn: { formattedDate: createdOnDate, formattedTime: createdOnTime },
      lastBonusGame: { formattedDate: lastBonusGameDate, formattedTime: lastBonusGameTime },
    }
  })

  return {
    response,
    total: input.total,
  }
}
