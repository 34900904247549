export const snackbarMessages = {
  Field_REQUIRED: "This field is required.",
  FIRST_NAME_REQUIRED: "First Name is required.",
  LAST_NAME_REQUIRED: "Last Name is required.",
  PIN_REQUIRED: "PIN is required.",
  PHONE_REQUIRED: "Phone is required.",
  EMAIL_REQUIRED: "Email is required.",
  DRIVERS_LICENSE_REQUIRED: "Driver's License is required.",
  USER_CREATION_FAILED: "Failed to create user.",
  USER_CREATION_SUCCESS: "User created successfully.",
  INVALID_NAME: "Name can only contain letters, hyphens, and apostrophes.",
  INVALID_NAME_LENGTH: "Name should be between 2 to 25 characters.",
  INVALID_PIN: "PIN can only contain numbers.",
  INVALID_PIN_LENGTH: "PIN should be between 6 to 8 digits long.",
  SIMPLE_PIN: "Avoid simple PIN sequences.",
  INVALID_PHONE: "Invalid phone number format.",
  INVALID_DRIVERS_LICENSE: "Driver's License can only contain numbers and letters.",
  INVALID_EMAIL_SPACE: "Email can not contain spaces.",
  INVALID_EMAIL_FORMAT: "Invalid email format.",
  DISPOSABLE_EMAIL: "Please use a non-disposable email address.",
  INVALID_DRIVERS_LICENSE_LENGTH: "Driver's license should be between 5-30 characters.",
}
