import React from "react"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc"

export const SessionLostComponent: React.FC = () => {
  const { logout, isAuthenticated } = useOidc()
  const { accessToken } = useOidcAccessToken()

  const loginHandler = () => {
    sessionStorage.removeItem("authShift")
    sessionStorage.removeItem("CONFIRM_CURRENT_SHIFT")
    localStorage.removeItem("selectedUser")
    sessionStorage.removeItem("HAS_PRINTED")
    if (isAuthenticated) {
      if (accessToken !== null && accessToken !== undefined) {
        console.log("accessToken not null, logout fired", accessToken)
        logout("/")
      } else {
        console.log("accessToken null, winodw href fired", accessToken)
        window.location.href = "/"
      }
    } else {
      console.log("not authenticated, else window.href fired", isAuthenticated)
      window.location.href = "/"
    }
  }

  return (
    <CustomDialog
      open={true}
      title="Session Expired"
      content="Your session has expired, please login again."
      primaryActionText="LOGIN"
      onPrimaryAction={loginHandler}
      handleClose={loginHandler}
    />
  )
}
