import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"

import {
  useGetShiftTransactionsQuery,
  useRemoveMutation,
  useAddMutation,
} from "../../redux/user/user.api"

import AddDialog from "../../components/Drawer/AddDialog"
import RemoveDialog from "../../components/Drawer/RemoveDialog"
import { DrawerButtonGrid } from "../../components/Drawer/DrawerButtonGrid"
import { TransactionTable } from "../../components/Drawer/TransactionTable"
import { OverviewSummaryGrid } from "../../components/OverviewSummary/OverviewSummaryGrid"

import { DrawerBox } from "../../styles/styledComponents/DrawerBox"
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "../../styles/styledComponents/CustomAccordion"
import customStyles from "../../styles/tokens.json"

import { TransactionOutput } from "../../types/ITransactions"
import { flattenDrawer } from "../../utils/flatteningFunctions/flattenDrawer"
import { DrawerButtonProps } from "../../types/IDrawerButtonProps"
import { ISummaries } from "../../types/ISummaries"
import { asyncErrorHandler } from "../../utils/errorHandler"
import { OverviewSummaryGridSkeleton } from "../../layouts/Skeletons/OverviewSummarySkeleton"
import { ErrorLayout } from "../../layouts/ErrorLayout"
import { Header } from "../../components/Header"
import { useSearchParams } from "react-router-dom"
import { TransactionTypeNumbers } from "../../constants/transactionTypes"
import { Box } from "@mui/material"
import TableSkeleton, { AlignmentConfig, SizeConfig } from "../../layouts/Skeletons/TableSkeleton"
import { useCustomSnackbar } from "../../components/multiSnackbar"

export const Drawer: React.FC = () => {
  const [transactions, setTransactions] = useState<TransactionOutput[]>([])
  const [summaries, setSummaries] = useState<ISummaries[]>([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [refetchKey, setRefetchKey] = useState(0)
  const [queryParameters, setQueryParameters] = useState({
    transactionTypes: [],
    page: 1,
    perPage: 10,
  })
  const [loading, setLoading] = useState(true)
  const [confirmPrintAddModal, setConfirmPrintAddModal] = useState(false)
  const [confirmPrintRemoveModal, setConfirmPrintRemoveModal] = useState(false)
  const [bleed, saveBleed] = useState(null)
  const [apiLoading, setApiLoading] = useState(false)

  const [applyingFilters, setApplyingFilters] = useState(false)

  const [queryParams] = useSearchParams()
  const showSnackbar = useCustomSnackbar()
  const handlePageChange = (newPage: number) => {
    setPage(newPage)

    setQueryParameters((prev) => ({
      ...prev,
      page: newPage + 1,
    }))
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(0)

    setRowsPerPage(newRowsPerPage)
    setQueryParameters((prev) => ({
      ...prev,
      page: 1,
      perPage: newRowsPerPage,
    }))
    setRefetchKey((prevKey) => prevKey + 1) // Force a refetch
  }

  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
  const currentShiftID = authShift?.currentShift?.id
  const currentShiftStarting = authShift?.currentShift?.starting

  const { data, isFetching, error } = useGetShiftTransactionsQuery({
    shiftId: currentShiftID,
    transactionTypes: queryParameters.transactionTypes,
    page: queryParameters.page,
    perPage: queryParameters.perPage,
    refetchKey,
  })

  const [openAdd, setOpenAdd] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [addAmount, setAddAmount] = useState("")
  const [removeAmount, setRemoveAmount] = useState("")
  const [addMoney, { isSuccess: addSuccess }] = useAddMutation()
  const [removeMoney, { isSuccess: removeSuccess }] = useRemoveMutation()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const handleClose = (
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
    clearValue: () => void
  ) => {
    setOpenDialog(false)
    clearValue()
    saveBleed(null)
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleRemoveClick = () => {
    setOpenRemove(true)
  }

  const handleChangeAmount = (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setValue(value)
  }

  const handleConfirmAdd = asyncErrorHandler(async () => {
    try {
      setApiLoading(true)

      const apiObject = {
        amount: Number(addAmount),
        shiftId: currentShiftID,
        transactionTypes: [],
        page: 1,
        perPage: 20,
      }

      const addResponse = await addMoney(apiObject).unwrap()
      const updatedAuthShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
      updatedAuthShift.currentShift = addResponse?.shiftPOCO
      sessionStorage.setItem("authShift", JSON.stringify(updatedAuthShift))
      setRefetchKey((prevKey) => prevKey + 1)
      setConfirmPrintAddModal(true)
      setOpenAdd(false)
    } finally {
      setApiLoading(false)
    }
  }, showSnackbar)

  const handleConfirmRemove = asyncErrorHandler(async () => {
    try {
      setApiLoading(true)

      const apiObject = {
        amount: Number(removeAmount),
        shiftId: currentShiftID,
        transactionTypes: [],
        page: 1,
        perPage: 20,
      }

      const removeResponse = await removeMoney(apiObject).unwrap()
      const updatedAuthShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
      updatedAuthShift.currentShift = removeResponse?.shiftPOCO
      sessionStorage.setItem("authShift", JSON.stringify(updatedAuthShift))
      saveBleed(removeResponse)

      setConfirmPrintRemoveModal(removeResponse.bleedResponse?.redeemed === false ? false : true)
      if (removeResponse.bleedResponse?.redeemed === false) {
        setConfirmPrintRemoveModal(false)
        setOpenRemove(true)
      } else {
        setConfirmPrintRemoveModal(true)
        setOpenRemove(false)
      }

      setRefetchKey((prevKey) => prevKey + 1)
    } finally {
      setApiLoading(false)
    }
  }, showSnackbar)

  useEffect(() => {
    if (data) {
      const result = flattenDrawer(data)

      setTransactions(result.transactions)
      setSummaries(result.summaries)
      setLoading(false)
      setApplyingFilters(false)
    }
  }, [data])

  const drawerButtons: DrawerButtonProps[] = [
    { id: "accounts", title: "Accounts", color: "info", href: "/accounts" },
    // { id: "transactions", title: "Transactions", color: "warning", href: "/transactions" },
    { id: "add", title: "Add", color: "orange", onClick: handleAddClick },
    { id: "remove", title: "Remove", color: "error", onClick: handleRemoveClick },
  ]

  useEffect(() => {
    setApplyingFilters(true)
    const titleFromParams = queryParams.get("title")
    const titleFromParamsArray = titleFromParams ? titleFromParams.split(",") : []
    const numbersFromParamsArray = titleFromParamsArray
      .map((title) => {
        if (title === "Adjustment") {
          return [
            TransactionTypeNumbers["Cancel Purchase"],
            TransactionTypeNumbers["Cancel Redeem"],
          ]
        }
        return TransactionTypeNumbers[title]
      })
      .flat()
    setTransactions([])
    setQueryParameters((prevParams: any) => ({
      ...prevParams,
      page: 1,
      transactionTypes: numbersFromParamsArray,
    }))
    return () => {
      setIsAccordionExpanded(true)
      setPage(0)
    }
  }, [queryParams])

  const handleAccordionToggle = () => {
    setIsAccordionExpanded(!isAccordionExpanded)
  }
  const headCellsConfig = {
    small: ["Account", "Date", "Tx.", "Amount"],
    medium: ["Account", "Name", "Date", "Transaction", "Amount"],
    large: ["Account", "Date", "Transaction", "Amount"],
  }
  const sizeStylesConfig: SizeConfig = {
    small: ["small", "xSmall", "auto", "small"],
    medium: ["small", "big", "big", "small", "big", "small"],
    large: ["small", "small", "auto", "small"],
  }

  const alignmentsConfig: AlignmentConfig = {
    small: ["left", "left", "left", "right"],
    medium: ["left", "left", "left", "left", "right"],
    large: ["left", "left", "left", "right"],
  }
  const balance = summaries[8]?.amountFull
  return (
    <ErrorLayout error={error}>
      <>
        <Header title="Drawer" />
        <DrawerBox marginBottom={0} marginLeft={0} marginRight={0} marginTop={0}>
          <DrawerBox marginBottom={0} marginTop={0}>
            <DrawerButtonGrid buttons={drawerButtons} balance={balance} />
          </DrawerBox>
          <DrawerBox marginBottom={0} marginLeft={0} marginRight={0} marginTop={2}>
            <Box
              style={{
                opacity: applyingFilters ? 0.3 : 1,
                pointerEvents: applyingFilters ? "none" : "auto",
              }}
            >
              {loading ? (
                <OverviewSummaryGridSkeleton itemCount={9} />
              ) : (
                <OverviewSummaryGrid
                  hasFilters={true}
                  summaries={summaries}
                  overviewHeader={"Current Shift"}
                  receiptName={"Current Shift"}
                  starting={currentShiftStarting}
                  totalNumTransactions={data?.total}
                />
              )}
            </Box>
          </DrawerBox>
          <DrawerBox marginTop={2}>
            <StyledAccordion expanded={isAccordionExpanded} onChange={handleAccordionToggle}>
              <StyledAccordionSummary aria-controls="transaction-content" id="transaction-header">
                <Typography variant="body1" fontWeight={customStyles.typography.fontWeight.bold}>
                  Details
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                {applyingFilters || isFetching ? (
                  <TableSkeleton
                    rows={rowsPerPage}
                    headCellsConfig={headCellsConfig}
                    alignmentsConfig={alignmentsConfig}
                    sizeStylesConfig={sizeStylesConfig}
                  />
                ) : (
                  <TransactionTable
                    users={transactions}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    totalNumberOfRows={data.total}
                  />
                )}
              </StyledAccordionDetails>
            </StyledAccordion>
          </DrawerBox>

          <AddDialog
            open={openAdd}
            handleClose={() => handleClose(setOpenAdd, () => setAddAmount(""))}
            amount={addAmount}
            handleChangeAmount={(e) => handleChangeAmount(e.target.value, setAddAmount)}
            handleConfirm={handleConfirmAdd}
            confirmPrintAddModal={confirmPrintAddModal}
            setConfirmPrintAddModal={setConfirmPrintAddModal}
            balance={summaries[8]?.amountFull}
            isLoading={apiLoading}
          />

          <RemoveDialog
            bleed={bleed}
            open={openRemove}
            handleClose={() => handleClose(setOpenRemove, () => setRemoveAmount(""))}
            amount={removeAmount}
            handleChangeAmount={(e) => handleChangeAmount(e.target.value, setRemoveAmount)}
            handleConfirm={handleConfirmRemove}
            confirmPrintRemoveModal={confirmPrintRemoveModal}
            setConfirmPrintRemoveModal={setConfirmPrintRemoveModal}
            balance={summaries[8]?.amountFull}
            isLoading={apiLoading}
          />
        </DrawerBox>
      </>
    </ErrorLayout>
  )
}
