import React from "react"
import { Grid, useTheme, useMediaQuery } from "@mui/material"
import DrawerButton from "./DrawerButton"
import { DrawerButtonProps } from "../../types/IDrawerButtonProps"

export const DrawerButtonGrid: React.FC<{ balance: string; buttons: DrawerButtonProps[] }> = ({
  buttons,
  balance,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  console.log(balance)
  return (
    <Grid container spacing={1} sx={isSmallScreen ? { paddingX: 1 } : {}}>
      {buttons.map((button) => (
        <Grid
          key={button.id}
          item
          xs={4}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DrawerButton balance={balance} {...button} />
        </Grid>
      ))}
    </Grid>
  )
}
