import React, { useEffect } from "react"
import { type RouteObject, useRoutes, useNavigate } from "react-router-dom"
import {
  Home,
  Drawer,
  Accounts,
  AccountDetails,
  Shifts,
  CashierManagement,
  // Training,
  Transactions,
} from "../pages"
import { NotFound } from "../pages/NotFound"
import { NavLayout } from "../layouts/NavLayout"
import { LoadingComponent } from "../callbacks/LoadingComponent"

interface ComponentProps {
  openFiltersHandler?: () => void
}

interface RoutesContainerProps {
  classification?: number
}

const layoutRouter = (
  Component: React.ComponentType<ComponentProps>,
  path: string
): RouteObject => {
  return {
    path,
    element: (
      <NavLayout>
        <Component />
      </NavLayout>
    ),
  }
}

export function RoutesContainer({ classification }: RoutesContainerProps) {
  if (classification === undefined) return <LoadingComponent />
  const navigate = useNavigate()
  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")

  // ensures cashiers cannot navigate to other routes before confirming shift status
  if (authShift?.currentShift === null && authShift?.response?.classification !== 5) {
    useEffect(() => {
      navigate("/home")
    }, [navigate])
  }

  let routes: RouteObject[] = [
    // Common route
    {
      path: "/home",
      element: <Home />,
    },
    // Catch-all route
    {
      path: "*",
      element: <NotFound />,
    },
  ]

  if (classification === 1) {
    // Add routes accessible by classification 1
    routes = [
      ...routes,
      layoutRouter(Drawer, "/drawer"),
      layoutRouter(Accounts, "/accounts"),
      layoutRouter(AccountDetails, "/account-details/:playerNumber"),
      layoutRouter(Shifts, "/shifts"),
      layoutRouter(Transactions, "/transactions"),
      layoutRouter(CashierManagement, "/cashier-management"),
      // layoutRouter(Training, "/training"),
    ]
  } else {
    // Add routes accessible by classification 5
    routes = [
      ...routes,
      layoutRouter(Shifts, "/shifts"),
      layoutRouter(Transactions, "/transactions"),
    ]
  }

  const element = useRoutes(routes)
  return element
}
