import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useCancelTransactionMutation,
  useSetPlayerTransactionMutation,
  useGetUserQuery,
  useGetCancelTransactionDetailsMutation,
} from "../../redux/user/user.api"
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Box,
  AlertColor,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material"
import PrintIcon from "@mui/icons-material/Print"
import EditIcon from "@mui/icons-material/Edit"
import CancelIcon from "@mui/icons-material/Cancel"
import { PlayerTransactionsDetails } from "../../components/AccountDetails/PlayerTransactionsDetails"
import { flattenAccountDetails } from "../../utils/flatteningFunctions/flattenAccountDetails"
import { UserOutput } from "../../types/IUser"
import BackButton from "../../components/BackButton"
import { ErrorLayout } from "../../layouts/ErrorLayout"
import { AddAccountDialog } from "../../components/Accounts/AddAccountDialog"
import { CustomDialog } from "../../styles/styledComponents/CustomDialog"
import { formatDateAndTime } from "../../utils/formattingFunctions/formatDateAndTime"
import { entriesToMoney } from "../../utils/entriesToMoney"
import { ReceiptContent } from "../../components/Print"
import { useReactToPrint } from "react-to-print"
import { formatAmount } from "../../utils/formattingFunctions/formatAmount"
import { PlayerTransactionOutput } from "../../types/IUserTransactions"
import CancelDialogContent from "../../components/AccountDetails/CancelDialogContent.tsx"
import { formatPhoneNumber } from "../../utils/formattingFunctions/formatPhoneNumber"
import ClearIcon from "@mui/icons-material/Clear"
import CheckIcon from "@mui/icons-material/Check"
import PhoneVerificationDialog from "../../components/PhoneVerificationDialog"
import SmsIcon from "@mui/icons-material/Sms"
import { useCustomSnackbar } from "../../components/multiSnackbar"
export const AccountDetails = () => {
  const { playerNumber } = useParams()
  const {
    data: userData,
    error,
    isLoading,
    refetch,
  } = useGetUserQuery({ playerNum: Number(playerNumber) })
  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [fetchCancelDetails, setFetchCancelDetails] = useState(false)
  const [confirmPrintCancelModal, setConfirmPrintCancelModal] = useState(false)
  const [isPhoneVerificationDialogOpen, setIsPhoneVerificationDialogOpen] = useState(false)
  const [cancelDetailsData, setCancelDetailsData] = useState<any>(null)
  const [isTransactionLoading, setIsTransactionLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: undefined as AlertColor | undefined,
  })
  const showSnackbar = useCustomSnackbar()
  localStorage.setItem("selectedUser", JSON.stringify(userData))

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbar({ message, severity })
  }

  const [cancelTransactionDetails] = useGetCancelTransactionDetailsMutation()

  const [
    cancelTransaction,
    { isSuccess, isLoading: isCancelTransactionLoading, error: cancelTransactionError },
  ] = useCancelTransactionMutation()

  useEffect(() => {
    if (snackbar.message) {
      showSnackbar(snackbar.message, snackbar.severity)
    }
  }, [snackbar])

  useEffect(() => {
    if (isSuccess || !isPhoneVerificationDialogOpen) {
      refetch()
    }
  }, [isSuccess, isPhoneVerificationDialogOpen, refetch])

  const handleCancelClick = useCallback(async () => {
    try {
      setFetchCancelDetails(true)
      setIsTransactionLoading(true)
      const details: any = await cancelTransactionDetails({ PlayerId: userData?.response?.id })

      setCancelDetailsData(details.data)

      if (details?.data?.cantCancel) {
        openSnackbar(details?.data.cantCancel, "error")
      } else if (details?.data?.failure) {
        openSnackbar(details?.data.failure, "error")
      } else if (details?.data && !isTransactionLoading) {
        setCancelDialogOpen(true)
      }
    } catch (error) {
      console.error("Error fetching transaction cancellation details:", error)
      openSnackbar("Error occurred while fetching details", "error")
    } finally {
      setFetchCancelDetails(false)
      setIsTransactionLoading(false)
    }
  }, [userData])

  const authShift = JSON.parse(sessionStorage.getItem("authShift") ?? "null")
  const currentShiftID = authShift?.currentShift?.id

  const [queryParams, setQueryParams] = useState({
    page: 1,
    rowsPerPage: rowsPerPage,
    refetchKey: 0,
  })

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true)
  const [transactionsError, setTransactionsError] = useState(null)
  const [transactionsApiResponse, setTransactionsApiResponse] = useState<any>(null)
  const [transactions, setTransactions] = useState<PlayerTransactionOutput[]>([])
  const [fetchTransactions] = useSetPlayerTransactionMutation()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(940))
  const getTransactions = async (
    inputQueryParams: any = {
      page: queryParams.page,
      rowsPerPage: queryParams.rowsPerPage,
    }
  ) => {
    setIsTransactionsLoading(true)

    const res: any = await fetchTransactions({
      playerId: user?.id,
      from: userData?.response.createdOn,
      to: new Date().toISOString(),
      page: inputQueryParams.page,
      perPage: inputQueryParams.rowsPerPage,
      refetchKey: queryParams.refetchKey,
    })
    setTransactionsApiResponse(res.data)
    setIsTransactionsLoading(false)
  }
  const handleOpenPhoneVerificationDialog = () => {
    setIsPhoneVerificationDialogOpen(true)
  }

  const handleConfirmCancel = async () => {
    const cancelTransactionRes: any = await cancelTransaction({
      PlayerId: user?.id,
      TransactionId: cancelDetailsData?.transaction?.transactionId,
      ShiftId: currentShiftID,
    })
    if (
      cancelTransactionError ||
      cancelTransactionRes?.data?.failure ||
      cancelTransactionRes?.data?.cantCancel
    ) {
      setCancelDialogOpen(false)
      openSnackbar(
        cancelTransactionRes?.data?.failure ||
          cancelTransactionRes?.data?.cantCancel ||
          "An error occurred.",
        "error"
      )
    } else {
      openSnackbar("Transaction successfully canceled!", "success")
      setCancelDialogOpen(false)
      setConfirmPrintCancelModal(true)
    }
    setTransactions([])
    setPage(0)
    setRowsPerPage(10)
    setQueryParams((prevQueryParams) => ({
      ...prevQueryParams,
      page: 1,
      rowsPerPage: rowsPerPage,
    }))

    getTransactions({ page: 1, rowsPerPage: rowsPerPage })
  }
  const cancelReceiptRef = useRef<HTMLDivElement>(null)
  const handlePrintCancel = useReactToPrint({
    content: () => cancelReceiptRef.current,
    removeAfterPrint: false,
  })
  const playerReceiptRef = useRef<HTMLDivElement>(null)
  const handlePrintPlayer = useReactToPrint({
    content: () => playerReceiptRef.current,
    removeAfterPrint: false,
  })
  const { formattedDate, formattedTime } = formatDateAndTime(
    cancelDetailsData?.transaction?.timestamp,
    true
  )
  if (isLoading) return <div>Loading...</div>

  const tableData = [
    // { label: "Transaction Type:", value: transactionType },
    { label: "Date:", value: `${formattedDate} ` },
    { label: "Time:", value: ` ${formattedTime}` },
    {
      label: "Amount:",
      value: formatAmount(entriesToMoney(cancelDetailsData?.transaction?.amount)),
    },
    {
      label: "Promo Amount:",
      value: cancelDetailsData?.transaction?.compAmount?.toLocaleString() + " points" || "--",
    },
  ]

  const user: UserOutput | undefined = flattenAccountDetails(
    userData?.response,
    userData?.parameters
  )

  const handleEditClick = () => {
    setEditDialogOpen(true)
  }

  return (
    <ErrorLayout error={error}>
      <>
        <Box
          bgcolor="primary.main"
          color="white"
          p={0.5}
          mb={2}
          display="grid"
          gridTemplateColumns="min-content 1fr min-content"
          alignItems="center"
        >
          <BackButton />

          <Typography variant="h5" align="center">
            Account Details
          </Typography>
          <div style={{ width: "70.9px" }}></div>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "primary", height: "100%" }}>
              <CardContent sx={{ paddingY: 1 }}>
                <Box position="relative">
                  <Box mb={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {user?.name}
                    </Typography>
                    <Typography variant="boldSubtitle1">
                      {user?.userName ? user?.userName : user?.playerNumber}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="black">
                      Email
                    </Typography>
                    <Typography variant="boldSubtitle1">{user?.email}</Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Stack direction={"row"}>
                        <Typography variant="subtitle1" color="black">
                          Phone
                        </Typography>
                        {user?.parameters?.phoneValidationRequired &&
                          (user?.isPhoneVerified ? (
                            <Tooltip title="Phone verified" placement="right">
                              <CheckIcon
                                sx={{
                                  color: "green",
                                  padding: 0,
                                  fontSize: "20px",
                                  align: "center",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Phone not verified" placement="right">
                              <ClearIcon
                                sx={{
                                  color: "red",
                                  padding: 0,
                                  fontSize: "20px",
                                  align: "center",
                                }}
                              />
                            </Tooltip>
                          ))}
                      </Stack>
                      <Typography variant="boldSubtitle1">
                        {formatPhoneNumber(user?.phone)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="black">
                        Free Entries
                      </Typography>
                      <Typography variant="boldSubtitle1">
                        {user?.freeEntries?.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={0}>
                        <Typography variant="subtitle1" color="black">
                          Driver&apos;s License
                        </Typography>
                        <Typography
                          sx={{
                            overflowWrap: "break-word",
                          }}
                          variant="boldSubtitle1"
                        >
                          {user?.driverLicense}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={0}>
                        <Typography variant="subtitle1" color="black">
                          Winnings
                        </Typography>
                        <Typography variant="boldSubtitle1">
                          {" "}
                          {user.winPoints !== undefined
                            ? formatAmount(entriesToMoney(user.winPoints))
                            : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>

              <CardActions>
                <Box display="flex" justifyContent="flex-start" flexGrow={1}>
                  <Grid container spacing={1}>
                    {/* First Row */}
                    <Grid item xs={6} md={3}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleEditClick}
                        sx={
                          isSmallScreen
                            ? {
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }
                            : { width: "100%" }
                        }
                      >
                        Edit
                      </Button>
                    </Grid>

                    {/* Second Row */}
                    <Grid item xs={6} md={3}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<PrintIcon />}
                        onClick={handlePrintPlayer}
                        sx={
                          isSmallScreen
                            ? {
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }
                            : { width: "100%" }
                        }
                      >
                        Print
                      </Button>
                    </Grid>

                    {user?.parameters?.phoneValidationRequired && !user?.isPhoneVerified && (
                      <Grid item xs={6} md={3}>
                        <Button
                          onClick={handleOpenPhoneVerificationDialog}
                          variant="outlined"
                          size="small"
                          startIcon={<SmsIcon />}
                          sx={
                            isSmallScreen
                              ? {
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }
                              : { width: "100%" }
                          }
                        >
                          Verify Phone
                        </Button>
                      </Grid>
                    )}

                    {userData?.parameters?.canCancelTransaction === true && (
                      <Grid item xs={6} md={3}>
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={
                            isTransactionLoading ? <CircularProgress size={18} /> : <CancelIcon />
                          }
                          onClick={handleCancelClick}
                          sx={
                            isSmallScreen
                              ? {
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }
                              : { width: "100%" }
                          }
                        >
                          {isSmallScreen ? "Cancel Tx." : "Cancel Transaction"}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Box mt={2}>
          <PlayerTransactionsDetails
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            getTransactions={getTransactions}
            transactionsApiResponse={transactionsApiResponse}
            error={transactionsError}
            isLoading={isTransactionsLoading}
            playerId={user?.id}
            createdOn={userData?.response.createdOn}
            transactions={transactions}
            setTransactions={setTransactions}
          />
        </Box>
        {isEditDialogOpen && user && (
          <AddAccountDialog
            open={isEditDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            onUserUpdatedOrCreated={() => {
              refetch()
            }}
            isEdit={true}
            initialUser={{
              // used in kiosk API
              // playerId: user.id,

              // used in dev3 API
              playerNum: user.playerNumber,
              firstName: user.firstName,
              lastName: user.lastName,
              phone: formatPhoneNumber(user.phone),
              email: user.email,
              driversLicense: user.driverLicense,
              password: "",
            }}
          />
        )}
        <CustomDialog
          open={cancelDialogOpen}
          title="Cancel Transaction"
          content={<CancelDialogContent tableData={tableData} />}
          primaryActionText="Yes"
          secondaryActionText="No"
          onPrimaryAction={handleConfirmCancel}
          onSecondaryAction={() => {
            setCancelDialogOpen(false)
            setFetchCancelDetails(false)
          }}
          handleClose={() => {
            setCancelDialogOpen(false)
            setFetchCancelDetails(false)
          }}
        />
        <div style={{ display: "none" }}>
          <ReceiptContent
            ref={cancelReceiptRef}
            accountId={user?.userName ? user?.userName : user?.playerNumber}
            cancelPurchase={formatAmount(entriesToMoney(cancelDetailsData?.transaction?.amount))}
            receiptName="Cancel"
            cencelPromo={cancelDetailsData?.transaction?.compAmount?.toLocaleString()}
            freeEntries={user?.freeEntries?.toLocaleString()}
            winPoints={
              user.winPoints !== undefined ? formatAmount(entriesToMoney(user.winPoints)) : "--"
            }
          />
        </div>
        <div style={{ display: "none" }}>
          <ReceiptContent
            receiptName="Account Details"
            ref={playerReceiptRef}
            accountId={user?.userName ? user?.userName : user?.playerNumber}
            freeEntries={user?.freeEntries?.toLocaleString()}
            winPoints={
              user.winPoints !== undefined ? formatAmount(entriesToMoney(user.winPoints)) : "--"
            }
          />
        </div>
        <CustomDialog
          open={confirmPrintCancelModal}
          title={`Cancel Confirmed`}
          primaryActionText="Print Receipt"
          secondaryActionText="Close"
          onPrimaryAction={() => {
            {
              setConfirmPrintCancelModal(false)
              setFetchCancelDetails(false)
            }
            handlePrintCancel()
          }}
          onSecondaryAction={() => {
            setConfirmPrintCancelModal(false)
            setFetchCancelDetails(false)
          }}
          handleClose={() => {
            setConfirmPrintCancelModal(false)
            setFetchCancelDetails(false)
          }}
        />
        <PhoneVerificationDialog
          open={isPhoneVerificationDialogOpen}
          onClose={() => setIsPhoneVerificationDialogOpen(false)}
          playerId={user?.id}
        />
      </>
    </ErrorLayout>
  )
}
