import { useOidc } from "@axa-fr/react-oidc"
import { useCloseShiftMutation } from "../redux/user/user.api"
import { useLayoutEffect, useRef, useState } from "react"
import { CustomDialog } from "../styles/styledComponents/CustomDialog"
import { useReactToPrint } from "react-to-print"
import { ReceiptContent, ReceiptProps } from "./Print"
import { flattenCloseShift } from "../utils/flatteningFunctions/flattenCloseShift"
import { asyncErrorHandler } from "../utils/errorHandler"
import { useCustomSnackbar } from "./multiSnackbar"

interface Props {
  open: boolean
  onClose: () => void
}

export function LogoutModal({ open, onClose }: Props) {
  const { logout } = useOidc()
  const [closeShift] = useCloseShiftMutation()

  const [loadingLogOut, setLoadingLogOut] = useState(false)
  const [loadingCloseShift, setLoadingCloseShift] = useState(false)
  const [showCloseShiftConfirmation, setShowCloseShiftConfirmation] = useState(false)
  const [hasPrinted, setHasPrinted] = useState(false)
  const showSnackbar = useCustomSnackbar()
  const closeShiftReceiptRef = useRef<HTMLDivElement>(null)

  const logoutHandler = asyncErrorHandler(async (showLoading = true) => {
    if (showLoading) setLoadingLogOut(true)
    try {
      sessionStorage.removeItem("CONFIRM_CURRENT_SHIFT")
      sessionStorage.removeItem("HAS_PRINTED")
      await logout("/")
    } catch (error) {
      console.log("An error occurred during logout:", error)
    }
  }, showSnackbar)

  const handlePrint = useReactToPrint({
    content: () => closeShiftReceiptRef.current,
    onAfterPrint: () => {
      setHasPrinted(true)
    },
    removeAfterPrint: false,
  })

  const showCloseShiftConfirmationHandler = () => {
    setShowCloseShiftConfirmation(true)
    onClose()
  }

  const [shiftData, setShiftData] = useState<any>(null)

  const closeShiftHandler = asyncErrorHandler(async () => {
    setLoadingCloseShift(true)
    try {
      const response = (await closeShift("")) as any
      if (!("error" in response)) {
        const flattenedData = flattenCloseShift(response.data)
        setShiftData(flattenedData)
        handlePrint()
      } else {
        showSnackbar(response?.error?.data || "Something went wrong!", "error")
        logoutHandler(true)
      }
    } catch (error) {
      console.log("An error occurred while closing the shift:", error)
    } finally {
      setLoadingCloseShift(false)
      setShowCloseShiftConfirmation(false)
    }
  }, showSnackbar)
  console.log("shiftData", shiftData)

  useLayoutEffect(() => {
    const storedHasPrinted = sessionStorage.getItem("HAS_PRINTED")

    if (storedHasPrinted) {
      setHasPrinted(JSON.parse(storedHasPrinted))
    }
  }, [])

  useLayoutEffect(() => {
    if (shiftData) {
      handlePrint()
      sessionStorage.setItem("HAS_PRINTED", JSON.stringify(true))
    }
  }, [shiftData])
  const userDataString = sessionStorage.getItem("authShift")
  const userData = userDataString ? JSON.parse(userDataString) : null
  const classification = userData?.response?.classification
  return (
    <>
      <CustomDialog
        open={open}
        title="Log Out"
        dialogContentText={
          classification === 1
            ? "Do you want to log out or close the current shift?"
            : "Do you want to log out?"
        }
        primaryActionText={loadingLogOut ? "Logging out..." : "Log out"}
        secondaryActionText={classification === 1 ? "Close Shift" : undefined}
        onSecondaryAction={classification === 1 ? showCloseShiftConfirmationHandler : undefined}
        onPrimaryAction={() => logoutHandler(true)}
        handleClose={onClose}
        disabledPrimaryAction={loadingLogOut}
      />
      <div style={{ display: "none" }}>
        {shiftData && (
          <ReceiptContent ref={closeShiftReceiptRef} {...shiftData} receiptName="End of Shift" />
        )}
      </div>
      <CustomDialog
        open={showCloseShiftConfirmation}
        title="Confirmation"
        dialogContentText="Are you sure you want to close the current shift?"
        primaryActionText={loadingCloseShift ? "Closing Shift..." : "Yes"}
        secondaryActionText="No"
        onPrimaryAction={() => {
          closeShiftHandler()
        }}
        onSecondaryAction={() => setShowCloseShiftConfirmation(false)}
        handleClose={() => setShowCloseShiftConfirmation(false)}
        isLoading={loadingCloseShift}
        disabledPrimaryAction={loadingCloseShift}
      />

      <CustomDialog
        open={hasPrinted}
        title=""
        dialogContentText="The shift has been closed successfully."
        primaryActionText={"Exit"}
        onPrimaryAction={async () => await logoutHandler(true)}
        handleClose={async () => await logoutHandler(false)}
        isLoading={loadingLogOut}
        disabledPrimaryAction={loadingLogOut}
      />
    </>
  )
}
